import axios from 'axios';
import {
  GET_ERRORS,
  GET_ALL_REGIONS,
  SET_SELECTED_REGION,
  CLEAR_SELECTED_REGION,
} from './types';

//Register new user
//Redirects to login
export const getAllRegions = () => dispatch => {
  axios
    .get ('/api/region/')
    .then (foundRegions => {
      dispatch ({
        type: GET_ALL_REGIONS,
        payload: foundRegions.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getRegionsList = regions => dispatch => {
  axios
    .post ('/api/region/list', regions)
    .then (foundRegions => {
      dispatch ({
        type: GET_ALL_REGIONS,
        payload: foundRegions.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setSelectedRegion = selectedRegion => dispatch => {
  if (selectedRegion === undefined) {
    return dispatch ({
      type: CLEAR_SELECTED_REGION,
    });
  }
  dispatch ({
    type: SET_SELECTED_REGION,
    payload: selectedRegion,
  });
};
