import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';
import {getAllRegions} from '../../../../store/actions/regionActions';
import {ValidateEmail} from '../../../../validation/fieldValidation';
export class ProjectMembers extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'projectMembers',
      isValid: false,

      answers: {
        team: 'SOLO',
        member1_firstName: '',
        member1_lastName: '',
        member1_email: '',
        member2_firstName: '',
        member2_lastName: '',
        member2_email: '',
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;
    let errors = {};

    if (isEmpty (answers.member1_firstName))
      errors.member1_firstName = 'member1_firstName';
    if (isEmpty (answers.member1_lastName))
      errors.member1_lastName = 'member1_lastName';
    if (isEmpty (answers.member1_email)) errors.member1_email = 'member1_email';
    if (!ValidateEmail (answers.member1_email))
      errors.member1_email = 'member1_email';
    if (answers.team === 'DUO' && isEmpty (answers.member2_firstName))
      errors.member2_firstName = 'member2_firstName';
    if (answers.team === 'DUO' && isEmpty (answers.member2_lastName))
      errors.member2_lastName = 'member2_lastName';
    if (answers.team === 'DUO' && isEmpty (answers.member2_email))
      errors.member2_email = 'member2_email';
    if (answers.team === 'DUO' && !ValidateEmail (answers.member2_email))
      errors.member2_email = 'member2_email';

    if (Object.keys (errors).length > 0) isValid = false;
    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
  // this.nameInput.focus ();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    return questions.includes(question);
  }

  RenderTeamQuestion = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('team');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.team.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="team"
                id="solo"
                value="SOLO"
                onChange={this.OnHandleRadio}
                checked={answers.team === 'SOLO' ? 'checked' : ''}
                ref={input => {
                  this.nameInput = input;
                }}
              />
              <label className="form-check-label" htmlFor="solo">
                {t (`${sectionName}.team.options.SOLO`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="team"
                id="duo"
                value="DUO"
                onChange={this.OnHandleRadio}
                checked={answers.team === 'DUO' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="duo">
                {t (`${sectionName}.team.options.DUO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderFirstMemberInfos = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion_name = this.MustRenderQuestion('member1_firstName') || this.MustRenderQuestion('member1_lastName');
    let mustRenderQuestion_email =  this.MustRenderQuestion('member1_email');

    return (
      <div>

        {mustRenderQuestion_name &&

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="member1_firstName">
                  {t (`${sectionName}.member1_firstName.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="member1_firstName"
                  id="member1_firstName"
                  value={answers.member1_firstName}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="member1_lastName">
                  {t (`${sectionName}.member1_lastName.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="member1_lastName"
                  id="member1_lastName"
                  value={answers.member1_lastName}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
          </div>
        }

        {mustRenderQuestion_email &&

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="member1_email">
                  {t (`${sectionName}.member1_email.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="member1_email"
                  id="member1_email"
                  value={answers.member1_email}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
          </div>
        }

      </div>

    );
  };

  RenderSecondMemberInfos = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion_name = this.MustRenderQuestion('member2_firstName') || this.MustRenderQuestion('member2_lastName');
    let mustRenderQuestion_email =  this.MustRenderQuestion('member2_email');
    let mustRenderQuestion_team =  this.MustRenderQuestion('team');

    return (
      <div>

        {(mustRenderQuestion_name || mustRenderQuestion_team) &&

         <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="member2_firstName">
                  {t (`${sectionName}.member2_firstName.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="member2_firstName"
                  id="member2_firstName"
                  value={answers.member2_firstName}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="member2_lastName">
                  {t (`${sectionName}.member2_lastName.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="member2_lastName"
                  id="member2_lastName"
                  value={answers.member2_lastName}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
          </div>

        }

        {(mustRenderQuestion_email || mustRenderQuestion_team)  &&

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="member2_email">
                  {t (`${sectionName}.member2_email.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="member2_email"
                  id="member2_email"
                  value={answers.member2_email}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
          </div>

        }
            
      </div>
     
    );
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderTeamQuestion ()}
        <hr />
        {this.RenderFirstMemberInfos ()}
        <hr />
        {answers.team === 'DUO' && this.RenderSecondMemberInfos ()}

      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {getAllRegions};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (ProjectMembers)
);
