import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import {logoutUser} from '../../store/actions/authActions';
import {
  GetAllPendingFormsByParticipant,
  GetAllPendingForms,
} from '../../store/actions/formActions';

import {withTranslation} from 'react-i18next';
import DiscussionNav from './DiscussionNav';
import isEmpty from '../../validation/isEmpty';
export class ParticipantDiscussionDashboard extends Component {
  constructor (props) {
    super (props);

    this.state = {};
  }

  componentDidMount () {
    const {user} = this.props.auth;
    if (isEmpty (user)) return;
    if (user.role === 'PARTICIPANT') {
      this.props.GetAllPendingFormsByParticipant (user._id, user.role);
    } else {
      this.props.GetAllPendingForms ();
    }
  }
  componentDidUpdate (prevProps, prevState) {}
  Logout = () => {
    this.props.logoutUser ();
  };
  RenderMenuSideBar = () => {
    return <DiscussionNav />;
  };
  RenderParticipantHeader = () => {
    const {t} = this.props;

    return (
      <div className="row pb-3">
        <div className="col-md-12">
          <h1>{t ('dashboard.participant_pending')}</h1>
        </div>

      </div>
    );
  };

  RenderAdminHeader = () => {
    const {t} = this.props;

    return (
      <div className="row pb-3">
        <div className="col-md-12">
          <h1>
            {t ('dashboard.admin_pending')}
          </h1>
        </div>
      </div>
    );
  };

  RenderRequestList = () => {
    const {t} = this.props;

    const {formsList} = this.props.form;
    if (isEmpty (formsList)) return <h4>{t ('dashboard.no_request')}</h4>;

    const requests = formsList.map ((r, index) => {
      const {_id, isApproved, status, lang, type, newRequest, date} = r;
      let renderedType = '';
      switch (type) {
        case 'ae_form':
          renderedType = 'A-E';
          break;
        case 'ar_form':
          renderedType = 'A-R';
          break;
        case 'd_form':
          renderedType = 'D';
          break;
        default:
          renderedType = '';
      }

      let renderedStatus = '';
      switch (status) {
        case 'DECLINED':
          renderedStatus = 'Refusé';
          break;
        case 'APPROVED':
          renderedStatus = 'Approuvé';
          break;
        case 'REVIEW':
          renderedStatus = 'En révision';
          break;
        case 'MODIFICATION_REQUIRED':
          renderedStatus = 'Modification nécessaire';
          break;
        default:
          renderedStatus = '';
      }
      return (
        <li
          key={index}
          className={classnames ('list-group-item', {
            'is-approved': isApproved,
          })}
        >
          <Link to={`/discussion/${_id}`}>
            <i className={classnames ('fas', {'fa-star pr-3': newRequest})} />
            {`Demande de formulaire ${renderedType} - ${moment (date).format ('YYYY-MM-DD HH:mm')}  - ${renderedStatus} - ${lang}`}
          </Link>
        </li>
      );
    });

    return (
      <ul className="list-group">
        {requests.length > 0 ? requests : t ('dashboard.no_request')}
      </ul>
    );
  };

  RenderAdminListHeader = () => {
    const {t} = this.props;
    return (
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-1">
          <strong>{t ('dashboard.language')}</strong>
        </div>
        <div className="col-md-2"><strong>{t ('dashboard.date')}</strong></div>
        <div className="col-md-2">
          <strong>{t ('dashboard.project_number')}</strong>
        </div>
        <div className="col-md-2">
          <strong>{t ('dashboard.member_1')}</strong>
        </div>
        <div className="col-md-2">
          <strong>{t ('dashboard.member_2')}</strong>
        </div>
        <div className="col-md-2"><strong>{t ('dashboard.new')}</strong></div>
      </div>
    );
  };

  GetFormType = formAnswers => {
    const {level} = formAnswers.participationInformations;
    const {
      human_subjects_participation,
    } = formAnswers.humanSubjectsParticipation;

    const type = level === 'ELEMENTARY'
      ? 'D'
      : human_subjects_participation === 'YES' ? 'AE' : 'AR';

    return type;
  };

  GetFormNumber = form => {
    const {answers, lang, type} = form;
    const {year, final} = answers.participantInformations;
    const {member1_lastName, member2_lastName} = answers.projectMembers;
    const no = `${year}-${type}-${lang}-${member1_lastName.substr (0, 7)}${!isEmpty (member2_lastName) ? '-' + member2_lastName.substr (0, 7) : ''}-${final}`;

    return no;
  };

  GetNewElements = (discussion, attachments) => {
    const {t} = this.props;
    const newMessages = discussion.filter (d => {
      return d.new === true;
    });
    const newDocuments = attachments.filter (a => {
      return a.new === true;
    });
    let newElements = [];
    newMessages.map ((m, index) => {
      newElements.push (
        <p key={'m' + index}>
          <small>
            {t ('dashboard.new_message')}
            <br />
            {' '}
            {moment (m.datetime).format ('YYYY-MM-DD, HH:mm')}
          </small>
        </p>
      );
      return true;
    });
    newDocuments.map ((d, index) => {
      newElements.push (
        <p key={'d' + index}>
          <small>
            {t ('dashboard.new_document')}
            {' '}
            <br />
            {moment (d.datetime).format ('YYYY-MM-DD,HH:mm')}
          </small>
        </p>
      );
      return true;
    });
    return newElements;
  };

  SortRows = list => {
    const rows = {
      D: [],
      AE: [],
      AR: [],
    };
    for (const request of list) {
      const type = request.type;
      rows[type].push (request);
    }
    return rows;
  };
  RenderAdminListRow = pendingRequest => {
    const {_id, lang, date, answers, attachments, discussion} = pendingRequest;
    const requestNo = this.GetFormNumber (pendingRequest);
    const newElements = this.GetNewElements (discussion, attachments);
    const newRequest = newElements.length > 0;
    return (
      <div
        className="row admin-row"
        onClick={() => {
          this.props.history.push (`/discussion/${_id}`);
        }}
        key={_id}
      >
        <div className="col-md-1">
          {newRequest && <i className="fas fa-exclamation-triangle" />}
        </div>
        <div className="col-md-1">{lang}</div>
        <div className="col-md-2">{moment (date).format ('YYYY-MM-DD')}</div>
        <div className="col-md-2">{requestNo}</div>
        <div className="col-md-2">{`${answers.projectMembers.member1_firstName} ${answers.projectMembers.member1_lastName}`}</div>
        <div className="col-md-2">{`${answers.projectMembers.member2_firstName} ${answers.projectMembers.member2_lastName}`}</div>
        <div className="col-md-2">
          {newElements.length > 0 ? newElements : ''}
        </div>
      </div>
    );
  };

  RenderFormDRows = requests => {
    const {t} = this.props;
    const {user} = this.props.auth;

    const rows = requests.map (this.RenderAdminListRow);
    return (
      (user.committee === 'RULES' || user.committee === 'BOTH') &&
      <div className="my-5">
        <div className="py-3">
          <h3>{t ('dashboard.form_d')}</h3>
        </div>
        {!isEmpty (requests) && this.RenderAdminListHeader ()}
        {isEmpty (requests) ? <h6>{t ('dashboard.no_form')}</h6> : rows}
      </div>
    );
  };

  RenderFormAERows = requests => {
    const {t} = this.props;
    const {user} = this.props.auth;
    const rows = requests.map (this.RenderAdminListRow);
    return (
      (user.committee === 'BOTH' || user.committee === 'ETHICS') &&
      <div className="my-5">
        <div className="py-3">
          <h3>{t ('dashboard.form_ae')}</h3>
        </div>
        {!isEmpty (requests) && this.RenderAdminListHeader ()}
        {isEmpty (requests) ? <h6>{t ('dashboard.no_form')}</h6> : rows}
      </div>
    );
  };

  RenderFormARRows = requests => {
    const {t} = this.props;
    const {user} = this.props.auth;
    const rows = requests.map (this.RenderAdminListRow);
    return (
      (user.committee === 'RULES' || user.committee === 'BOTH') &&
      <div className="my-5">
        <div className="py-3">
          <h3>{t ('dashboard.form_ar')}</h3>
        </div>
        {!isEmpty (requests) && this.RenderAdminListHeader ()}
        {isEmpty (requests) ? <h6>{t ('dashboard.no_form')}</h6> : rows}
      </div>
    );
  };

  RenderAdminList = () => {
    const {formsList} = this.props.form;
    if (isEmpty (formsList)) return;

    const sortedRows = this.SortRows (formsList);
    return (
      <Fragment>
        {this.RenderFormAERows (sortedRows.AE)}
        {this.RenderFormARRows (sortedRows.AR)}
        {this.RenderFormDRows (sortedRows.D)}
      </Fragment>
    );
  };

  RenderMain = () => {
    const {user} = this.props.auth;
    return (
      <main className="discussion-dashboard">
        {user.role === 'PARTICIPANT' && this.RenderParticipantHeader ()}
        {user.role === 'PARTICIPANT' && this.RenderRequestList ()}

        {user.role !== 'PARTICIPANT' && this.RenderAdminHeader ()}
        {user.role !== 'PARTICIPANT' && this.RenderAdminList ()}
      </main>
    );
  };

  render () {
    return (
      <div>
        <div className="discussion-container">
          {this.RenderMenuSideBar ()}
          {this.RenderMain ()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({auth: state.auth, form: state.form});

const mapDispatchToProps = {
  logoutUser,
  GetAllPendingFormsByParticipant,
  GetAllPendingForms,
};

export default withTranslation (['pages']) (
  connect (mapStateToProps, mapDispatchToProps) (ParticipantDiscussionDashboard)
);
