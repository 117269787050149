import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  GetFormById,
  AddDiscussionMessage,
  SwitchFormStatus,
  Archive,
  AddAttachment,
  GetAttachmentsList,
  UpdateNotifications,
  ChangeCorrections,
  Delete,
} from '../../store/actions/formActions';
import {logoutUser} from '../../store/actions/authActions';
import {getCommitteeMembers} from '../../store/actions/userActions';
import {SetMessage} from '../../store/actions/messageActions';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../validation/isEmpty';
import DiscussionMessage from '../discussion/DiscussionMessage';
import Attachment from '../discussion/Attachment';
import moment from 'moment';
import classnames from 'classnames';
import DiscussionNav from './DiscussionNav';
import ConditionModal from './ConditionModal';
import i18next from 'i18next';

export class ParticipantDiscussionView extends Component {
  constructor (props) {
    super (props);

    this.state = {
      modal: '',
      file: '',
      status: 'REVIEW',
      notifiedUsers: [],
      isArchived: false,
      attachments: [],
      discussionMessage: {
        message: '',
        author: '',
        form: '',
        datetime: '',
        adminOnly: true,
        new: true,
      },
    };
  }

  componentDidMount () {
    const {formId} = this.props.match.params;
    this.props.GetFormById (formId);
    if (this.props.auth.user.role === 'ADMIN') {
      this.props.getCommitteeMembers ();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.form !== this.props.form) {
      if (prevProps.form.pendingRequest !== this.props.form.pendingRequest) {
        this.props.GetAttachmentsList (this.props.form.pendingRequest._id);
        const {discussionMessage} = this.state;
        const {isArchived} = this.props.form.pendingRequest;

        discussionMessage.form = this.props.form.pendingRequest._id;
        discussionMessage.author = this.props.auth.user._id;
        discussionMessage.adminOnly =
          this.props.auth.user.role !== 'PARTICIPANT';
        this.setState ({
          discussionMessage,
          isArchived,
        });
      }
    }
  }

  ResetMessage = () => {
    const {discussionMessage} = this.state;
    discussionMessage.datetime = '';
    discussionMessage.message = '';
    this.setState ({discussionMessage});
  };

  HandleMessageChange = e => {
    const {discussionMessage} = this.state;
    discussionMessage.message = e.currentTarget.value;
    this.setState ({discussionMessage});
  };
  HandleCheckbox = e => {
    const {discussionMessage} = this.state;
    discussionMessage.adminOnly = e.currentTarget.checked;
    this.setState ({discussionMessage});
  };
  HandleFile = e => {
    const {t} = this.props;
    const file = e.currentTarget.files[0];
    if (e.currentTarget.files[0].size > 10000000) {
      console.log (e.currentTarget.files[0].size);

      this.props.SetMessage (
        'FILE_TOO_LARGE',
        t ('pages:attachment.file_too_large')
      );
      return;
    }
    this.setState ({file});
    this.props.AddAttachment (
      file,
      this.props.form.pendingRequest._id,
      this.props.auth.user._id,
      this.props.form.pendingRequest
    );
  };

  HandleStatusChange = e => {
    this.props.SwitchFormStatus (
      this.props.form.pendingRequest._id,
      e.currentTarget.value,
      moment ().format (),
      this.props.form.pendingRequest
    );
  };

  ArchiveForm = e => {
    const {_id} = this.props.form.pendingRequest;
    this.props.Archive (_id, e.currentTarget.checked);
  };

  DeleteForm = e => {
    const {pendingRequest} = this.props.form;
    if (isEmpty (pendingRequest)) return;

    this.props.Delete (pendingRequest._id, this.props.history);
  };

  SendNewDiscussionMessage = () => {
    const {discussionMessage} = this.state;

    if (isEmpty (discussionMessage)) {
      return;
    }
    //ISO 8601 FORMAT 1977-04-22T01:00:00-05:00
    discussionMessage.datetime = moment ().format ();
    this.props.AddDiscussionMessage (
      discussionMessage,
      this.props.form.pendingRequest
    );
    window.setTimeout (() => {
      this.ResetMessage ();
    }, 250);
  };

  SetCorrection = e => {
    const id = this.props.form.pendingRequest._id;
    this.props.ChangeCorrections (
      id,
      e.currentTarget.dataset.section,
      e.currentTarget.dataset.key
    );
  };

  ToggleNotifiedUsers = e => {
    var notifiedUsers = [];
    notifiedUsers = this.props.form.pendingRequest.committeeNotifications;

    //If exists, remove from array, else push to array
    if (notifiedUsers.indexOf (e.currentTarget.value) !== -1) {
      notifiedUsers = notifiedUsers.filter (u => {
        return u !== e.currentTarget.value;
      });
    } else {
      notifiedUsers.push (e.currentTarget.value);
    }

    this.props.UpdateNotifications (
      this.props.form.pendingRequest._id,
      notifiedUsers
    );
  };

  RenderNotifications = () => {
    if (isEmpty (this.props.form.pendingRequest)) {
      return;
    }
    const {t} = this.props;
    const {type, committeeNotifications, participant} = this.props.form.pendingRequest;
    const {regions : participantRegions} = participant;
    const {usersList} = this.props.user;

    const users = usersList
      .filter (u => {
        if (u.role !== 'ADMIN' && u.role !== 'COORDINATOR' && u.role !== 'READONLY') return false;

        //SI user role === readonly et que même regions
        let coordo =  u.role === 'READONLY' && participantRegions.some(value => u.regions.includes(value))
        if(coordo){
          return true;
        }

        if (type === 'AE') {
          return u.committee === 'ETHICS' || u.committee === 'BOTH';
        } else {
          return u.committee === 'RULES' || u.committee === 'BOTH';
        }
      })
      .map (u => {
        return (
          <div className="form-check col-md-12">
            <input
              className="form-check-input"
              type="checkbox"
              value={u._id}
              name={u._id}
              id={u._id}
              onChange={this.ToggleNotifiedUsers}
              checked={
                committeeNotifications.indexOf (u._id) !== -1 ? 'checked' : ''
              }
            />
            <label className="form-check-label" htmlFor={u._id}>
              {`${u.firstName} ${u.lastName}`} - {t(`pages:users_list.${u.role.toLowerCase()}`)}
            </label>
          </div>
        );
      });

    return (
      <div className="row">
        <div className="col-md-12">
          <h3>{t ('pages:discussion_view.notification')}</h3>
          <p>{t ('pages:discussion_view.will_be_notified')}</p>
        </div>
        {users}
      </div>
    );
  };

  Logout = () => {
    this.props.logoutUser ();
  };

  RenderMenuSideBar = () => {
    return <DiscussionNav />;
  };

  RenderHeader = () => {
    const {form, t, auth} = this.props;
    const {pendingRequest} = form;
    const {answers, type, status} = pendingRequest;
    if (answers === undefined) return;
    const {projectMembers, projectInformations} = answers;

    const participant1 = !isEmpty (pendingRequest)
      ? `${projectMembers.member1_firstName} ${projectMembers.member1_lastName}`
      : '';
    const participant2 = !isEmpty (pendingRequest)
      ? `${projectMembers.member2_firstName} ${projectMembers.member2_lastName}`
      : '';
    const project_title = !isEmpty (pendingRequest)
      ? projectInformations.project_title
      : '';

    return (
      <div className="row pb-3">
        <div className="col-md-12">
          <h1>{t ('pages:discussion_view.header')} {type}</h1>
        </div>
        <div className="col-md-12">
          <h5>{`${project_title}`}</h5>
        </div>
        <div className="col-md-12">
          <p>
            <em
            >{`${participant1}${!isEmpty (participant2) ? ' | ' + participant2 : ''}`}</em>
          </p>
        </div>
        {/*Correction button*/}
        {!isEmpty (pendingRequest.corrections) &&
          (auth.user.role === 'ADMIN' || auth.user.role === 'PARTICIPANT') &&
          pendingRequest.status === 'MODIFICATION_REQUIRED' &&
          Object.keys (pendingRequest.corrections).length > 0 &&
          <div className="col-md-12">
            <button className={classnames ('btn btn-lg btn-main btn-alert')}>
              <Link to={`/correction/${pendingRequest._id}`}>
                {t ('pages:discussion_view.corrections_required')}
              </Link>
            </button>
          </div>}

        {/*Certificate button*/}
        {status === 'APPROVED' &&
          <div className="col-md-12">
            <button
              className={classnames ('btn btn-lg btn-main', {
                'is-invalid': status !== 'APPROVED',
              })}
            >
              <Link to={`/certificat/${pendingRequest._id}`}>
                {t ('pages:discussion_view.download_certificate')}
              </Link>
            </button>
          </div>}
      </div>
    );
  };

  FormatAnswers = () => {
    const {t} = this.props;
    const {pendingRequest} = this.props.form;
    const {answers, corrections} = pendingRequest;
    if (answers === undefined) return;

    let htmlElems = [];

    //Create a section
    Object.keys (answers).map ((sectionKey, index) => {
      //CHECKS IF CORRECTIONS IS NEEDED IN SECTION
      const isCorrectionsNeededInSection =
        corrections !== undefined && corrections.hasOwnProperty (sectionKey);

      let newSection = (
        <div className="accordion" id="accordionExample" key={index}>
          <div className="card">
            {/* RENDERS SECTION HEADER */}
            <div className="card-header" id={`h${sectionKey}`}>
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#c${sectionKey}`}
                  aria-expanded="true"
                  aria-controls={`c${sectionKey}`}
                >
                  {/* RENDERS TEXT RED IF CORRECTION IN SECTION */}
                  <h4
                    className={classnames ('correction', {
                      'correction-necessary': isCorrectionsNeededInSection,
                    })}
                  >
                    {t (`form:${sectionKey}.sectionTitle`)}
                  </h4>
                </button>
              </h5>
            </div>

            {/* RENDERS COLLAPSING CARDS */}
            {this.renderAnswers (sectionKey, answers[sectionKey])}
          </div>
        </div>
      );
      htmlElems.push (newSection);
      return newSection;
    });

    return htmlElems;
  };

  renderAnswers = (sectionName, section) => {
    const {t} = this.props;
    const {user} = this.props.auth;

    let answers = [];
    let questionsTitleAlready_displayed = [];
    let inside_a_group_of_questions = false;
    let must_indent = false;
    Object.keys (section).map ((answersKey, index) => {
      let translatedAnswer = '';
      if (
        i18next.exists (
          `form:${sectionName}.${answersKey}.options.${section[answersKey]}`
        )
      ) {
        translatedAnswer = t (
          `form:${sectionName}.${answersKey}.options.${section[answersKey]}`
        );
      } else if (
        i18next.exists (
          `form:${sectionName}.${answersKey}.${section[answersKey]}`
        )
      ) {
        translatedAnswer = t (
          `form:${sectionName}.${answersKey}.${section[answersKey]}`
        );
      } else if (
        i18next.exists (`form:${sectionName}.${section[answersKey]}`)
      ) {
        translatedAnswer = t (`form:${sectionName}.${section[answersKey]}`);
      } else if (i18next.exists (`form:${section[answersKey]}`)) {
        translatedAnswer = t (`form:${section[answersKey]}`);
      } else if (typeof section[answersKey] === 'boolean') {
        if (section[answersKey] === true) {
          translatedAnswer = t (`form:YES`);
        } else {
          translatedAnswer = t (`form:NO`);
        }
      } else if (section[answersKey] === '') {
        translatedAnswer = 'Aucune réponse';
      } else {
        translatedAnswer = section[answersKey];
      }

      //ADD CHECK TO CHECKBOX FOR CORRECTIONS REQUEST
      const corrections = this.props.form.pendingRequest.corrections;
      const correctionNecessary =
        corrections !== undefined &&
        corrections.hasOwnProperty (sectionName) &&
        corrections[sectionName].indexOf (answersKey) !== -1;

      let translatedQuestion = '';
      if (typeof section[answersKey] === 'boolean') {
        //HERE WE KIND OF NEED TO ADD THE QUESTION TITLE WHEN WE HAVE MULTIPLE ANSWERS POSSIBLE
        let answersKeySplit = answersKey.split("_");

        //LET'S TRY TO FIND THE GOOD STRING
        
        let full_string_part = '';
        for (var i = 0; i < answersKeySplit.length; i++) {
          full_string_part += answersKeySplit[i];
          if (
            i18next.exists (
              `form:${sectionName}.${full_string_part}.title`
            ) && (full_string_part != answersKey) && ! questionsTitleAlready_displayed.includes(full_string_part)
              && full_string_part != 'human_bio_material' //stupid exception
          ) {
            translatedQuestion = t (`form:${sectionName}.${full_string_part}.title`);
            questionsTitleAlready_displayed.push(full_string_part);
            inside_a_group_of_questions = true;
            must_indent = true;
            break;
          }
          full_string_part += '_';
        }

        if (! questionsTitleAlready_displayed.includes(full_string_part) && ! translatedQuestion)
        {
            //some strings are not with the same structure :( let's map them manually here :
                    
            let mapping_strings_boolean_answers_with_question = {
                institution_code : "code",
                institution_animal_code : "code",
                institution_three_counsils : "code",
                human_subjects_proc_archive: "human_subjects_procedures",
                human_subjects_proc_observation: "human_subjects_procedures",
                human_subjects_proc_survey: "human_subjects_procedures",
                human_subjects_proc_individual_interview: "human_subjects_procedures",
                human_subjects_proc_group_interview: "human_subjects_procedures",
                human_subjects_proc_test: "human_subjects_procedures",
                human_subjects_proc_conception: "human_subjects_procedures",
                recrutment_exclusion_age: "exclusions",
                recrutment_exclusion_gender: "exclusions",
                recrutment_exclusion_origin: "exclusions",
                recrutment_exclusion_social_condition: "exclusions",
                recrutment_exclusion_handicap: "exclusions",
                recrutment_exclusion_language: "exclusions",
                recrutment_exclusion_sexual_orientation: "exclusions",
                recrutment_exclusion_religious_association: "exclusions",
                recrutment_diffusionMethod_person: "recrutment_diffusion_method",
                recrutment_diffusionMethod_phone: "recrutment_diffusion_method",
                recrutment_diffusionMethod_email: "recrutment_diffusion_method",
                recrutment_diffusionMethod_social_medias: "recrutment_diffusion_method",
                recrutment_diffusionMethod_mail: "recrutment_diffusion_method",
                certify_all_info: "document_certification",
                certify_protocol: "document_certification",
                certify_tools: "document_certification",
                certify_rules: "information_certification",
                certify_filled: "information_certification",
                certify_informed: "information_certification",
                certify_ethics_security: "information_certification",
                certify_public_authorization: "information_certification",
                certify_photo_authorization: "information_certification",
                certify_content: "information_certification",
                human_bio_material_laboratory: "human_bio_material_provenance",
                human_bio_material_biobank: "human_bio_material_provenance",
                human_bio_material_sample_science_fairs: "human_bio_material_provenance",
                
            }; 

            if (typeof mapping_strings_boolean_answers_with_question[answersKey] !== 'undefined'
            && i18next.exists (
              `form:${sectionName}.${mapping_strings_boolean_answers_with_question[answersKey]}.title`
            ) && ! questionsTitleAlready_displayed.includes(mapping_strings_boolean_answers_with_question[answersKey]))
            {
              translatedQuestion = t (`form:${sectionName}.${mapping_strings_boolean_answers_with_question[answersKey]}.title`);
              questionsTitleAlready_displayed.push(mapping_strings_boolean_answers_with_question[answersKey]);
              inside_a_group_of_questions = true;
              must_indent = true;
            }
        }
        
      }
      else
      {
        //but we still need to check if this one is a OTHER field.
        if (inside_a_group_of_questions && answersKey.includes('other'))
            must_indent = true;
        else  
            must_indent = false;
        inside_a_group_of_questions = false;
      }

      if (translatedQuestion != '') {
        
        answers.push (
          <div className="col-md-12">
          
            <h5>
              <strong>
                {translatedQuestion}
              </strong>
            </h5>

          </div>
        );
      }

      answers.push (
        <div className={classnames ('col-md-12', {
          'decalage': must_indent,
        })} key={'q' + answersKey}>
          {user.role === 'ADMIN' &&
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
              data-section={sectionName}
              data-key={answersKey}
              onChange={this.SetCorrection}
              checked={correctionNecessary ? 'checked' : ''}
            />}

          <h5
            className={classnames ('correction', {
              'correction-necessary': correctionNecessary,
            })}
          >
            <strong>
              {t (`form:${sectionName}.${answersKey}.title`)}
            </strong>
            {correctionNecessary &&
              <span className="pl-2"><em>{t ('form:correction')}</em></span>}
          </h5>

        </div>
      );

      answers.push (
        <div className={classnames ('col-md-12', {
          'decalage': must_indent,
        })}  key={'a' + answersKey}>
          <p
            className={classnames ('correction', {
              'correction-necessary': correctionNecessary,
            })}
          >
            <em>{translatedAnswer}</em>
          </p>
        </div>
      );
      return true;
    });

    if ( ! inside_a_group_of_questions)
          must_indent = false;

    return (
      <div
        id={`c${sectionName}`}
        className="collapse hide"
        aria-labelledby={`h${sectionName}`}
        data-parent="#accordionExample"
      >
        <div className="card-body row">
          {answers}
        </div>
      </div>
    );
  };

  RenderFormSummary = () => {
    const {t} = this.props;
    return (
      <div>
        <h2>{t ('pages:discussion_view.form_answers')}</h2>
        <div className="form-summary">

          {this.FormatAnswers ()}
        </div>
      </div>
    );
  };

  RenderWrittenMessageBlock = () => {
    const {t} = this.props;
    const {user} = this.props.auth;
    const {role} = user;
    const {pendingRequest} = this.props.form;
    const {status} = pendingRequest;
    const isValid =
      role !== 'PARTICIPANT' ||
      (role === 'PARTICIPANT' && status === 'MODIFICATION_REQUIRED');
    return (
      user.role !== 'READONLY' &&
      <div className="row ">
        <p className="col-md-12 pl-5 pt-3">
          <i className="fas fa-user-clock fa-2x pr-3" />
          {' '}
          {isValid
            ? t ('pages:discussion_view.write_new_message')
            : t ('pages:discussion_view.cant_write_message')}
          {role === 'COORDINATOR'
            ? <span className="pl-1">
                {t ('pages:discussion_view.message_not_visible')}
              </span>
            : ''}
        </p>
        {role !== 'PARTICIPANT' &&
          <div className="col-md-12">
            {this.RenderPublicMessageCheckBox ()}
          </div>}
        <div className="messagebox">
          <div className="form-group message-text">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={this.HandleMessageChange}
              value={this.state.discussionMessage.message}
              disabled={!isValid ? 'disabled' : ''}
            />
          </div>
          <div className="message-btn">
            <button
              className={classnames ({'is-invalid': !isValid})}
              onClick={this.SendNewDiscussionMessage}
              disabled={!isValid ? 'disabled' : ''}
            >
              <h4>{t ('pages:discussion_view.send_message')}</h4>
            </button>
          </div>
        </div>

      </div>
    );
  };

  RenderPublicMessageCheckBox = () => {
    const {t} = this.props;
    const {discussionMessage} = this.state;
    const {user} = this.props.auth;
    return (
      user.role === 'ADMIN' &&
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="adminOnly"
          name="adminOnly"
          checked={discussionMessage.adminOnly ? 'checked' : ''}
          onChange={this.HandleCheckbox}
        />
        <label className="form-check-label" htmlFor="adminOnly">
          <i
            className={classnames (
              'fas pr-2',
              {'fa-lock': discussionMessage.adminOnly === true},
              {'fa-lock-open': discussionMessage.adminOnly === false}
            )}
          />
          {discussionMessage.adminOnly
            ? t ('pages:discussion_view.message_admin_only')
            : t ('pages:discussion_view.message_participant')}
        </label>
      </div>
    );
  };

  RenderExistingMessages = () => {
    const {form, t} = this.props;
    const {discussion} = form && form.pendingRequest;
    if (isEmpty (form) || isEmpty (form.pendingRequest)) {
      return;
    }
    const {user} = this.props.auth;
    if (isEmpty (user)) return;

    const messages = discussion.map ((m, index) => {
      return (
        <DiscussionMessage
          message={m}
          user={user}
          type={form.pendingRequest.type}
          key={index}
        />
      );
    });

    return (
      user.role !== 'READONLY' &&
      <div className="messages-container">
        <div>
          <h4>{t ('pages:discussion_view.discussion')}</h4>
        </div>
        {messages}
      </div>
    );
  };

  RenderMain = () => {
    return (
      <main className="discussion-main">
        {this.RenderHeader ()}
        {this.RenderFormSummary ()}
        <hr />
        {this.RenderWrittenMessageBlock ()}
        {this.RenderExistingMessages ()}
      </main>
    );
  };

  RenderSwitchFormStatus = () => {
    const {t} = this.props;
    const {pendingRequest} = this.props.form;
    if (isEmpty (pendingRequest)) {
      return;
    }

    return (
      <div className="col-md-12 p-2">
        <div className="form-group">
          <label htmlFor="form-status">
            <h5>
              {t ('pages:discussion_view.status_change')}
            </h5>
          </label>
          <select
            className="custom-select"
            name="form-status"
            id="form-status"
            value={pendingRequest.status}
            onChange={this.HandleStatusChange}
          >
            <option value="REVIEW">
              {t ('pages:discussion_view.status_pending')}
            </option>
            <option value="APPROVED">
              {t ('pages:discussion_view.status_approved')}
            </option>
            <option value="MODIFICATION_REQUIRED">
              {t ('pages:discussion_view.status_correction')}
            </option>
            <option value="DENIED">
              {t ('pages:discussion_view.status_denied')}
            </option>
          </select>
        </div>

      </div>
    );
  };

  RenderFormDownLoad = () => {
    const {t} = this.props;
    const {user} = this.props.auth;
    const {pendingRequest} = this.props.form;
    const {status} = !isEmpty (pendingRequest) && pendingRequest;

    return (
      <div className="row">
        <h3 className="col-md-12 main-color">
          {t ('pages:discussion_view.status')}
        </h3>
        <p className="col-md-2"><i className="fas fa-user-clock fa-2x" /></p>
        <h4 className="col">{t ('form:status.' + status)}</h4>
        {user.role === 'ADMIN' && this.RenderSwitchFormStatus ()}
      </div>
    );
  };

  RenderFiles = () => {
    const {t} = this.props;
    const {role} = this.props.auth.user;
    const {attachments, pendingRequest} = this.props.form;
    const {status} = pendingRequest;

    const isValid =
      role === 'ADMIN' ||
      (role === 'PARTICIPANT' && (status === 'MODIFICATION_REQUIRED' || status == 'REVIEW'));
    const list = attachments.map ((a, index) => {
      return (
        <Attachment key={index} attachment={a} isValid={isValid} role={role} />
      );
    });
    return (
      <div className="row py-3">
        <div className="col-md-12">
          <h3>{t ('pages:attachment.files')}</h3>
        </div>

        <div className="col-md-12 file-box px-3">
          {list}
        </div>
        <div className="form-group row">
          <label className="custom-file text-center col-md-12 mx-auto">
            <span
              className={classnames (
                'btn btn-lg btn-main btn-block col-md-12 text-center mt-3',
                {'is-invalid': !isValid}
              )}
            >
              {isValid
                ? t ('pages:attachment.download')
                : t ('pages:attachment.download_not_possible')}
            </span>
            <input
              type="file"
              name="file"
              id="file"
              placeholder={t ('pages:attachment.file_placeholder')}
              className="custom-file-input"
              disabled={!isValid ? 'disabled' : ''}
              onChange={this.HandleFile}
              accept="image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf"
            />
          </label>
        </div>

      </div>
    );
  };

  RenderArchive = () => {
    const {auth, form, t} = this.props;
    const {user} = auth;
    const {pendingRequest} = form;
    if (user.role !== 'ADMIN') return;

    return (
      <div className="form-check mt-5">
        <h3>{t ('pages:archive.header')}</h3>
        <h5>
          {pendingRequest.isArchived
            ? t ('pages:archive.form_archived')
            : t ('pages:archive.form_not_archived')}
        </h5>
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="isArchived"
          name="isArchived"
          checked={pendingRequest.isArchived ? 'checked' : ''}
          onChange={this.ArchiveForm}
        />
        <label className="form-check-label" htmlFor="isArchived">
          <i
            className={classnames (
              'fas pr-2',
              {'fa-box': !pendingRequest.isArchived},
              {'fa-box-open': pendingRequest.isArchived}
            )}
          />
          {!pendingRequest.isArchived
            ? t ('pages:archive.check_to_archive')
            : t ('pages:archive.uncheck_to_unarchive')}
        </label>
      </div>
    );
  };

  RenderConditions = () => {
    const {form, auth, t} = this.props;
    const {user} = auth;
    const {pendingRequest} = form;
    return (
      <div className="row py-2">
        <div className="col-md-12 ">
          <h3 className="main-color">{t ('pages:conditions.header')}</h3>
        </div>
        <div className="col-md-12 conditions">
          {isEmpty (pendingRequest.conditions)
            ? <p>{t ('pages:conditions.na')}</p>
            : <p>{pendingRequest.conditions}</p>}
        </div>
        {user.role === 'ADMIN' &&
          <div className="col-md-12 mt-4">
            <button
              className="btn btn-accent btn-block"
              onClick={this.ShowModal}
            >
              <h4>
                {t ('pages:conditions.change_conditions')}
              </h4>
            </button>
          </div>}
      </div>
    );
  };

  RenderDeleteButton = () => {
    const {t} = this.props;
    const {pendingRequest} = this.props.form;
    if (!pendingRequest.isArchived) return;
    return (
      <div className="row">
        <div className="col-md-12">
          <h3>{t ('pages:discussion_view.delete_form_btn')}</h3>
          <p>
            <i className="fas fa-exclamation-triangle pr-2" />
            {t ('pages:discussion_view.delete_form_warning')}
          </p>
        </div>
        <div className="col-md-12">
          <button className="btn btn-alert btn-block" onClick={this.DeleteForm}>
            <h4>
              {t ('pages:discussion_view.delete_form')}
            </h4>
          </button>
        </div>
      </div>
    );
  };

  RenderAside = () => {
    const {user} = this.props.auth;
    return (
      <aside className="discussion-aside">
        {this.RenderFormDownLoad ()}
        <hr />
        {this.RenderConditions ()}
        <hr />
        {this.RenderFiles ()}
        {user.role === 'ADMIN' && <hr />}
        {user.role === 'ADMIN' && this.RenderNotifications ()}
        {user.role === 'ADMIN' && <hr />}
        {user.role === 'ADMIN' && this.RenderArchive ()}
        {user.role === 'ADMIN' && <hr />}
        {user.role === 'ADMIN' && this.RenderDeleteButton ()}
      </aside>
    );
  };

  ShowModal = () => {
    const {pendingRequest} = this.props.form;
    const modal = (
      <ConditionModal
        ClearModal={this.ClearModal}
        _id={pendingRequest._id}
        conditions={pendingRequest.conditions}
      />
    );

    this.setState ({modal}, () => {
      document.getElementById ('modalEntry-btn').click ();
    });
  };

  ClearModal = () => {
    document.getElementById ('closeModalBtn').click ();
    this.setState ({modal: ''});
  };

  render () {
    return (
      <div>
        {this.state.modal}

        <div className="discussion-container">
          {this.RenderMenuSideBar ()}
          {this.RenderMain ()}
          {this.RenderAside ()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  form: state.form,
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = {
  GetFormById,
  AddDiscussionMessage,
  SwitchFormStatus,
  logoutUser,
  Archive,
  AddAttachment,
  GetAttachmentsList,
  Delete,
  SetMessage,
  getCommitteeMembers,
  UpdateNotifications,
  ChangeCorrections,
};

export default withTranslation (['ethic', 'rules', 'form', 'pages']) (
  connect (mapStateToProps, mapDispatchToProps) (ParticipantDiscussionView)
);
