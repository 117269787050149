import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {pdf} from '@react-pdf/renderer';
import CertificateB1 from './CertificateB1';
import CertificateB2 from './CertificateB2';
import {GetFormById} from '../../../store/actions/formActions';
import {withTranslation, Trans} from 'react-i18next';
import isEmpty from '../../../validation/isEmpty';

export class CertificateDownload extends Component {
  constructor (props) {
    super (props);

    this.state = {
      ready: false,
    };
  }

  componentDidMount () {
    const {formid} = this.props.match.params;
    this.props.GetFormById (formid);
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.form !== this.props.form) {
      const {pendingRequest} = this.props.form;
      if (!isEmpty (pendingRequest)) {
        this.setState ({ready: true});
      }
    }
  }

  DownloadCertificate = () => {
    const {t, form, auth} = this.props;
    const {pendingRequest} = form;
    if (pendingRequest.status !== 'APPROVED') return;

    const certificate = pendingRequest.type === 'AE'
      ? <CertificateB1 form={pendingRequest} t={t} trans={Trans} auth={auth} />
      : <CertificateB2 form={pendingRequest} t={t} trans={Trans} auth={auth} />;

    return new Promise ((resolve, reject) => {
      const blob = pdf (certificate).toBlob ();
      resolve (blob);
    })
      .then (result => {
        const downloadUrl = window.URL.createObjectURL (new Blob ([result]));
        const link = document.createElement ('a');
        link.href = downloadUrl;
        link.setAttribute ('download', 'Certificat approbation.pdf'); //any other extension
        document.body.appendChild (link);
        link.click ();
        link.remove ();
      })
      .catch (err => {});
  };

  render () {
    const {t} = this.props;
    if (
      !isEmpty (this.props.form.pendingRequest) &&
      this.props.form.pendingRequest.status !== 'APPROVED'
    ) {
      return <Redirect to={`/discussion/${this.props.match.params.formid}`} />;
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center py-5">
            <h1>{t ('certificate:header')}</h1>
            <h2>{t ('certificate:approved')}</h2>
          </div>
          <div className="col-md-12 py-3 text-center">
            <h3>{t ('certificate:what_to_do')}</h3>
            <p>
              <i className="fas fa-exclamation-triangle pr-2" />
              {t ('certificate:sgi')}
              {' '}
            </p>
          </div>
          <div className="col-md-12 text-center">

            <button
              className="btn btn-main btn-block py-3 mb-3"
              onClick={this.DownloadCertificate}
            >
              <h3>
                {t ('certificate:download')}
              </h3>
            </button>
            <small>
              {t ('certificate:download_note')}
            </small>
          </div>
          <div className="col-md-12 py-5 text-center">
            <button className="btn btn-accent mx-auto">
              <Link to={`/discussion/${this.props.match.params.formid}`}>
                {t ('certificate:return')}
              </Link>
            </button>
          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({form: state.form, auth: state.auth});

const mapDispatchToProps = {GetFormById};

export default withTranslation (['ethic', 'rules', 'certificate']) (
  connect (mapStateToProps, mapDispatchToProps) (CertificateDownload)
);
