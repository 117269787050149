import React, {Component} from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  Link,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import * as S from './StyledCertificateComp';
import isEmpty from '../../../validation/isEmpty';
import {Trans} from 'react-i18next';

class CertificateB2 extends Component {
  constructor (props) {
    super (props);

    this.state = {
      datetime: moment ().format ('YYYY-MM-DD HH:mm:ss'),
    };
  }

  componentDidMount () {}

  RenderHeader = () => {
    const {lang} = this.props.auth;
    const xsc_logo_url = lang == 'en'
      ? '/images/certificat/Logo_Xsc_en.png'
      : '/images/certificat/Logo_Xsc.png';
    const rt_logo_url = lang == 'en'
      ? '/images/certificat/Logo_TechSc_en.png'
      : '/images/certificat/Logo_TechSc.png';

    return (
      <S.LogoGroup>
        <S.Logo src={xsc_logo_url} />
        <S.Logo src={rt_logo_url} />
      </S.LogoGroup>
    );
  };

  RenderPreambule = () => {
    if (isEmpty (this.props.form)) return;
    const {t} = this.props;
    const {answers, type, lang, conditions, approbationDate} = this.props.form;
    const {participantInformations, projectMembers} = answers;
    const {year, final} = participantInformations;
    const {member1_lastName, member2_lastName} = projectMembers;

    const prefix = 'rules';
    const formTitle = t (prefix + ':certificate.title');
    const formSubtitle = t (prefix + ':certificate.subtitle');
    const formPreamble = `
    ${t (prefix + ':certificate.preamble1')} ${year}\n
    ${t (prefix + ':certificate.preamble2')}\n 
    ${t (prefix + ':certificate.preamble3')} ${year} ${t (prefix + ':certificate.preamble4')}
    `;
    const formApprobationText = t (prefix + ':certificate.approbation_text');

    const participant1 = member1_lastName.substr (0, 5).toUpperCase ();
    const participant2 = member2_lastName.substr (0, 5).toUpperCase ();

    const cond = isEmpty (conditions) ? 'N/A' : conditions;

    return (
      <View>
        <S.Header1>
          {formTitle}
        </S.Header1>
        <S.Header2>
          {formSubtitle}
        </S.Header2>
        <S.Hr />
        <S.Para>
          {formPreamble}
        </S.Para>
        <S.Hr />
        <S.Lead>
          {formApprobationText}
        </S.Lead>
        <S.Hr />
        <S.ImportantInfo>
          <S.Strong>{t ('certificate:certificate_date')}</S.Strong>
          {' '}
          {moment (approbationDate).format ('YYYY-MM-DD HH:mm')}
        </S.ImportantInfo>
        <S.ImportantInfo>
          <S.Strong>{t ('certificate:number')}</S.Strong>
          {' '}
          {`${year}_${type.toUpperCase ()}_${lang.toUpperCase ()}_${participant1}${participant2}_${final}`}
        </S.ImportantInfo>
        <S.ImportantInfo>
          <S.Conditions>{t ('certificate:conditions')}</S.Conditions>{'\n'}
          <S.Conditions>{cond}</S.Conditions>
        </S.ImportantInfo>
      </View>
    );
  };

  RenderProjectInformation = () => {
    if (isEmpty (this.props.form)) return;
    const {t} = this.props;
    const {answers} = this.props.form;
    const {
      participantInformations,
      projectMembers,
      projectInformations,
    } = answers;
    const {year, final} = participantInformations;
    const {project_title} = projectInformations;
    const {
      member1_firstName,
      member1_lastName,
      member1_email,
      member2_firstName,
      member2_lastName,
      member2_email,
    } = projectMembers;
    const finalLongName = `${t ('certificate:B2.region.' + final)}`;

    return (
      <S.InfosBlockContainer2>
        <S.InfosBlock>
          <S.Header7>
            <S.Strong>{t ('rules:certificate.project')}</S.Strong>
          </S.Header7>
        </S.InfosBlock>
        <S.InfosBlock />
        <S.InfosBlock>
          <S.Para>{t ('rules:certificate.year')} {year}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>
            {t ('rules:certificate.final')} {finalLongName}
          </S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>
            {t ('rules:certificate.project_title')} {project_title}
          </S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para />
        </S.InfosBlock>

        <S.InfosBlock>
          <S.Header6>
            <S.Strong>{t ('rules:certificate.exhibitors')}</S.Strong>
          </S.Header6>
        </S.InfosBlock>
        <S.InfosBlock />

        <S.InfosBlock>
          <S.Para>{member1_firstName + ' ' + member1_lastName}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{member1_email}</S.Para>
        </S.InfosBlock>
        {!isEmpty (member2_lastName) &&
          <S.InfosBlock>
            <S.Para>{member2_firstName + ' ' + member2_lastName}</S.Para>
          </S.InfosBlock>}
        {!isEmpty (member2_email) &&
          <S.InfosBlock>
            <S.Para>{member2_email}</S.Para>
          </S.InfosBlock>}
      </S.InfosBlockContainer2>
    );
  };

  RenderSupervisorInformation = () => {
    if (isEmpty (this.props.form)) return;
    const {t} = this.props;
    const {answers} = this.props.form;
    const {supervisor, institution} = answers;

    const {institution_name} = institution;
    const {
      supervisor_firstName,
      supervisor_lastName,
      supervisor_email,
      supervisor_type,
      supervisor_type_other,
    } = supervisor;

    const supervisorTitle = supervisor_type === 'OTHER'
      ? supervisor_type_other
      : t ('certificate:B2.supervisor.' + supervisor_type);

    return (
      <S.InfosBlockContainer2>
        <S.InfosBlock>
          <S.Header7>
            <S.Strong>
              {t ('rules:certificate.supervisor')}
            </S.Strong>
          </S.Header7>
        </S.InfosBlock>
        <S.InfosBlock />
        <S.InfosBlock>
          <S.Para>{supervisor_firstName + ' ' + supervisor_lastName}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{supervisorTitle}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{institution_name}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{supervisor_email}</S.Para>
        </S.InfosBlock>
      </S.InfosBlockContainer2>
    );
  };

  RenderConsentSection = () => {
    return (
      <View>
        <S.Header3>
          Formulaire de consentement libre et individuel
        </S.Header3>
        <S.Para>
          Vous avez été approché par un exposant* aux Expo-sciences qui a décidé de présenter un projet en expérimentation nécessitant la participation active de sujets humains.
        </S.Para>
        <S.Para>
          Les règlements exigent que l’exposant doit,
          <S.Strong> AVANT </S.Strong>
          d’amorcer l’expérimentation, ait obtenu l'approbation du comité provincial de l’éthique du Réseau Technoscience pour que le projet soit jugé conforme aux règlements en vigueur.
        </S.Para>
        <S.Para>
          En tant que sujet humain participant au projet,
          <S.Strong>vous devez donner votre consentement libre </S.Strong>
          en remplissant le présent formulaire. Il est important que vous soyez informé des risques possibles liés à votre participation. Avant de signer ce présent formulaire, lisez attentivement tous les renseignements qui s’y trouvent et vérifiez que l’exposant* et le superviseur scientifique aient dûment signés leurs sections.
        </S.Para>
        <S.Para>
          Vous pouvez consulter les règlements en demandant une copie à l’exposant ou en visitant le site web du Réseau Technoscience :
          {' '}
          <Link src="https://technoscience.ca/programmes/expo-sciences/outils-et-reglements/reglements/">
            http://technoscience.ca
          </Link>
        </S.Para>
        <S.Space />
        <S.Header6><S.Strong>L’EXPOSANT* DOIT :</S.Strong></S.Header6>
        <S.Para>
          - S’assurer que les participants soient libres de participer et qu’aucune pression ou avantage n’est associé à cette participation;
          {'\n'}
          - Conserver une copie de tous les
          {' '}
          <S.Strong> Formulaires B </S.Strong>
          {' '}
          avec la signature des participants;
          {'\n'}
          - Remettre une copie signée aux participants;{'\n'}
          - Présenter tous les formulaires B dûment signés lors de la vérification des règlements au montage du stand aux finales.
          {'\n'}
        </S.Para>
        <S.Header5>
          <S.Strong>
            Le Réseau Technoscience et ses organismes membres vous remercient de votre participation !
          </S.Strong>
        </S.Header5>
      </View>
    );
  };

  RenderMain = () => {};
  RenderDescription = () => {
    const {answers} = this.props.form;
    const description = answers['3']['3_2'];
    return (
      <View>
        <S.Header6>1 - Description du projet</S.Header6>
        <S.Para>{description}</S.Para>
      </View>
    );
  };
  RenderTasks = () => {
    const {answers} = this.props.form;
    const tasks = answers['8']['8_3'];
    return (
      <View>
        <S.Header6>2 - Tâches demandées au sujet humain participant</S.Header6>
        <S.Para>{tasks}</S.Para>
      </View>
    );
  };
  RenderRisks = () => {
    const {answers} = this.props.form;
    const risks = answers['9']['9_1'];
    return (
      <View>
        <S.Header6>3 - Risques liés à la participation</S.Header6>
        <S.Para>{risks}</S.Para>
      </View>
    );
  };
  RenderRisksLimits = () => {
    const {answers} = this.props.form;
    const risksLimits = answers['9']['9_3'];
    return (
      <View>
        <S.Header6>4 - Moyens mis en place pour limiter les risques</S.Header6>
        <S.Para>{risksLimits}</S.Para>
      </View>
    );
  };
  RenderExpTime = () => {
    const {answers} = this.props.form;
    const time = answers['8']['8_4'];
    return (
      <View>
        <S.Header6>
          5 - Temps que le sujet humain participant doit consacrer à l'expérimentation
        </S.Header6>
        <S.Para>{time}</S.Para>
      </View>
    );
  };
  RenderExpConditions = () => {
    const {conditions} = this.props.form;
    return (
      <View>
        <S.Header6>
          6 - Conditions particulière à la réalisation de l'expérimentation
        </S.Header6>
        <S.Para>{isEmpty (conditions) ? 'N/A' : conditions}</S.Para>
      </View>
    );
  };

  RenderFooter = () => {
    const {t} = this.props;

    return (
      <View>
        <S.InfosBlock>
          <S.ImportantInfo>
            <S.Header3>
              <S.Strong>{t ('rules:certificate.upload_warning')}</S.Strong>
            </S.Header3>
          </S.ImportantInfo>
        </S.InfosBlock>
      </View>
    );
  };
  RenderFooterNotes = () => {
    const {t} = this.props;
    return (
      <S.Footer>
        <S.Small>{t ('rules:certificate.footer_date')}</S.Small>
        <S.Small>{t ('rules:certificate.footer_duo')}</S.Small>
        <S.Small>
          Page{' '}
          <Text
            render={({pageNumber}) =>
              `${pageNumber} ${t ('rules:certificate.of')} 1`}
            fixed
          />
        </S.Small>
      </S.Footer>
    );
  };
  render () {
    const styles = StyleSheet.create ({
      page: {padding: '30px'},
    });

    return (
      <Document
        title="Certificat d'approbation"
        author="Comité provincial des Règlements - Expo-sciences"
      >
        <Page size="LETTER" style={styles.page}>
          <S.PageContainer>
            {this.RenderHeader ()}
            {this.RenderPreambule ()}
            <S.HrBlue />
            {this.RenderProjectInformation ()}
            <S.FineHr />
            {this.RenderSupervisorInformation ()}
            {this.RenderFooter ()}
            {this.RenderFooterNotes ()}
          </S.PageContainer>
        </Page>
      </Document>
    );
  }
}

export default CertificateB2;
