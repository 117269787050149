import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCommitteeMembers, deleteUser} from '../../store/actions/userActions';
import DiscussionNav from '../discussion/DiscussionNav';
import AddUserModal from '../auth/AddUserModal';
import UpdateUserModal from '../auth/UpdateUserModal';
import isEmpty from '../../validation/isEmpty';
import {withTranslation} from 'react-i18next';

export class UsersList extends Component {
  constructor (props) {
    super (props);

    this.state = {
      addUserModal: '',
      updateUserModal: '',
    };
  }

  //==========================
  //#region LifeCycle methods
  //=========================
  componentDidMount () {
    //GET ALL USERS
    this.props.getCommitteeMembers ();
  }
  //#endregion

  DeleteUser = e => {
    this.props.deleteUser (e.currentTarget.dataset.id);
  };
  //==========================
  //#region Render methods
  //=========================
  RenderHeader = () => {
    const {t} = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <h1>{t ('users_list.header')}</h1>
        </div>
        <div className="col-md-12">
          <button className="btn btn-primary" onClick={this.ShowAddUserModal}>
            <h4>{t ('users_list.add_user')}</h4>
          </button>
        </div>
      </div>
    );
  };

  RenderAdminUsers = usersList => {
    const {t} = this.props;
    const currentUser = this.props.auth.user;

    const userItems = usersList.map (user => {
      return (
        <li
          key={user._id}
          className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        >
          <span className="">
            <strong>{`${user.firstName} ${user.lastName}`}</strong>
          </span>
          <span className="mr-auto px-3">
            {user.committee === 'BOTH'
              ? t ('users_list.ethic_rules')
              : user.committee === 'ETHICS'
                  ? t ('users_list.ethic')
                  : user.committee === 'RULES'
                      ? t ('users_list.rules')
                      : t ('users_list.no_committee')}
          </span>
          {currentUser._id !== user._id &&
            <span>
              <i
                className="fas fa-edit px3"
                data-id={user._id}
                onClick={this.ShowUpdateUserModal}
              />
              <i
                className="fas fa-trash px-3"
                data-id={user._id}
                onClick={this.DeleteUser}
              />
            </span>}
        </li>
      );
    });

    return (
      <div className="row  col-md-12 my-5">
        <div className="col-md-12">
          <h3>{t ('users_list.admin_users')}</h3>
        </div>
        <div className="col-md-12">
          <ul className="list-group">
            {userItems}
          </ul>
        </div>
      </div>
    );
  };
  RenderCoordinatorUsers = usersList => {
    const {t} = this.props;
    const userItems = usersList.map (user => {
      return (
        <li
          key={user._id}
          className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        >
          <span>{`${user.firstName} ${user.lastName}`}</span>
          <span>
            {user.committee === 'BOTH'
              ? t ('users_list.ethic_rules')
              : user.committee === 'ETHICS'
                  ? t ('users_list.ethic')
                  : user.committee === 'RULES'
                      ? t ('users_list.rules')
                      : t ('users_list.no_committee')}
          </span>
          <span>
            <i
              className="fas fa-edit px-3"
              data-id={user._id}
              onClick={this.ShowUpdateUserModal}
            />
            <i
              className="fas fa-trash px-3"
              data-id={user._id}
              onClick={this.DeleteUser}
            />
          </span>
        </li>
      );
    });

    return (
      <div className="row col-md-12 my-5">
        <div className="col-md-12">
          <h3>{t ('users_list.committee_users')}</h3>
        </div>
        <div className="col-md-12">
          <ul className="list-group">
            {userItems}
          </ul>
        </div>
      </div>
    );
  };

  RenderReadOnlyUsers = usersList => {
    const {t} = this.props;
    const userItems = usersList.map (user => {
      return (
        <li
          key={user._id}
          className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        >
          <span>{`${user.firstName} ${user.lastName}`}</span>
          <span>
            {user.committee === 'BOTH'
              ? t ('users_list.ethic_rules')
              : user.committee === 'ETHICS'
                  ? t ('users_list.ethic')
                  : user.committee === 'RULES'
                      ? t ('users_list.rules')
                      : t ('users_list.no_committee')}
          </span>
          <span>
            <i
              className="fas fa-edit px3"
              data-id={user._id}
              onClick={this.ShowUpdateUserModal}
            />
            <i
              className="fas fa-trash px-3"
              data-id={user._id}
              onClick={this.DeleteUser}
            />
          </span>
        </li>
      );
    });

    return (
      <div className="row col-md-12 my-5">
        <div className="col-md-12">
          <h3>{t ('users_list.guest_users')}</h3>
        </div>
        <div className="col-md-12">
          <ul className="list-group">
            {userItems}
          </ul>
        </div>
      </div>
    );
  };

  RenderUsers = () => {
    const {usersList} = this.props.user;

    if (this.props.user === undefined || isEmpty (usersList)) return;

    const sortedUsers = {
      ADMIN: [],
      COORDINATOR: [],
      READONLY: [],
      PARTICIPANT: [],
    };

    for (const user of usersList) {
      const role = user.role;
      if (role === undefined) return;
      sortedUsers[role].push (user);
    }

    return (
      <div className="row">
        {this.RenderAdminUsers (sortedUsers.ADMIN)}
        {this.RenderCoordinatorUsers (sortedUsers.COORDINATOR)}
        {this.RenderReadOnlyUsers (sortedUsers.READONLY)}
      </div>
    );
  };
  RenderMenuSideBar = () => {
    return <DiscussionNav />;
  };
  RenderAside = () => {};
  RenderMain = () => {
    return (
      <main className="discussion-main">
        {this.RenderHeader ()}
        {this.RenderUsers ()}
      </main>
    );
  };

  ClearModal = () => {
    document.getElementById ('closeModalBtn').click ();
    this.setState ({addUserModal: '', updateUserModal: ''});
  };

  ShowAddUserModal = () => {
    const addUserModal = <AddUserModal ClearModal={this.ClearModal} />;

    this.setState ({addUserModal}, () => {
      document.getElementById ('modalEntry-btn').click ();
    });
  };

  ShowUpdateUserModal = e => {
    const {usersList} = this.props.user;
    const userInfos = usersList.find (u => {
      return u._id === e.currentTarget.dataset.id;
    });
    const updateUserModal = (
      <UpdateUserModal userInfos={userInfos} ClearModal={this.ClearModal} />
    );

    this.setState ({updateUserModal}, () => {
      document.getElementById ('modalEntry-btn').click ();
    });
  };

  render () {
    const {addUserModal, updateUserModal} = this.state;
    return (
      <div className="discussion-container">
        {addUserModal}
        {updateUserModal}
        {this.RenderMenuSideBar ()}
        {this.RenderMain ()}
        {this.RenderAside ()}
      </div>
    );
  }
  //#endregion
}

const mapStateToProps = state => ({
  user: state.user,
  auth: state.auth,
});

const mapDispatchToProps = {getCommitteeMembers, deleteUser};

export default withTranslation (['pages']) (
  connect (mapStateToProps, mapDispatchToProps) (UsersList)
);
