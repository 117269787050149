import {
  SET_CURRENT_USER,
  SET_SPINNER,
  SET_LANG,
  CLEAR_ALL,
} from '../actions/types';
import isEmpty from '../../validation/isEmpty';

const initialState = {
  isAuthenticated: false,
  user: {},
  spinning: false,
  lang: 'fr',
};

//Controller for authentication states
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SPINNER:
      return {
        ...state,
        spinning: action.payload,
      };
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty (action.payload),
        user: action.payload,
      };
    case CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}
