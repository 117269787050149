import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class Protocol extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'protocol',
      isValid: false,

      answers: {
        project_hypothesis: '',
        project_tools: '',
        project_methodology: '',
        project_support: '',
        project_data: '',
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (isEmpty (answers.project_hypothesis)) isValid = false;
    if (isEmpty (answers.project_tools)) isValid = false;
    if (isEmpty (answers.project_methodology)) isValid = false;
    if (isEmpty (answers.project_support)) isValid = false;
    if (isEmpty (answers.project_data)) isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
  //  this.nameInput.focus ();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    return questions.includes(question);
  }

  RenderProjectHypothesis = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    
    let mustRenderQuestion =  this.MustRenderQuestion('project_hypothesis');

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_hypothesis">
                {t (`${sectionName}.project_hypothesis.title`)}
              </label>
             
              <textarea
                type="text"
                className="form-control"
                name="project_hypothesis"
                id="project_hypothesis"
                value={answers.project_hypothesis}
                onChange={this.OnHandleText}
                ref={input => {
                  this.nameInput = input;
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    
  };

  RenderProjectTools = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('project_tools');

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_tools">
                {t (`${sectionName}.project_tools.title`)}
              </label>
             
              <textarea
                type="text"
                className="form-control"
                name="project_tools"
                id="project_tools"
                value={answers.project_tools}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderProjectMethodology = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('project_methodology');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_methodology">
                {t (`${sectionName}.project_methodology.title`)}
              </label>
              
              <textarea
                type="text"
                className="form-control"
                name="project_methodology"
                id="project_methodology"
                value={answers.project_methodology}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderProjectSupport = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

   let mustRenderQuestion =  this.MustRenderQuestion('project_support');

   if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_support">
                {t (`${sectionName}.project_support.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="project_support"
                id="project_support"
                value={answers.project_support}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderProjectData = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('project_data');

    if (mustRenderQuestion)
    {
      return (
            <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="project_data">
                  {t (`${sectionName}.project_data.title`)} <b>{t (`${sectionName}.project_data.important`)}</b>
                </label>
                
                <textarea
                  type="text"
                  className="form-control"
                  name="project_data"
                  id="project_data"
                  value={answers.project_data}
                  onChange={this.OnHandleText}
                />
              </div>
            </div>
          </div>
      );
    }
  };

  RenderQuestions = () => {
    return (
      <Fragment>
        {this.RenderProjectHypothesis ()}
        <hr />
        {this.RenderProjectTools ()}
        <hr />
        {this.RenderProjectMethodology ()}
        <hr />
        {this.RenderProjectSupport ()}
        <hr />
        {this.RenderProjectData ()}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        <h6 className="section-text important">
          {t (sectionName + '.sectionText')}
        </h6>
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (Protocol)
);
