import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import classnames from 'classnames';
import {
  DownloadAttachment,
  DeleteAttachment,
  ToggleNewAttachment,
} from '../../store/actions/formActions';
export class Attachment extends Component {
  ToggleNewAttachment = () => {
    const {attachment} = this.props;
    if (this.props.auth.user.role !== 'ADMIN') return;
    this.props.ToggleNewAttachment (attachment);
  };
  DownloadAttachment = e => {
    const {attachment, auth} = this.props;
    if (auth.user.role === 'READONLY') return;
    this.props.DownloadAttachment (
      attachment.name,
      attachment.form,
      attachment.format
    );
  };
  DeleteAttachment = e => {
    const {attachment} = this.props;
    this.props.DeleteAttachment (
      attachment.name,
      attachment.form,
      attachment._id
    );
  };
  render () {
    const {attachment, role, t} = this.props;
    return (
      <div className="row attachment">
        {role !== 'PARTICIPANT' &&
          <div className="col-md-1" onClick={this.ToggleNewAttachment}>
            <span>
              <i
                className={classnames ('fas fa-star', {
                  'is-new': attachment.new,
                  'is-old': !attachment.new,
                })}
              />
            </span>
          </div>}
        <div className="col-md-9 pb-2">
          <span className="file link" onClick={this.DownloadAttachment}>
            {attachment.name}{' '}
          </span>
          <br />
          <span>
            <small>
              {t ('attachment.added')}
              {' '}
              {moment (attachment.datetime).format ('YYYY-MM-DD HH:mm')}
            </small>
          </span>
        </div>
        {role === 'ADMIN' &&
          <div className="col-md-1" onClick={this.DeleteAttachment}>
            <i className="fas fa-times-circle" />
          </div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({auth: state.auth});

const mapDispatchToProps = {
  DownloadAttachment,
  DeleteAttachment,
  ToggleNewAttachment,
};

export default withTranslation (['pages']) (
  connect (mapStateToProps, mapDispatchToProps) (Attachment)
);
