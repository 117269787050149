import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClearAllInfos, SetLanguage } from '../../store/actions/authActions';
import { GetSiteStyle } from "../../store/actions/siteStyleActions";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import isEmpty from "../../validation/isEmpty";
class Home extends Component {
  componentDidMount() {
    const { lang } = this.props.auth;
    i18next.changeLanguage(lang);
    this.props.GetSiteStyle();
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth !== this.props.auth) {
      i18next.changeLanguage(this.props.auth.lang);
      this.forceUpdate();
    }
    if (prevProps.siteStyle !== this.props.siteStyle) {
      this.SetSiteStyle();
    }
  }

  SetSiteStyle() {
    if(!isEmpty(this.props.siteStyle)){
      document.documentElement.style.setProperty('--data-color-main',this.props.siteStyle.mainColor);
      document.documentElement.style.setProperty('--data-color-secondary',this.props.siteStyle.secondaryColor);
      document.documentElement.style.setProperty('--data-color-accent',this.props.siteStyle.accentColor);
      document.documentElement.style.setProperty('--data-color-dark',this.props.siteStyle.darkColor);
      document.documentElement.style.setProperty('--data-color-light',this.props.siteStyle.lightColor);
    } 
  }
  RenderLogoContainer = () => {
    const { lang } = this.props.auth;

    return (
      <div className="logo-container py-5">
        <img
          className="mx-auto logo"
          src={`/images/Logo_XSC_noir_${i18next.language}.svg`}
          alt="logo"
        />
        <img
          className="mx-auto logo reseau"
          src={`/images/LogoTechnoscience_${i18next.language}.svg`}
          alt="logo"
        />
        <ul className="navbar-nav mr-auto lang-nav">
          {lang === 'fr' &&
            <li
              className="nav-item"
              onClick={() => {
                this.props.SetLanguage('en');
              }}
            >
              <span>English</span>
            </li>}

          {lang === 'en' &&
            <li
              className="nav-item"
              onClick={() => {
                this.props.SetLanguage('fr');
              }}
            >
              <span>Français</span>
            </li>}
        </ul>
      </div>
    );
  };
  RenderWelcomeText = () => {
    const { t } = this.props;
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 text-white text-justify">
          <h1 className="text-uppercase display-4 description text-white text-center">
            {t('home_title')}
          </h1>
          <h4>
            {t('home_subtitle')}
          </h4>
          <br></br>
          <h5>
            {t('home_description_1')}
            {'\u00A0'}
            {t('home_description_2')}
          </h5>
          <br></br>
          <h5>
              {t('home_warning')}
          </h5>
        </div>
      </div>
    );
  };
  RenderNavigation = () => {
    const { t } = this.props;

    return (
      <div className="row py-5 text-white text-center pointer btn-container">
        <div className="col-md-3 mx-2 btn-group">

          <Link to="/pre-questionnaire" className="text-white">
            <h3 className="py-3">
              <i className="fas fa-question-circle fa-2x" />
            </h3>
            <h3>{t('home_btn_dontknow')}</h3>
          </Link>
        </div>
        <div className="col-md-3 mx-2 btn-group">
          <Link to="/connexion" className="text-white">
            <h3 className="py-3">
              <i className="fas fa-biohazard fa-2x" />
            </h3>
            <h3>{t('home_btn_needform')}</h3>
          </Link>

        </div>
        <div className="col-md-3 mx-2 btn-group">
          <Link to="/connexion" className="text-white">
            <h3 className="py-3">
              <i className="fas fa-comment-dots fa-2x" />
            </h3>
            <h3>{t('home_btn_pendingrequest')}</h3>
          </Link>

        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="homePage">
        <div className="container ">
          {this.RenderLogoContainer()}

          {this.RenderWelcomeText()}
          {this.RenderNavigation()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  siteStyle:state.siteStyle
});
export default withTranslation('pages')(
  connect(mapStateToProps, { ClearAllInfos, SetLanguage, GetSiteStyle })(Home)
);
