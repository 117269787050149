import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import i18next from 'i18next';
import classnames from 'classnames';
import {
  SetForm,
  LoadForm,
  SaveSection,
  SaveForm,
  DeleteForm,
  SaveCorrectedAnswers,
} from '../../store/actions/formActions';
import moment from 'moment';

import DiscussionNav from '../discussion/DiscussionNav';
import DeleteWarningModal from './DeleteWarningModal';
//GENERAL SECTIONS
import ParticipantInformations
  from './Sections/General/ParticipantInformations';
import ProjectMembers from './Sections/General/ProjectMembers';
import ProjectInformation from './Sections/General/ProjectInformation';
import SecondYear from './Sections/General/SecondYear';
import Institution from './Sections/General/Institution';
import Supervisor from './Sections/General/Supervisor';

//ETHICS SECTIONS
import ActiveParticipationHuman
  from './Sections/Ethics/ActiveParticipationHuman';
import RecrutmentMethods from './Sections/Ethics/RecrutmentMethods';
import RisksAndLimitations from './Sections/Ethics/RisksAndLimitations';
import EvaluationByEthicsCommittee
  from './Sections/Ethics/EvaluationByEthicsCommittee';

//RULES SECTIONS
import AnimalBioMaterial from './Sections/Rules/AnimalBioMaterial';
import HumanBioMaterial from './Sections/Rules/HumanBioMaterial';
import ChemicalMaterial from './Sections/Rules/ChemicalMaterial';

//END SECTIONS
import Protocol from './Sections/General/Protocol';
import InstitutionsEngagements
  from './Sections/General/InstitutionsEngagements';
import isEmpty from '../../validation/isEmpty';

export class FormCorrection extends Component {
  constructor (props) {
    super (props);

    this.state = {
      modal: '',
      form: {},
      answers: {},
      selectedSection: 1,
      isValid: false,
      sections: {
        participantInformations: (
          <ParticipantInformations
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        projectMembers: (
          <ProjectMembers
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        projectInformations: (
          <ProjectInformation
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        secondYear: (
          <SecondYear
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        institution: (
          <Institution
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        supervisor: (
          <Supervisor
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        activeParticipationHuman: (
          <ActiveParticipationHuman
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        recrutmentMethods: (
          <RecrutmentMethods
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            SwitchSection={this.SwitchSection}
            GetAnswers={this.GetAnswers}
          />
        ),
        risksAndLimitations: (
          <RisksAndLimitations
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            SwitchSection={this.SwitchSection}
            GetAnswers={this.GetAnswers}
          />
        ),
        evaluationByEthicsCommittee: (
          <EvaluationByEthicsCommittee
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            SwitchSection={this.SwitchSection}
            GetAnswers={this.GetAnswers}
          />
        ),
        humanBioMaterial: (
          <HumanBioMaterial
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        animalBioMaterial: (
          <AnimalBioMaterial
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        chemicalMaterial: (
          <ChemicalMaterial
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        protocol: (
          <Protocol
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
        institutionsEngagements: (
          <InstitutionsEngagements
            SetAnswers={this.SetAnswers}
            CheckValid={this.CheckValid}
            GetAnswers={this.GetAnswers}
          />
        ),
      },
    };
  }

  componentDidMount () {
    this.props.LoadForm (this.props.match.params.formId);
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      prevProps.form !== this.props.form &&
      this.props.form.selectedForm !== null &&
      prevProps.form.selectedForm !== null
    ) {
      if (prevProps.form.selectedForm !== this.props.form.selectedForm) {
        const {answers} = this.props.form.selectedForm;
        this.setState ({answers});
      }
    }
  }

  CheckValid = isValid => {
    this.setState ({isValid});
  };

  GetAnswers = () => {
    return this.state.answers;
  };

  SetAnswers = section => {
    const {answers} = this.state;
    answers[section.sectionName] = section.answers;
    this.setState ({answers});
  };

  SaveCorrectedAnswers = e => {
    const {selectedForm} = this.props.form;
    const {answers} = this.state;
    const formInfos = {
      _id: selectedForm._id,
      corrections: answers,
      fullFormInfo : selectedForm
    };
    const is_admin = this.props.auth.user.role === 'ADMIN';
    this.props.SaveCorrectedAnswers (formInfos, this.props.history, is_admin);
  };

  RenderHeader = () => {
    const {t} = this.props;
    return (
      <div className="py-3">
        <h1>{t ('pages:corrections.header')}</h1>
        <button className={classnames ('btn btn-main my-3')}>
          <Link to={`/discussion/${this.props.form.selectedForm._id}`}>
            {t ('pages:corrections.return')}
          </Link>
        </button>
        <hr />
      </div>
    );
  };

  RenderSections = () => {
    if (isEmpty (this.props.form) || isEmpty (this.props.form.selectedForm))
      return;

    const {corrections, answers} = this.props.form.selectedForm;
    if (corrections === undefined) return;
    const sectionsToRender = [];

    Object.keys (corrections).map (correctionSection => {
      const section = this.state.sections[correctionSection];
      if (section === undefined) return;

      sectionsToRender.push (
        React.cloneElement (section, {
          FormAnswers: answers,
          mode: 'correction',
          questions: corrections[correctionSection],
        })
      );
    });

    return sectionsToRender;
  };

  RenderMenuSideBar = () => {
    return <DiscussionNav />;
  };
  RenderMain = () => {
    const {t} = this.props;
    const {selectedForm} = this.props.form;
    const {isValid} = this.state;
    if (selectedForm === null || selectedForm === undefined) return;
    return (
      <main className="discussion-main">
        <h3 className="py-3">{t (selectedForm.name)}</h3>
        {this.RenderHeader ()}
        {this.RenderSections ()}
        <div className="col-md-12">
          <button
            className={classnames ('btn btn-accent my-3', {
              'is-invalid': !isValid,
            })}
            onClick={this.SaveCorrectedAnswers}
          >
            {t ('pages:corrections.save')}
          </button>
        </div>

      </main>
    );
  };
  RenderAside = () => {};

  render () {
    return (
      <div className="discussion-container">

        {this.state.modal}
        {this.RenderMenuSideBar ()}
        {this.RenderMain ()}
        {this.RenderAside ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({auth: state.auth, form: state.form});

const mapDispatchToProps = {
  SetForm,
  LoadForm,
  SaveSection,
  SaveForm,
  DeleteForm,
  SaveCorrectedAnswers,
};

export default withTranslation (['form', 'pages']) (
  connect (mapStateToProps, mapDispatchToProps) (FormCorrection)
);
