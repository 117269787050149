import React, {Component} from 'react';
import './App.scss';
import {Provider} from 'react-redux';
import store from './store/store';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import ReactGA from 'react-ga'; //GOOGLE ANALYTICS
import createHistory from 'history/createBrowserHistory';
import {withTranslation} from 'react-i18next';

import setAuthHeader from './utils/setAuthHeader';
import jwt_decode from 'jwt-decode';
import {setCurrentUser, logoutUser} from './store/actions/authActions';
import {SetMessage} from './store/actions/messageActions';

//Structure
import MessageBox from './components/message/MessageBox';
import ErrorBox from './components/message/ErrorBox';
import Spinner from './components/home/Spinner';
//PAGES APP
import Home from './components/home/Home';
import FilterQuestionnaire from './components/form/FilterQuestionnaire';

/* import ClientLogin from './components/client/ClientLogin';
import ClientRegister from './components/client/ClientRegister'; */

//CLIENT PAGES
import RegularClientRoute from './components/auth/RegularClientRoute';
import PrivateRoute from './components/auth/PrivateRoute';

/* import ClientForgottenPwd from './components/client/ClientForgottenPwd';
import ClientResetPwd from './components/client/ClientResetPwd'; */

//PARTICIPANT PAGES
import ParticipantDiscussionDashboard
  from './components/discussion/ParticipantDiscussionDashboard';
import ParticipantDiscussionApproved
  from './components/discussion/ParticipantDiscussionApproved';

import ParticipantDiscussionDenied
  from './components/discussion/ParticipantDiscussionDenied';

import ParticipantDiscussionArchived
  from './components/discussion/ParticipantDiscussionArchived';
import ParticipantDiscussionView
  from './components/discussion/ParticipantDiscussionView';
import CertificateDownload
  from './components/form/certificate/CertificateDownload';
import CertificateTestB1 from './components/form/certificate/CertificateTestB1';
import Form2 from './components/form/Form2';
import FormCorrection from './components/form/FormCorrection';
//ADMIN PAGES
//import RegularAdminRoute from './components/auth/RegularAdminRoute';
//import AdminRoute from './components/auth/AdminRoute';
//import CoordinatorRoute from './components/auth/CoordinatorRoute';
import Login from './components/auth/Login';
//import ExportToExcel from './components/exportation/ExportToExcel';
import RegisterUser from './components/auth/RegisterUser';
import UsersList from './components/auth/UsersList';
import EditHomepage  from "./components/home/EditHome";
import EditTranslations from "./components/translations/EditTranslations";

import AuthForgottenPwd from './components/auth/AuthForgottenPwd';
import AuthResetPwd from './components/auth/AuthResetPwd';

//INITIALIZE GOOGLE ANALYTICS
/* ReactGA.initialize ('UA-109951286-2');
ReactGA.pageview (window.location.pathname + window.location.search); */

/* const history = createHistory ();
history.listen (location => {
  ReactGA.set ({page: location.pathname});
  ReactGA.pageview (location.pathname);
}); */

//if there is a token already in localstorage,
//Allows to keep users info if page reloads
if (localStorage.ethiqueTechnoscience) {
  //Set authorization header for each request
  setAuthHeader (localStorage.ethiqueTechnoscience);
  //Decode user's token
  const userDecoded = jwt_decode (localStorage.ethiqueTechnoscience);
  //Set current user and authentication
  store.dispatch (setCurrentUser (userDecoded));

  //Checks expired token
  const currentTime = Date.now () / 1000;
  if (userDecoded.exp < currentTime) {
    store.dispatch (logoutUser ());
    //Redirects
    store.dispatch (SetMessage ('USER_LOGOUT', 'Vous devez vous reconnecter.'));
    window.location.href = '/';
  }
}

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Spinner />

            <Switch>
              <RegularClientRoute exact path="/" component={Home} />
              <RegularClientRoute exact path="/connexion" component={Login} />
              <RegularClientRoute
                exact
                path="/nouveau-compte"
                component={RegisterUser}
              />
              <RegularClientRoute
                exact
                path="/mot-de-passe"
                component={AuthForgottenPwd}
              />
              <RegularClientRoute
                exact
                path="/reinit-mot-de-passe/:id"
                component={AuthResetPwd}
              />
              <RegularClientRoute
                exact
                path="/pre-questionnaire"
                component={FilterQuestionnaire}
              />
              <RegularClientRoute
                exact
                path="/test-certificat-b1"
                component={CertificateTestB1}
              />
              <PrivateRoute exact path="/form/:formId" component={Form2} />
              <PrivateRoute
                exact
                path="/correction/:formId"
                component={FormCorrection}
              />

              <PrivateRoute
                exact
                path="/mes-demandes"
                component={ParticipantDiscussionDashboard}
              />
              <PrivateRoute
                exact
                path="/demandes-approuvees"
                component={ParticipantDiscussionApproved}
              />
              <PrivateRoute
                exact
                path="/demandes-refusees"
                component={ParticipantDiscussionDenied}
              />
              <PrivateRoute
                exact
                path="/certificat/:formid"
                component={CertificateDownload}
              />
              <PrivateRoute
                exact
                path="/demandes-archivees"
                component={ParticipantDiscussionArchived}
              />
              <PrivateRoute
                exact
                path="/discussion/:formId"
                component={ParticipantDiscussionView}
              />

              <PrivateRoute
                exact
                path="/discussion/participantView"
                component={ParticipantDiscussionView}
              />
              <PrivateRoute
                exact
                path="/discussion/participantDashboard"
                component={ParticipantDiscussionDashboard}
              />
              <PrivateRoute exact path="/utilisateurs" component={UsersList} />
              <PrivateRoute exact path="/visuel-accueil" component={EditHomepage} />
              <PrivateRoute exact path="/traductions" component={EditTranslations} />

            </Switch>
            <MessageBox />
            <ErrorBox />
          </div>
        </Router>
      </Provider>
    );
  }
}
export default withTranslation ('common') (App);
