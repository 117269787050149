export const ValidateEmail = value => {
  var regex = /^[\w\d.-]+@[\w\d.-]+\.[\w]{2,}$/;
  return regex.test(value);
};

export const ValidateCharAmount = (text, min, max) => {
  var regex = new RegExp(`^.{${min},${max}}$`);
  return regex.test(text);
};

export const ValidateWordAmount = (text, min, max = null) => {
  const textToValidate = text.trim();
  const wordAmount = textToValidate
    .trim()
    .split(" ")
    .filter((word) => { if (word !== '') return word })
    .length;
  
  return (wordAmount >= min && (max === null || wordAmount <= max));

};
