import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {resetUserPassword} from '../../store/actions/authActions';

class AuthResetPwd extends Component {
  constructor (props) {
    super (props);
    this.state = {
      password: '',
      password2: '',
    };
  }

  OnSubmit = e => {
    this.props.resetUserPassword (
      this.props.match.params.id,
      this.state.password,
      this.state.password2,
      this.props.history
    );
  };

  OnChange = e => {
    this.setState ({[e.target.name]: e.target.value});
  };

  RenderHeader = () => {
    return (
      <div className="row">
        <div className="col-md-8 m-auto">
          <h1 className="display-4 text-center">Modifier votre mot de passe</h1>
        </div>
      </div>
    );
  };
  RenderForm = () => {
    return (
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="password">Nouveau mot de passe</label>
          <input
            type="password"
            className="form-control form-control-lg"
            name="password"
            value={this.state.password}
            onChange={this.OnChange}
          />
        </div>

        <div className="form-group col-md-12">
          <label htmlFor="password">Veuillez confirmer le mot de passe</label>
          <input
            type="password"
            className="form-control form-control-lg"
            name="password2"
            value={this.state.password2}
            onChange={this.OnChange}
          />
        </div>
        <div className="col-md-4 offset-md-2">
          <button
            className="btn btn-main btn-block p-2"
            onClick={this.OnSubmit}
          >
            <h4>Enregistrer</h4>
          </button>
        </div>
        <div className="col-md-4">
          <button className="btn btn-accent btn-block p-2">
            <Link to="/connexion">
              <h4>Annuler</h4>
            </Link>
          </button>
        </div>
      </div>
    );
  };
  render () {
    return (
      <div className="container">
        {this.RenderHeader ()}
        {this.RenderForm ()}

      </div>
    );
  }
}

export default connect (null, {resetUserPassword}) (AuthResetPwd);
