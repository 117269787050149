import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

import { withTranslation } from 'react-i18next';
import { logoutUser, SetLanguage } from '../../store/actions/authActions';
import { CheckIncomplete } from '../../store/actions/formActions';
import isEmpty from '../../validation/isEmpty';

export class DiscussionNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingRequest: null,
    };
  }

  componentDidMount() {
    const { lang, user } = this.props.auth;
    i18next.changeLanguage(lang);
    this.forceUpdate();
    this.props.CheckIncomplete(user._id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth !== this.props.auth) {
      i18next.changeLanguage(this.props.auth.lang);
      if (prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
        this.props.CheckIncomplete(this.props.auth.user._id);
      }
      this.forceUpdate();
    }
  }
  Logout = () => {
    this.props.logoutUser();
  };

  render() {
    const { t, auth } = this.props;
    const { lang } = this.props.auth;
    const logoUrl = `/images/Logo_XSC_noir_${i18next.language}.svg`;
    const { user } = auth;
    const formid = !isEmpty(this.props.form.selectedForm)
      ? this.props.form.selectedForm._id
      : null;

    return (
      <nav className="discussion-nav">
        <div className="logo">
          <NavLink activeClassName="selected" to="/">
            <img src={logoUrl} alt="" />
          </NavLink>
        </div>
        <div className="nav row">
          {user.role === 'PARTICIPANT' &&
            <Fragment>
              {!isEmpty(formid)
                ? <div className="col-md-12">
                  <NavLink activeClassName="selected" to={`/form/${formid}`}>
                    <h4>{t('nav_incomplete_form')}</h4>
                  </NavLink>
                </div>
                : <div className="col-md-12">
                  <NavLink activeClassName="selected" to="/form/new">
                    <h4>{t('nav_new_form')}</h4>
                  </NavLink>
                </div>}
            </Fragment>}

          <div className="col-md-12">
            <NavLink activeClassName="selected" to="/mes-demandes">
              <h4>{t('nav_pending_forms')}</h4>
            </NavLink>
          </div>

          <div className="col-md-12">
            <NavLink activeClassName="selected" to="/demandes-approuvees">
              <h4>{t('nav_approved_forms')}</h4>
            </NavLink>
          </div>

          <div className="col-md-12">
            <NavLink activeClassName="selected" to="/demandes-refusees">
              <h4>{t('nav_denied_forms')}</h4>
            </NavLink>
          </div>

          <div className="col-md-12">
            <NavLink activeClassName="selected" to="/demandes-archivees">
              <h4>{t('nav_archived_forms')}</h4>
            </NavLink>
          </div>

          {user.role === 'ADMIN' &&
            user.userAccess === true &&
            <div className="col-md-12">
              <NavLink activeClassName="selected" to="/utilisateurs">
                <h4>{t('nav_users_list')}</h4>
              </NavLink>
            </div>}

          {user.role === 'ADMIN' &&
            user.userAccess === true &&
            <div className="col-md-12">
              <NavLink activeClassName="selected" to="/visuel-accueil">
                <h4>{t('nav_homepage')}</h4>
              </NavLink>
            </div>}
            
            {user.role === 'ADMIN' &&
            user.userAccess === true &&
            <div className="col-md-12">
              <NavLink activeClassName="selected" to="/traductions">
                <h4>{t('nav_translations')}</h4>
              </NavLink>
            </div>}

          <div className="col-md-12">
            <h5 className="mt-4" onClick={this.Logout}>
              <a href="#">
                <i className="fas fa-sign-out-alt pr-2" />{t('nav_logout')}
              </a>
            </h5>
          </div>
          <div className="col-md-12 mt-5 pl-4 lang-nav">
            {lang === 'fr' &&
              <div
                className="nav-item"
                onClick={() => {
                  this.props.SetLanguage('en');
                }}
              >
                <span>English</span>
              </div>}

            {lang === 'en' &&
              <div
                className="nav-item"
                onClick={() => {
                  this.props.SetLanguage('fr');
                }}
              >
                <span>Français</span>
              </div>}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({ auth: state.auth, form: state.form });

const mapDispatchToProps = {
  logoutUser,
  SetLanguage,
  CheckIncomplete,
};

export default withTranslation('pages')(
  connect(mapStateToProps, mapDispatchToProps)(DiscussionNav)
);
