//IMPORT ACTIONS
import {
   GET_SITE_STYLE,
   EDIT_SITE_STYLE,
   CREATE_SITE_STYLE,
   CLEAR_ALL
  } from '../actions/types';

  const initialState = {
  };

  const siteStyleReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SITE_STYLE:
        return {
          ...state,
          ...action.payload,
        };
      case EDIT_SITE_STYLE:
        return {
          ...state,
          ...action.payload,
        };
        case CREATE_SITE_STYLE:
          return {
            ...state,
            ...action.payload,
          };
      case CLEAR_ALL:
        return initialState;
      default:
        return state;
    }
  };
  
  export default siteStyleReducer;