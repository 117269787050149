import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';
import {getAllRegions} from '../../../../store/actions/regionActions';
import {ValidateEmail} from '../../../../validation/fieldValidation';
import classnames from 'classnames';
export class ParticipantInformations extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'participantInformations',
      isValid: false,

      answers: {
        year: moment ().isBefore (moment ().month (6).date (1))
          ? moment ().format ('YYYY')
          : moment ().add (1, 'year').format ('YYYY'),
        final: '',
        level: 'HIGHSCHOOL',
        school: '',
        teacher_firstName: '',
        teacher_lastName: '',
        teacher_email: '',
        project_type: ''
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;
    let errors = {};
    if (isEmpty (answers.year)) errors.year = 'YEAR';
    if (isEmpty (answers.final)) errors.final = 'FINAL';
    if (isEmpty (answers.level)) errors.level = 'LEVEL';
    if (isEmpty (answers.school)) errors.school = 'SCHOOL';
    if (isEmpty (answers.teacher_firstName))
      errors.teacher_firstName = 'TEACHER FIRSTNAME';
    if (isEmpty (answers.teacher_lastName))
      errors.teacher_lastName = 'TEACHER LASTNAME';
    if (isEmpty (answers.teacher_email)) errors.teacher_email = 'TEACHER EMAIL';
    if (!ValidateEmail (answers.teacher_email))
      errors.teacher_email = 'TEACHER EMAIL2';
    if (isEmpty (answers.project_type)) errors.project_type = 'PROJECT_TYPE';

    if (Object.keys (errors).length > 0) isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    this.props.getAllRegions ();
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
    this.props.mode === 'fill' && this.nameInput.focus ();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;

    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };


  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }

    return false;
  }

  RenderYearQuestion = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const yearStart = moment ().isBefore (moment ().month (6).date (1))
      ? moment ().format ('YYYY')
      : moment ().add (1, 'year').format ('YYYY');

    const options = [];
    for (let i = 0; i < 3; i++) {
      let year = moment ()
        .year (yearStart)
        .month (1)
        .date (1)
        .add (i, 'year')
        .format ('YYYY');
      options.push (<option key={i} value={year}>{year}</option>);
    }
    const isDisabled =
      this.props.mode === 'correction' && this.props.question === 'year';

    let mustRenderQuestion =  this.MustRenderQuestion('year');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="year">{t (`${sectionName}.year.title`)}</label>
              <select
                className={classnames ('custom-select', {
                  'is-invalid': isDisabled,
                })}
                disabled={isDisabled}
                name="year"
                id="year"
                value={answers.year}
                onChange={this.OnHandleText}
                ref={input => {
                  this.nameInput = input;
                }}
              >
                <option>{t (`inputs.select.empty`)}</option>
                {options}
              </select>
            </div>
            <hr />   
          </div>

        </div>
      );
    }
   
  };

  RenderFinalQuestion = () => {
    const {t, region} = this.props;
    const {sectionName, answers} = this.state;
    const {regionsList} = region;

    const options = regionsList.map (r => {
      if (r.shortName === 'RT') return null;
      return (
        <option value={r.shortName} key={r.shortName}>

          {t (`${sectionName}.final.options.${r.shortName}`)}
        </option>
      );
    });

    let mustRenderQuestion_final =  this.MustRenderQuestion('final');
    let mustRenderQuestion_level =  this.MustRenderQuestion('level');

    return (
      <div className="row">
        {mustRenderQuestion_final &&
         
         <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="final">{t (`${sectionName}.final.title`)}</label>
              <select
                className="custom-select"
                name="final"
                id="final"
                value={answers.final}
                onChange={this.OnHandleText}
              >
                <option value="">{t (`inputs.select.empty`)}</option>
                {options}
              </select>
            </div>
            <hr />
          </div>
        }



        {mustRenderQuestion_level &&
          <div className="col-md-12">
            <div className="form-group">
              <label>{t (`${sectionName}.level.title`)}</label>
              { this.RenderLevelsOptions ()}
            </div>
            <hr />
          </div>
        }

      </div>
    );
  };

  RenderLevelsOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'ELEMENTARY1',
      'ELEMENTARY2',
      'ELEMENTARY3',
      'ELEMENTARY4',
      'ELEMENTARY5',
      'ELEMENTARY6',
      'HIGHSCHOOL1',
      'HIGHSCHOOL2',
      'HIGHSCHOOL3',
      'HIGHSCHOOL4',
      'HIGHSCHOOL5',
      'CEGEP1',
      'CEGEP2',
      'CEGEP3',
      'TECHNIQUE',
    ];
    const options = [];
    let index = 0;
    for (let type of types) {
      options.push (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            type="radio"
            name="level"
            id={type}
            value={type}
            onChange={this.OnHandleRadio}
            checked={answers.level === type ? 'checked' : ''}
          />
          <label className="form-check-label" htmlFor={type}>
            {t (`${sectionName}.level.options.${type}`)}
          </label>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderSchoolName = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion=  this.MustRenderQuestion('school');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="school">{t (`${sectionName}.school.title`)}</label>
              <input
                type="text"
                className="form-control"
                name="school"
                id="school"
                value={answers.school}
                onChange={this.OnHandleText}
                maxLength="75"
              />
            </div>
            <hr />
          </div>
        </div>
      );
    }
    
  };

  RenderTeacherInfos = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion_name =  this.MustRenderQuestion(['teacher_firstName', 'teacher_lastName']);
    let mustRenderQuestion_email =  this.MustRenderQuestion('teacher_email');

    return (

      <div>

        {mustRenderQuestion_name && 
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="teacher_firstName">
                  {t (`${sectionName}.teacher_firstName.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="teacher_firstName"
                  id="teacher_firstName"
                  value={answers.teacher_firstName}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="teacher_lastName">
                  {t (`${sectionName}.teacher_lastName.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="teacher_lastName"
                  id="teacher_lastName"
                  value={answers.teacher_lastName}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
            </div>
          </div>
        }

        {mustRenderQuestion_email && 
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="teacher_email">
                  {t (`${sectionName}.teacher_email.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="teacher_email"
                  id="teacher_email"
                  value={answers.teacher_email}
                  onChange={this.OnHandleText}
                  maxLength="75"
                />
              </div>
              <hr />
            </div>
          </div>
        }

        </div>
    );
  };

  RenderProjectTypeOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const project_types = [
      'EXPERIMENT',
      'CONCEPTION',
    ];
    const options = [];
    let index = 0;
    for (let ptype of project_types) {
      options.push (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            type="radio"
            name="project_type"
            id={ptype}
            value={ptype}
            onChange={this.OnHandleRadio}
            checked={answers.project_type === ptype ? 'checked' : ''}
          />
          <label className="form-check-label" htmlFor={ptype}>
            {t (`${sectionName}.project_type.options.${ptype}`)}
          </label>
        </div>
      );
      index++;
    }
    return options;
  };


  RenderProjectType = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('project_type');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <hr />
            <p>{t (`${sectionName}.project_type.title`)}</p>
            { this.RenderProjectTypeOptions ()}
          </div>
        </div>
      );
    }
   
  };

  
  RenderQuestions = () => {
    return (
      <Fragment>
        {this.RenderYearQuestion ()}

        {this.RenderFinalQuestion ()}

        {this.RenderSchoolName ()}

        {this.RenderTeacherInfos ()}
        {this.RenderProjectType ()}

      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {getAllRegions};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (ParticipantInformations)
);
