import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class RecrutmentMethods extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'recrutmentMethods',
      isValid: false,

      answers: {
        human_subjects_participation: 'NO',

        recrutment_participants: '',
        recrutment_amount: '',
        recrutment_age_0_12: false,
        recrutment_age_13_17: false,
        recrutment_age_18_29: false,
        recrutment_age_30_49: false,
        recrutment_age_50_69: false,
        recrutment_age_70_over: false,

        recrutment_process: '',
        recrutment_exclusion_age: false,
        recrutment_exclusion_gender: false,
        recrutment_exclusion_origin: false,
        recrutment_exclusion_social_condition: false,
        recrutment_exclusion_handicap: false,
        recrutment_exclusion_language: false,
        recrutment_exclusion_sexual_orientation: false,
        recrutment_exclusion_religious_association: false,
        recrutment_exclusion_precision: '',

        recrutment_message: '',

        recrutment_diffusion_people_exhibitors: false,
        recrutment_diffusion_people_supervisor: false,
        recrutment_diffusion_people_teacher: false,
        recrutment_diffusion_people_parent: false,
        recrutment_diffusion_people_authority: false,
        recrutment_diffusion_people_other: '',

        recrutment_diffusionMethod_person: false,
        recrutment_diffusionMethod_phone: false,
        recrutment_diffusionMethod_email: false,
        recrutment_diffusionMethod_social_medias: false,
        recrutment_diffusionMethod_mail: false,
        recrutment_diffusionMethod_other: '',
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.recrutment_participants)
    )
      isValid = false;
    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.recrutment_amount)
    )
      isValid = false;
    if (
      answers.human_subjects_participation === 'YES' &&
      (answers.recrutment_age_0_12 === false &&
        answers.recrutment_age_13_17 === false &&
        answers.recrutment_age_18_29 === false &&
        answers.recrutment_age_30_49 === false &&
        answers.recrutment_age_50_69 === false &&
        answers.recrutment_age_70_over === false)
    )
      isValid = false;

    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.recrutment_process)
    )
      isValid = false;

    if (
      answers.human_subjects_participation === 'YES' &&
      ((answers.recrutment_exclusion_age === true ||
        answers.recrutment_exclusion_gender === true ||
        answers.recrutment_exclusion_origin === true ||
        answers.recrutment_exclusion_social_condition === true ||
        answers.recrutment_exclusion_handicap === true ||
        answers.recrutment_exclusion_language === true ||
        answers.recrutment_exclusion_sexual_orientation === true ||
        answers.recrutment_exclusion_religious_association === true) &&
        isEmpty (answers.recrutment_exclusion_precision))
    )
      isValid = false;

    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.recrutment_message)
    )
      isValid = false;

    if (
      answers.human_subjects_participation === 'YES' &&
      (answers.recrutment_diffusion_people_authority === false &&
        answers.recrutment_diffusion_people_exhibitors === false &&
        answers.recrutment_diffusion_people_parent === false &&
        answers.recrutment_diffusion_people_supervisor === false &&
        answers.recrutment_diffusion_people_teacher === false &&
        isEmpty (answers.recrutment_diffusion_people_other))
    )
      isValid = false;

    if (
      answers.human_subjects_participation === 'YES' &&
      (answers.recrutment_diffusionMethod_email === false &&
        answers.recrutment_diffusionMethod_mail === false &&
        answers.recrutment_diffusionMethod_person === false &&
        answers.recrutment_diffusionMethod_phone === false &&
        answers.recrutment_diffusionMethod_social_medias === false &&
        isEmpty (answers.recrutment_diffusionMethod_other))
    )
      isValid = false;
    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      if (
        isEmpty (FormAnswers.activeParticipationHuman) &&
        isEmpty (
          FormAnswers.activeParticipationHuman.human_subjects_participation
        )
      ) {
        this.props.SwitchSection (7);
      }

      // PRECEDENT SECTION == NO
      if (
        FormAnswers.activeParticipationHuman.human_subjects_participation ===
        'NO'
      ) {
        const answers = {
          human_subjects_participation: 'NO',

          recrutment_participants: '',
          recrutment_amount: '',
          recrutment_age_0_12: false,
          recrutment_age_13_17: false,
          recrutment_age_18_29: false,
          recrutment_age_30_49: false,
          recrutment_age_50_69: false,
          recrutment_age_70_over: false,

          recrutment_process: '',
          recrutment_exclusion_age: false,
          recrutment_exclusion_gender: false,
          recrutment_exclusion_origin: false,
          recrutment_exclusion_social_condition: false,
          recrutment_exclusion_handicap: false,
          recrutment_exclusion_language: false,
          recrutment_exclusion_sexual_orientation: false,
          recrutment_exclusion_religious_association: false,
          recrutment_exclusion_precision: '',

          recrutment_message: '',

          recrutment_diffusion_people_exhibitors: false,
          recrutment_diffusion_people_supervisor: false,
          recrutment_diffusion_people_teacher: false,
          recrutment_diffusion_people_parent: false,
          recrutment_diffusion_people_authority: false,
          recrutment_diffusion_people_other: '',

          recrutment_diffusionMethod_person: false,
          recrutment_diffusionMethod_phone: false,
          recrutment_diffusionMethod_email: false,
          recrutment_diffusionMethod_social_medias: false,
          recrutment_diffusionMethod_mail: false,
          recrutment_diffusionMethod_other: '',
        };

        this.setState ({answers}, () => {
          this.props.SetAnswers (this.state);
          this.Validate ();
        });
      } else {
        FormAnswers[sectionName].human_subjects_participation =
          FormAnswers.activeParticipationHuman.human_subjects_participation;
        this.setState ({answers: FormAnswers[sectionName]}, () => {
          this.Validate ();
        });
      }
    } else {
      this.props.SetAnswers (this.state);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      FormAnswers[sectionName].human_subjects_participation =
        FormAnswers.activeParticipationHuman.human_subjects_participation;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        if (this.nameInput !== undefined) {
          this.nameInput.focus ();
        }
        this.Validate ();
      });
    }
  }

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }
    return false;
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;

    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  RenderEthicControlQuestion = () => {
    const {t} = this.props;
    const {answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('human_subjects_participation');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>
              {t (`activeParticipationHuman.human_subjects_participation.title`)}
            </p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'YES' ? 'checked' : ''
                }
                disabled="disabled"
              />
              <label className="form-check-label" htmlFor="yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="NO"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'NO' ? 'checked' : ''
                }
                disabled="disabled"
              />
              <label className="form-check-label" htmlFor="NO">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderRecrutedPeople = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion_recrutement =  this.MustRenderQuestion(['human_subjects_participation', 'recrutment_participants']);
    let mustRenderQuestion_amount =  this.MustRenderQuestion(['human_subjects_participation', 'recrutment_amount']);

    return (
      <div className="row">
        {mustRenderQuestion_recrutement &&

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="recrutment_participants">
                {t (`${sectionName}.recrutment_participants.title`)}
              </label>
              <p className="important">
                {t (`${sectionName}.recrutment_participants.text`)}
              </p>
              <textarea
                type="text"
                className="form-control"
                name="recrutment_participants"
                id="recrutment_participants"
                value={answers.recrutment_participants}
                onChange={this.OnHandleText}
                ref={input => {
                  this.nameInput = input;
                }}
              />
            </div>
          </div>

        }

        {mustRenderQuestion_amount &&
          
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="recrutment_amount">
                {t (`${sectionName}.recrutment_amount.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="recrutment_amount"
                id="recrutment_amount"
                value={answers.recrutment_amount}
                onChange={this.OnHandleText}
              />
            </div>
          </div>

        }

      </div>
    );
  };

  RenderAgeGroupsOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'recrutment_age_0_12',
      'recrutment_age_13_17',
      'recrutment_age_18_29',
      'recrutment_age_30_49',
      'recrutment_age_50_69',
      'recrutment_age_70_over',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-6 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderAgeGroups = () => {
    const {t} = this.props;
    const {sectionName} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_participation', 
      'recrutment_age_0_12',
      'recrutment_age_13_17',
      'recrutment_age_18_29',
      'recrutment_age_30_49',
      'recrutment_age_50_69',
      'recrutment_age_70_over'
    ]);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.recrutment_age.title`)}</p>
          </div>
  
          {this.RenderAgeGroupsOptions ()}
        </div>
      );
    }
  };

  RenderRecrutmentProcess = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_participation', 
      'recrutment_process'
    ]);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="recrutment_process">
                {t (`${sectionName}.recrutment_process.title`)}
              </label>

              <textarea
                type="text"
                className="form-control"
                name="recrutment_process"
                id="recrutment_process"
                value={answers.recrutment_process}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderExclusionsOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'recrutment_exclusion_age',
      'recrutment_exclusion_gender',
      'recrutment_exclusion_origin',
      'recrutment_exclusion_social_condition',
      'recrutment_exclusion_handicap',
      'recrutment_exclusion_language',
      'recrutment_exclusion_sexual_orientation',
      'recrutment_exclusion_religious_association',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderExclusions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_participation', 
      'recrutment_exclusion_precision',
      'exclusions',
      'recrutment_exclusion_age',
      'recrutment_exclusion_gender',
      'recrutment_exclusion_origin',
      'recrutment_exclusion_social_condition',
      'recrutment_exclusion_handicap',
      'recrutment_exclusion_language',
      'recrutment_exclusion_sexual_orientation',
      'recrutment_exclusion_religious_association'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12 ">
            <p>{t (`${sectionName}.exclusions.title`)}</p>
            <p className="important">
              {t (`${sectionName}.exclusions.text`)}
            </p>
          </div>

          {this.RenderExclusionsOptions ()}
          <hr />
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <label htmlFor="recrutment_exclusion_precision">
                {t (`${sectionName}.recrutment_exclusion_precision.title`)}
              </label>
              <p className="important">
                {t (`${sectionName}.recrutment_exclusion_precision.text`)}
              </p>
              <textarea
                type="text"
                className="form-control"
                name="recrutment_exclusion_precision"
                id="recrutment_exclusion_precision"
                value={answers.recrutment_exclusion_precision}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };
  RenderMessage = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_participation', 
      'recrutment_message'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="recrutment_message">
                {t (`${sectionName}.recrutment_message.title`)}
              </label>

              <textarea
                type="text"
                className="form-control"
                name="recrutment_message"
                id="recrutment_message"
                value={answers.recrutment_message}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderDiffusionPeopleOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'recrutment_diffusion_people_exhibitors',
      'recrutment_diffusion_people_supervisor',
      'recrutment_diffusion_people_teacher',
      'recrutment_diffusion_people_parent',
      'recrutment_diffusion_people_authority',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };
  RenderDiffusionPeople = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_participation', 
      'recrutment_diffusion_people',
      'recrutment_diffusion_people_other',
      'recrutment_diffusion_people_exhibitors',
      'recrutment_diffusion_people_supervisor',
      'recrutment_diffusion_people_teacher',
      'recrutment_diffusion_people_parent',
      'recrutment_diffusion_people_authority'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12 ">
            <p>{t (`${sectionName}.recrutment_diffusion_people.title`)}</p>
            <p className="important">
              {t (`${sectionName}.recrutment_diffusion_people.text`)}
            </p>
          </div>

          {this.RenderDiffusionPeopleOptions ()}
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="recrutment_diffusion_people_other">
                {t (`${sectionName}.recrutment_diffusion_people_other.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="recrutment_diffusion_people_other"
                id="recrutment_diffusion_people_other"
                value={answers.recrutment_diffusion_people_other}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderDiffusionMethodsOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'recrutment_diffusionMethod_person',
      'recrutment_diffusionMethod_phone',
      'recrutment_diffusionMethod_email',
      'recrutment_diffusionMethod_social_medias',
      'recrutment_diffusionMethod_mail',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderDiffusionMethods = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_participation', 
      'recrutment_diffusion_method',
      'recrutment_diffusionMethod_other',
      'recrutment_diffusionMethod_person',
      'recrutment_diffusionMethod_phone',
      'recrutment_diffusionMethod_email',
      'recrutment_diffusionMethod_social_medias',
      'recrutment_diffusionMethod_mail'
    ]);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12 ">
            <p>{t (`${sectionName}.recrutment_diffusion_method.title`)}</p>
          </div>

          {this.RenderDiffusionMethodsOptions ()}

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="recrutment_diffusionMethod_other">
                {t (`${sectionName}.recrutment_diffusionMethod_other.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="recrutment_diffusionMethod_other"
                id="recrutment_diffusionMethod_other"
                value={answers.recrutment_diffusionMethod_other}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderEthicControlQuestion ()}
        {answers.human_subjects_participation === 'YES' &&
          <Fragment>
            {this.RenderRecrutedPeople ()}
            {this.RenderAgeGroups ()}
            {this.RenderRecrutmentProcess ()}
            {this.RenderExclusions ()}
            {this.RenderMessage ()}
            {this.RenderDiffusionPeople ()}
            {this.RenderDiffusionMethods ()}
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {answers.human_subjects_participation === 'NO' &&
          <div>
            <h4>
              {t ('activeParticipationHuman.noParticipation')}
            </h4>
          </div>}
        {answers.human_subjects_participation === 'YES' &&
          this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (RecrutmentMethods)
);
