import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DiscussionNav from '../discussion/DiscussionNav';
import isEmpty from '../../validation/isEmpty';
import { withTranslation } from 'react-i18next';
import { GetTranslations, UpdateTranslations } from "../../store/actions/translationActions";
import classnames from 'classnames';

export class EditTranslations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textesOriginaux: [],
            translations: [],
            changes: {}
        }
    }

    //==========================
    //#region LifeCycle methods
    //=========================
    componentDidMount() {
        this.props.GetTranslations();
        //GET PAGE DATA


        // textesOriginaux.pages.fr = JSON.parse(JSON.stringify(pagesFrTemp));
        // textesOriginaux.pages.en = JSON.parse(JSON.stringify(pagesEnTemp));
        // textesOriginaux.form.fr = JSON.parse(JSON.stringify(formFrTemp));
        // textesOriginaux.form.en = JSON.parse(JSON.stringify(formEnTemp));
        // textesOriginaux.rules.fr = JSON.parse(JSON.stringify(rulesFrTemp));
        // textesOriginaux.rules.en = JSON.parse(JSON.stringify(rulesEnTemp));
        // textesOriginaux.ethic.fr = JSON.parse(JSON.stringify(ethicFrTemp));
        // textesOriginaux.ethic.en = JSON.parse(JSON.stringify(ethicEnTemp));
        // textesOriginaux.preQuestionnaire.fr = JSON.parse(JSON.stringify(preQuestionnaireFrTemp));
        // textesOriginaux.preQuestionnaire.en = JSON.parse(JSON.stringify(preQuestionnaireEnTemp));



        this.cancelButton = document.querySelector(".cancel-translation-btn");
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.translations!==this.props.translations && prevProps.translations.allTranslations !== this.props.translations.allTranslations) {
            const translations = this.props.translations.allTranslations.reduce((accumulator, currentValue, currentIndex, array) => {
                accumulator[currentValue.name] = this.FlattenObject(currentValue.translations);
                return accumulator;
            }, {})

            this.setState({ translations });
        }
    }
    //#endregion

    //==========================
    //#region Other methods
    //=========================

    //#endregion

    //==========================
    //#region Render methods
    //=========================
    FlattenObject = (obj, parent, res = {}) => {
        for (let key in obj) {
            let propName = parent ? parent + '#' + key : key;
            if (typeof obj[key] == 'object') {
                this.FlattenObject(obj[key], propName, res);
            } else {
                res[propName] = obj[key];
            }
        }
        return res;
    }

    SetField = event => {
        const { translations } = this.state;

        let input = event.currentTarget;
        let language = input.dataset.language;
        let filename = input.dataset.filename;
        let translationsKey = input.name;
        let value = input.value;

        translations[`${filename}${language}`][translationsKey] = value;
        this.setState(translations);
    }


    AddChanges = event => {
        let { changes } = this.state

        let input = event.currentTarget;
        let language = input.dataset.language;
        let filename = input.dataset.filename;
        let translationsKey = input.name;
        let value = input.value;

        const originalText = this.GetOriginalText(filename, language, translationsKey);

        if (!changes[`${filename}${language}`]) {
            changes[`${filename}${language}`] = [];
        }

        if (value !== originalText) {
            const change = changes[`${filename}${language}`].find(element => element.translationsKey === translationsKey);

            if (change) {
                change.value = value;
            } else {
                let data = {
                    filename: `${filename}${language}`,
                    translationsKey,
                    value
                }

                changes[`${filename}${language}`].push(data);
            }

            this.setState(changes);
        } else {
            const index = changes[`${filename}${language}`].findIndex(element => element.translationsKey === translationsKey);

            if (index !== -1) {
                changes[`${filename}${language}`].splice(index, 1);
                if (changes[`${filename}${language}`].length === 0) {
                    delete changes[`${filename}${language}`];
                }
                this.setState({ changes })
            }
        }


    }

    /**
     * Function to undo changes. It removes from state and get original texts back.
     * @param {*} event 
     */
    RemoveChanges = (event) => {
        let { changes, translations } = this.state;

        const parent = event.currentTarget.closest(".input-group")
        const input = parent.querySelector("input")

        let language = input.dataset.language;
        let filename = input.dataset.filename;
        let translationsKey = input.name;

        //Filter out element from changes
        changes[`${filename}${language}`] = changes[`${filename}${language}`].filter(element => {
            if (element.filename === `${filename}${language}` && element.translationsKey === translationsKey) return false;

            return true;
        })

        if (changes[`${filename}${language}`] && changes[`${filename}${language}`].length === 0) {
            delete changes[`${filename}${language}`];
        }

        const originalText = this.GetOriginalText(filename, language, translationsKey);
        translations[`${filename}${language}`][translationsKey] = originalText;

        this.setState({ changes, translations })
    }

    GetOriginalText = (filename, language, translationsKey) => {
        const { allTranslations } = this.props.translations;
        const fullFilename = `${filename}${language}`;
        let value;

        const translationFile = allTranslations.find((file) => {
            return file.name === fullFilename
        })

        if (translationFile !== null) {
            value = translationFile.translations[translationsKey]
        }

        return value;
    }

    SaveChanges = (event) => {
        const { changes } = this.state;

        if (Object.keys(changes).length > 0) {
            const data = { changes }
            this.props.UpdateTranslations(data)
            let resetChanges = {};
            this.setState({changes:resetChanges})
        }
    }

    RenderMenuSideBar = () => {
        return <DiscussionNav />;
    };

    RenderField = (translationFilename, translationsKey, translationValue, language) => {
        const { changes } = this.state;
        let modified = false;

        if (changes[`${translationFilename}${language}`]) {
            modified = changes[`${translationFilename}${language}`].some(element => element.translationsKey === translationsKey);
        }

        return <div className="input-group">
            <input type="text" name={translationsKey} className={classnames({ 'modified': modified })} value={translationValue} data-language={language} data-filename={translationFilename} onChange={this.SetField} onBlur={this.AddChanges} key={translationsKey + language} />
            <span className="cancel-translation-btn " onClick={this.RemoveChanges}>
                <i className="fas fa-times-circle"></i>
            </span>
        </div>
    }

    RenderRow = (translationFilename, translationKey, translationFr, translationEn) => {
        return <div className="translations-row" key={translationKey + "row"}>
            {this.RenderField(translationFilename, translationKey, translationFr, "fr")}
            {this.RenderField(translationFilename, translationKey, translationEn, "en")}
        </div>
    }

    RenderSection = (translationFilename, translationsSectionFr, translationsSectionEn) => {
        let liste = [];
        for (const translationKey in translationsSectionFr) {
            let element = this.RenderRow(translationFilename, translationKey, translationsSectionFr[translationKey], translationsSectionEn[translationKey])
            liste.push(element)
        }

        return <div className="translations-list">
            {liste}
        </div>

    }

    RenderMain = () => {
        const { changes } = this.state;
        const { ae_en, ae_fr, ar_fr, ar_en, pre_fr, pre_en, pages_fr, pages_en } = this.state.translations;

        return (
            <main className="discussion-main edit-translations">
                <button className="btn btn-main save-translations-btn" onClick={this.SaveChanges} disabled={Object.keys(changes).length === 0}>Enregistrer les traductions modifiées</button>

                <h1>Modifier les traductions globales pour les pages</h1>
                {this.RenderSection("pages_", pages_fr, pages_en)}
                <h1>Modifier les traductions des sections communes aux formulaires d'éthiques</h1>
                {this.RenderSection("ae_", ae_fr, ae_en)}
                <h1>Modifier les traductions des sections communes aux formulaires de règlements</h1>
                {this.RenderSection("ar_", ar_fr, ar_en)}
                <h1>Modifier les traductions des sections du pré-questionnaire</h1>
                {this.RenderSection("pre_", pre_fr, pre_en)}
            </main>
        );
    };

    render() {
        return (
            <div className="discussion-container">
                {this.RenderMenuSideBar()}
                {this.RenderMain()}

            </div>
        );
    }
    //#endregion
}

const mapStateToProps = state => ({
    user: state.user,
    auth: state.auth,
    translations: state.translations
});

const mapDispatchToProps = { GetTranslations, UpdateTranslations };

export default withTranslation(['pages'])(
    connect(mapStateToProps, mapDispatchToProps)(EditTranslations)
);
