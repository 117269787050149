import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import {DeleteForm} from '../../store/actions/formActions';

class DeleteWarningModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      conditions: '',
    };
  }

  componentDidMount () {
    this.setState ({conditions: this.props.conditions});
  }

  componentDidUpdate (prevProps, prevState) {}

  //**************** */

  OnHandleText = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  ChangeConditions = () => {
    this.props.DeleteForm (this.props._id, this.props.history);
    this.props.ClearModal ();
  };

  //******** VIEW SECTIONS
  ModalHeader = () => {
    return (
      <div className="modal-header">
        <h3 className="modal-title text-center" id="modalEntryLabel">
          Supprimer le formulaire en cours
        </h3>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeModalBtn"
          onClick={this.props.ClearModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };
  ModalBody = () => {
    return (
      <div className="modal-body" id="modalEntry-body">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4>
              <strong>
                <i className="fas fa-exclamation-triangle pr-3" />
                Attention, cette action est irréversible
              </strong>
            </h4>
            <h4>Voulez-vous supprimer votre demande en cours?</h4>
          </div>
        </div>
      </div>
    );
  };

  ModalFooter = () => {
    const isValid = true;

    return (
      <div className="modal-footer">
        <button
          type="button"
          className={classnames ('btn btn-primary', {
            'btn-disabled': !isValid,
          })}
          onClick={this.ChangeConditions}
        >
          Supprimer le formulaire
        </button>

        <button
          type="button"
          className="btn btn-invalid"
          data-dismiss="modal"
          onClick={this.props.ClearModal}
        >
          Annuler
        </button>
      </div>
    );
  };

  render () {
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalNewEntry"
          id="modalEntry-btn"
        />
        <div
          className="modal fade"
          id="modalNewEntry"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalEntryLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content ">
              {this.ModalHeader ()}
              <hr />
              {this.ModalBody ()}
              {this.ModalFooter ()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({});

export default connect (mapStateToProps, {
  DeleteForm,
}) (DeleteWarningModal);
