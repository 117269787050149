import axios from 'axios';

import {
  GET_SITE_STYLE,
  EDIT_SITE_STYLE,
  SET_MESSAGE,
  CREATE_SITE_STYLE,
  GET_ERRORS
} from './types';

const logger = require('tracer').colorConsole();

export const GetSiteStyle = () => dispatch => {
  axios
    .get('api/siteStyle/')
    .then(foundRequest => {
      dispatch({
        type: GET_SITE_STYLE,
        payload: foundRequest.data,
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const CreateSiteStyle = (siteStyle) => dispatch => {
  axios
    .post('api/siteStyle/create', siteStyle)
    .then(foundRequest => {
      dispatch({
        type: CREATE_SITE_STYLE,
        payload: foundRequest.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "Style create",
          msg: "Changements enregistrés"
        },
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const EditSiteStyle = (siteStyle) => dispatch => {
  axios
    .put('api/siteStyle/edit', siteStyle)
    .then(foundRequest => {
      dispatch({
        type: EDIT_SITE_STYLE,
        payload: foundRequest.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "Style edit",
          msg: "Changements enregistrés"
        },
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const EditHomeBackgroundImage = (file) => dispatch => {

  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);
  let attachment = null;

  return axios.post('/api/siteStyle/edit-background', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .catch(err => {
      console.log("err",err);
    });
};