import React, { Component } from 'react';
import { connect } from 'react-redux';
import DiscussionNav from '../discussion/DiscussionNav';
import isEmpty from '../../validation/isEmpty';
import { withTranslation } from 'react-i18next';
import { GetSiteStyle, EditSiteStyle, CreateSiteStyle, EditHomeBackgroundImage } from '../../store/actions/siteStyleActions';

export class EditHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siteStyle: {
                mainColor: "",
                secondaryColor: "",
                accentColor: "",
                darkColor: "",
                lightColor: "",
            },
            isDirty: false,
            file: '',
        };
    }

    //==========================
    //#region LifeCycle methods
    //=========================
    componentDidMount() {
        //GET PAGE DATA
        this.props.GetSiteStyle();

    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteStyle !== this.props.siteStyle) {
            const { siteStyle } = this.props;

            this.setState({ siteStyle })
            this.SetSiteStyle();
        }
    }
    //#endregion

    //==========================
    //#region Other methods
    //=========================
    SetSiteStyle() {
        if (!isEmpty(this.props.siteStyle)) {
            document.documentElement.style.setProperty('--data-color-main', this.props.siteStyle.mainColor);
            document.documentElement.style.setProperty('--data-color-secondary', this.props.siteStyle.secondaryColor);
            document.documentElement.style.setProperty('--data-color-accent', this.props.siteStyle.accentColor);
            document.documentElement.style.setProperty('--data-color-dark', this.props.siteStyle.darkColor);
            document.documentElement.style.setProperty('--data-color-light', this.props.siteStyle.lightColor);
        }
    }
    //#endregion

    HandleFile = e => {
        const { t } = this.props;
        const file = e.currentTarget.files[0];
        if (e.currentTarget.files[0].size > 10000000) {
            console.log(e.currentTarget.files[0].size);

            this.props.SetMessage(
                'FILE_TOO_LARGE',
                t('pages:attachment.file_too_large')
            );
            return;
        }
        this.setState({ file });
        this.props.EditHomeBackgroundImage(
            file
        );
    };

    //==========================
    //#region Render methods
    //=========================

    SetColor = event => {
        const { siteStyle } = this.state;
        const input = event.currentTarget;

        siteStyle[input.name] = input.value;
        this.setState({ siteStyle: { ...siteStyle }, isDirty: true });

    };

    SaveChanges = event => {
        const { siteStyle } = this.state;
        if (this.props.siteStyle._id === undefined) {
            this.props.CreateSiteStyle(siteStyle);
        } else {
            this.props.EditSiteStyle(siteStyle);
        }
    }

    RenderMenuSideBar = () => {
        return <DiscussionNav />;
    };


    RenderMain = () => {
        const { mainColor, secondaryColor, accentColor, darkColor, lightColor } = this.state.siteStyle;

        return (
            <main className="discussion-main edit-home">
                <h1>Modifier les éléments visuels du site</h1>
                {/* Choix couleur */}
                <div>
                    <p>Couleur principale</p>
                    <input type="color"
                        name="mainColor"
                        id="mainColor"
                        value={mainColor}
                        onChange={this.SetColor} />
                </div>
                <div>
                    <p>Couleur secondaire</p>
                    <input type="color"
                        name="secondaryColor"
                        id="secondaryColor"
                        value={secondaryColor}
                        onChange={this.SetColor} />
                </div>
                <div>
                    <p>Couleur accent</p>
                    <input type="color"
                        name="accentColor"
                        id="accentColor"
                        value={accentColor}
                        onChange={this.SetColor} />
                </div>
                <div>
                    <p>Couleur foncée</p>
                    <input type="color"
                        name="darkColor"
                        id="darkColor"
                        value={darkColor}
                        onChange={this.SetColor} />
                </div>
                <div>
                    <p>Couleur pâle</p>
                    <input type="color"
                        name="lightColor"
                        id="lightColor"
                        value={lightColor}
                        onChange={this.SetColor} />
                </div>
                <h1>Modifier les éléments visuels de la page d'accueil</h1>
                {/*  Choix fond */}
                <div className="edit-home-bg">
                    <p>Image de fond de la page d'accueil</p>
                    <img src="images/bg.jpg" alt="" />
                </div>
                <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={this.HandleFile}
                    accept="image/jpeg"
                />

                {/*  Choix élément 1 */}
                <div className={`save-button button ${!this.state.isDirty ? "disabled" : ""}`} onClick={this.SaveChanges}>
                    Sauvegarder
                </div>
                <div className="cancel-button button">
                    Annuler
                </div>

            </main>
        );
    };

    render() {
        return (
            <div className="discussion-container">
                {this.RenderMenuSideBar()}
                {this.RenderMain()}
            </div>
        );
    }
    //#endregion
}

const mapStateToProps = state => ({
    user: state.user,
    auth: state.auth,
    siteStyle: state.siteStyle
});

const mapDispatchToProps = { GetSiteStyle, EditSiteStyle, CreateSiteStyle, EditHomeBackgroundImage };

export default withTranslation(['pages'])(
    connect(mapStateToProps, mapDispatchToProps)(EditHome)
);
