import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import questions from './filterQuestions';
import isEmpty from '../../validation/isEmpty';
import i18next from 'i18next';
import {withTranslation} from 'react-i18next';

export class FilterQuestionnaire extends Component {
  constructor (props) {
    super (props);

    this.state = {
      level: '',
      formAE: false,
      formAR: false,
      formTypes: {
        formAE: {
          url: '/form/ae',
          name: 'A-E',
          committee: "Comité de l'Éthique",
        },
        formAR: {
          url: '/form/ar',
          name: 'A-R',
          committee: 'Comité des règlements',
        },
        formD: {
          url: '/form/d',
          name: 'D',
          committee: 'Comité des règlements',
        },
      },
      questionIndex: 0,
      question: '',
    };
  }

  componentDidMount () {
    const {lang} = this.props.auth;
    i18next.changeLanguage (lang);
    this.forceUpdate ();
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.auth !== this.props.auth) {
      i18next.changeLanguage (this.props.auth.lang);
      this.forceUpdate ();
    }
  }

  OnCheck = e => {
    const state = this.state;
    state[e.currentTarget.name] = e.currentTarget.value;
    this.setState (state);
  };

  RenderHeader = () => {
    const {t} = this.props;
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1>{t ('preq_title')}</h1>
          <h5>
            <i className="fas fa-2x fa-hand-point-right pr-2" />
            {t ('preq_subtitle')}
          </h5>
        </div>
      </div>
    );
  };

  RenderNoFormNeeded = () => {
    const {t} = this.props;

    return (
      <div className="row mt-5 text-center py-3">
        <div className="col-md-10 offset-md-1 text-success">
          <h2>
            <i className="fas fa-exclamation-circle pr-3" />
            {t ('preq_noform_title')}
          </h2>
          <h5>
            {t ('preq_noform_text')}
          </h5>
          <button className="btn btn-primary mt-4 p-2">
            <Link to="/"><h4>{t ('nav_back_home')}</h4></Link>
          </button>
        </div>
      </div>
    );
  };

  RenderNoHumanExpElementary = () => {
    const {t} = this.props;

    return (
      <div className="row mt-5 text-center py-3">
        <div className="col-md-10 offset-md-1 text-danger">
          <h2>
            <i className="fas fa-exclamation-triangle pr-3" />
            {t ('preq_refused_title')}
          </h2>
          <h5>
            {t ('preq_refused_text')}
          </h5>
          <button className="btn btn-primary mt-4 p-2">
            <Link to="/"><h4>{t ('nav_back_home')}</h4></Link>
          </button>
        </div>
      </div>
    );
  };

  RenderFormLink = formType => {
    const {t} = this.props;

    const {formTypes} = this.state;
    const infos = formTypes[formType];
    return (
      <div className="row mt-5 text-center py-3">
        <div className="col-md-10 offset-md-1 text-primary">
          <h2>
            <i className="fas fa-exclamation-triangle pr-3" />
            {`${t ('preq_needform_title')} ${infos.name}`}
          </h2>
          <h5>
            {`${t ('preq_needform_require')} ${infos.committee}. ${t ('preq_needform_clicklink')}`}
          </h5>

        </div>
        <div className="col-md-10 offset-md-1 row my-3">
          <button className=" col-md-5 btn btn-primary mt-4 p-3 mx-auto">
            <Link to={infos.url}>
              <h3>{`${t ('preq_needform_link')} ${infos.name}`}</h3>
            </Link>
          </button>
        </div>
      </div>
    );
  };

  RenderLevelSelect = () => {
    const {t} = this.props;

    const {level} = this.state;
    if (!isEmpty (level)) {
      return;
    }
    return (
      <div className="row mt-5 text-center py-4">
        <div className="col-md-10 offset-md-1">
          <h2>
            {t ('preq_level')}
          </h2>
        </div>
        <div className="form-check col-md-5 offset-md-1">
          <button
            className="btn btn-main btn-block"
            onClick={() => {
              this.setState ({level: 'elementary'});
            }}
          >
            <h3>{t ('preq_level_elem')}</h3>
          </button>
        </div>
        <div className="form-check col-md-5">
          <button
            className="btn btn-accent btn-block"
            onClick={() => {
              this.setState ({level: 'secondary'});
            }}
          >
            <h3>{t ('preq_level_sec')}</h3>
          </button>
        </div>
      </div>
    );
  };

  TriggerFormRequest = form => {
    const state = this.state;
    state[form] = true;
    this.setState (state);
  };

  FetchNextQuestion = () => {
    const {t} = this.props;

    const {questionIndex, formAE, formAR, level} = this.state;
    if (isEmpty (level) || formAE || formAR) {
      return;
    }
    if (questionIndex >= questions.length) {
      if (!formAE && !formAR) {
        return this.RenderNoFormNeeded ();
      } else {
        return;
      }
    }

    const question = questions[questionIndex];
    if (isEmpty (question)) return;

    return (
      <div className="row mt-5 text-center">
        <div className="col-md-10 offset-md-1 py-3 ">
          <h2 className="text-uppercase">
            {t ('preq_project_need')}
          </h2>
        </div>
        <div className="col-md-10 offset-md-1">
          <h3>
            {t (`preq_${question.id}`)}
          </h3>
        </div>

        <div className="form-check col-md-3 offset-md-3">
          <button
            className="btn btn-main btn-block"
            onClick={() => {
              this.TriggerFormRequest (question.trigger);
            }}
          >
            <h3>{t ('yes').toUpperCase ()}</h3>
          </button>
        </div>
        <div className="form-check col-md-3">
          <button
            className="btn btn-accent btn-block"
            onClick={() => {
              const newIndex = questionIndex + 1;
              this.setState ({questionIndex: newIndex});
            }}
          >
            <h3>{t ('no').toUpperCase ()}</h3>
          </button>
        </div>
      </div>
    );
  };

  RenderDecoBloc = () => {
    return (
      <div className="decobloc-container d-none d-lg-block">
        <div className="decobloc" />
      </div>
    );
  };

  render () {
    const {formAE, formAR, level} = this.state;
    let blockToRender = this.RenderLevelSelect ();

    if (level === 'elementary') {
      if (formAE) {
        blockToRender = this.RenderNoHumanExpElementary ();
      } else if (formAR) {
        blockToRender = this.RenderFormLink ('formD');
      }
    } else if (level === 'secondary') {
      if (formAE) {
        blockToRender = this.RenderFormLink ('formAE');
      } else if (formAR) {
        blockToRender = this.RenderFormLink ('formAR');
      }
    }
    return (
      <div className="pre-questionnaire container">
        {this.RenderDecoBloc ()}

        {this.RenderHeader ()}
        {blockToRender}
        {this.FetchNextQuestion ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default withTranslation ('prequestionnaire') (
  connect (mapStateToProps, mapDispatchToProps) (FilterQuestionnaire)
);
