import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {getAllRegions} from '../../store/actions/regionActions';
import {registerUser} from '../../store/actions/authActions';

export class RegisterUser extends Component {
  constructor (props) {
    super (props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      role: 'PARTICIPANT',
      regions: [],
      password: '',
      password2: '',
    };
  }

  // ========================
  // #region LifeCycle Methods
  // =======================
  componentDidMount () {
    this.props.getAllRegions ();
  }
  //#endregion

  // ========================
  // #region Class Methods
  // =======================
  SaveUser = () => {
    this.props.registerUser (this.state, this.props.history);
  };
  // #region

  // ========================
  // #region Event Methods
  // =======================
  HandleChange = e => {
    const state = this.state;
    state[e.currentTarget.name] = e.currentTarget.value;
    this.setState (state);
  };

  HandleRoleSelect = e => {
    const state = this.state;
    state[e.currentTarget.name] = e.currentTarget.value;
    this.setState (state);
  };

  HandleRegionSelect = e => {
    const {regions} = this.state;
    let updatedRegions = [];
    if (e.currentTarget.checked) {
      updatedRegions = this.AddRegion (e.currentTarget.name, regions);
    } else {
      updatedRegions = this.RemoveRegion (e.currentTarget.name, regions);
    }
    this.setState ({regions: updatedRegions});
  };

  AddRegion = (id, regions) => {
    const {regionsList} = this.props.region;
    const regionToAdd = regionsList.find (region => {
      return region._id === id;
    });
    if (regionToAdd !== null) {
      regions.push (regionToAdd);
    }
    return regions;
  };
  RemoveRegion = (id, regions) => {
    return regions.filter (region => {
      return region._id !== id;
    });
  };

  // #endregion

  // ========================
  // #region Render Methods
  // =======================
  RenderHeader = () => {
    const {t} = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <h1>
            {t ('register.new_account')}
          </h1>
        </div>
      </div>
    );
  };
  RenderRegionCheckBoxes = () => {
    const {regionsList} = this.props.region;
    return regionsList
      .filter (region => {
        return region.name !== 'Réseau Technoscience';
      })
      .map (region => {
        return (
          <div key={region._id} className="form-check col-md-6 pl-5">
            <input
              className="form-check-input"
              type="checkbox"
              id={region._id}
              name={region._id}
              onChange={this.HandleRegionSelect}
            />
            <label className="form-check-label" htmlFor={region._id}>
              {region.name}
            </label>
          </div>
        );
      });
  };

  RenderAddUserForm = () => {
    const {t} = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <h5>{t ('register.personnal_infos')}</h5>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="firstName">{t ('register.first_name')}</label>
          <input
            type="text"
            className="form-control"
            name="firstName"
            id="firstName"
            onChange={this.HandleChange}
            value={this.state.firstName}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="lastName">{t ('register.last_name')}</label>
          <input
            type="text"
            className="form-control"
            name="lastName"
            id="lastName"
            onChange={this.HandleChange}
            value={this.state.lastName}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="email">{t ('register.email')}</label>
          <input
            type="text"
            className="form-control"
            name="email"
            id="email"
            onChange={this.HandleChange}
            value={this.state.email}
          />
        </div>
        <div className="col-md-6" />
        <div className="form-group col-md-6">
          <label htmlFor="lastName">{t ('register.password')}</label>
          <input
            type="password"
            className="form-control"
            name="password"
            id="password"
            onChange={this.HandleChange}
            value={this.state.password}
          />
          <small>
            {t ('register.password_warning')}
          </small>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="password2">
            {t ('register.password_confirm')}
          </label>
          <input
            type="password"
            className="form-control"
            name="password2"
            id="password2"
            onChange={this.HandleChange}
            value={this.state.password2}
          />
        </div>

        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-12">
          <h5>
            {t ('register.region_check')}
          </h5>
        </div>
        <div className="form-group col-md-12 row">
          {this.RenderRegionCheckBoxes ()}
        </div>
      </div>
    );
  };

  RenderButtons = () => {
    const {t} = this.props;

    return (
      <div className="row py-5">
        <div className="col-md-4 offset-md-2 text-center">
          <button className="btn btn-primary p-3" onClick={this.SaveUser}>
            <h4>{t ('register.save')}</h4>
          </button>
        </div>
        <div className="col-md-4  text-center">
          <button className="btn btn-secondary p-3">
            <Link to="/connexion">
              <h4>{t ('register.cancel')}</h4>
            </Link>
          </button>
        </div>
      </div>
    );
  };

  render () {
    return (
      <div className="container">
        {this.RenderHeader ()}
        {this.RenderAddUserForm ()}
        {this.RenderButtons ()}
      </div>
    );
  }
  // #endregion
}

const mapStateToProps = state => ({
  region: state.region,
});

const mapDispatchToProps = {
  getAllRegions,
  registerUser,
};

export default withTranslation ('pages') (
  connect (mapStateToProps, mapDispatchToProps) (RegisterUser)
);
