import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {ClearErrors} from '../../store/actions/errorActions';

import isEmpty from '../../validation/isEmpty';

export class ErrorBox extends Component {
  constructor (props) {
    super (props);
    this.state = {
      cleared: false,
    };
  }
  componentDidUpdate (prevProps, prevState) {
    if (this.props.error !== prevProps.error) {
      if (!isEmpty (this.props.error) && !this.state.cleared) {
        this.setState ({cleared: true});

        window.setTimeout (() => {
          this.setState ({cleared: false});
          this.props.ClearErrors ();
        }, 2500);
      }
    }
  }

  RenderMessage = () => {
    if (Object.keys (this.props.error).length >= 1) {
      return Object.keys (this.props.error).map ((key, index) => {
        return (
          <p key={index}>
            <i className="fas fa-exclamation-circle pr-3" />
            {this.props.error[key].msg}
          </p>
        );
      });
    } else {
      return <p>{this.props.error.msg}</p>;
    }
  };
  render () {
    const isError = !isEmpty (this.props.error);
    return (
      <Fragment>
        {isError &&
          <div className="messageBox" onClick={this.props.ClearErrors}>
            <div className="alert alert-danger fade-in-out box" role="alert">
              <div>
                {this.RenderMessage ()}
              </div>
            </div>
          </div>}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  error: state.error,
});

const mapDispatchToProps = {ClearErrors};

export default connect (mapStateToProps, mapDispatchToProps) (ErrorBox);
