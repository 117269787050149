import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Nav from '../navigation/Nav';

const RegularClientRoute = ({component: Component, client, ...rest}) => {
  const props = {...rest};
  const isHome = props.path === '/';

  return (
    <Fragment>
      {!isHome ? <Nav /> : null}
      <Route {...rest} render={props => <Component {...props} />} />
    </Fragment>
  );
};

export default connect (null) (RegularClientRoute);
