import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';
import {ValidateEmail} from '../../../../validation/fieldValidation';

export class Supervisor extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'supervisor',
      isValid: false,

      answers: {
        supervisor_firstName: '',
        supervisor_lastName: '',
        supervisor_phone: '',
        supervisor_email: '',

        supervisor_type: '',
        supervisor_type_other: '',
        supervisor_qualifications: '',

        supervisor_support_supervision: false,
        supervisor_support_lab_access: false,
        supervisor_support_manipulation_support: false,
        supervisor_support_project_dev: false,
        supervisor_support_project_revison: false,
        supervisor_support_other: '',
      },
    };
  }
  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (isEmpty (answers.supervisor_firstName)) isValid = false;
    if (isEmpty (answers.supervisor_lastName)) isValid = false;
    if (isEmpty (answers.supervisor_phone)) isValid = false;
    if (isEmpty (answers.supervisor_email)) isValid = false;
    if (!ValidateEmail (answers.supervisor_email)) isValid = false;

    if (isEmpty (answers.supervisor_type)) isValid = false;
    if (
      answers.supervisor_type === 'OTHER' &&
      isEmpty (answers.supervisor_type_other)
    )
      isValid = false;
    if (isEmpty (answers.supervisor_qualifications)) isValid = false;

    if (
      answers.supervisor_support_supervision === false &&
      answers.supervisor_support_lab_access === false &&
      answers.supervisor_support_manipulation_support === false &&
      answers.supervisor_support_project_dev === false &&
      answers.supervisor_support_project_revison === false &&
      isEmpty (answers.supervisor_support_other)
    )
      isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
    //this.nameInput.focus ();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    answers['supervisor_type_other'] = '';
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }

    return false;
  }

  RenderSupervisorName = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion_name =  this.MustRenderQuestion('supervisor_firstName') || this.MustRenderQuestion('supervisor_lastName');

    if (mustRenderQuestion_name)
    {
      return (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="supervisor_firstName">
                {t (`${sectionName}.supervisor_firstName.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="supervisor_firstName"
                id="supervisor_firstName"
                value={answers.supervisor_firstName}
                onChange={this.OnHandleText}
                maxLength="100"
                ref={input => {
                  this.nameInput = input;
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="supervisor_lastName">
                {t (`${sectionName}.supervisor_lastName.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="supervisor_lastName"
                id="supervisor_lastName"
                value={answers.supervisor_lastName}
                onChange={this.OnHandleText}
                maxLength="100"
              />
            </div>
          </div>
        </div>
      );
    }
    
  };

  RenderSupervisorInfos = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion_phone =  this.MustRenderQuestion('supervisor_phone');
    let mustRenderQuestion_email =  this.MustRenderQuestion('supervisor_email');

    return (
      <div className="row">

        {mustRenderQuestion_phone &&

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="supervisor_phone">
                {t (`${sectionName}.supervisor_phone.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="supervisor_phone"
                id="supervisor_phone"
                value={answers.supervisor_phone}
                onChange={this.OnHandleText}
                maxLength="100"
              />
            </div>
          </div>

        }

      {mustRenderQuestion_email &&
        
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="supervisor_email">
              {t (`${sectionName}.supervisor_email.title`)}
            </label>
            <input
              type="email"
              className="form-control"
              name="supervisor_email"
              id="supervisor_email"
              value={answers.supervisor_email}
              onChange={this.OnHandleText}
              maxLength="100"
            />
          </div>
        </div>
      }

      </div>
    );
  };

  RenderSupervisorTypeOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'SCIENCE_TEACHER',
      'SCIENCE_TECH',
      'HEALTH_SPECIALIST',
      'UNIVERSITY_TEACHER',
      'SEARCHER',
      'LAB_PROFESSIONNAL',
      'OTHER',
    ];
    const options = [];
    let index = 0;
    for (let type of types) {
      options.push (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            type="radio"
            name="supervisor_type"
            id={type}
            value={type}
            onChange={this.OnHandleRadio}
            checked={answers.supervisor_type === type ? 'checked' : ''}
          />
          <label className="form-check-label" htmlFor={type}>
            {t (`${sectionName}.supervisor_type.options.${type}`)}
          </label>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderSupervisorType = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('supervisor_type');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.supervisor_type.title`)}</p>
            {this.RenderSupervisorTypeOptions ()}
          </div>
          {answers.supervisor_type === 'OTHER' &&
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="supervisor_type_other">
                  {t (`${sectionName}.supervisor_type_other.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="supervisor_type_other"
                  id="supervisor_type_other"
                  value={answers.supervisor_type_other}
                  onChange={this.OnHandleText}
                  maxLength="100"
                />
              </div>
            </div>}
        </div>
      );
    }
    
  };

  RenderSupervisorQualifications = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('supervisor_qualifications');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="supervisor_qualifications">
                {t (`${sectionName}.supervisor_qualifications.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="supervisor_qualifications"
                id="supervisor_qualifications"
                value={answers.supervisor_qualifications}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
    
  };

  RenderSupervisorSupportOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'supervisor_support_supervision',
      'supervisor_support_lab_access',
      'supervisor_support_manipulation_support',
      'supervisor_support_project_dev',
      'supervisor_support_project_revison',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };
  RenderSupervisorSupport = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    const options = [
      'supervisor_support_supervision',
      'supervisor_support_lab_access',
      'supervisor_support_manipulation_support',
      'supervisor_support_project_dev',
      'supervisor_support_project_revison',
    ];

    let mustRenderQuestion =  this.MustRenderQuestion(options);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.supervisor_support.title`)}</p>
          </div>
  
          {this.RenderSupervisorSupportOptions ()}
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="supervisor_support_other">
                {t (`${sectionName}.supervisor_support_other.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="supervisor_support_other"
                id="supervisor_support_other"
                value={answers.supervisor_support_other}
                onChange={this.OnHandleText}
                maxLength="100"
              />
            </div>
          </div>
        </div>
      );
    }
   
  };

  RenderQuestions = () => {
    return (
      <Fragment>
        {this.RenderSupervisorName ()}
        {this.RenderSupervisorInfos ()}
        <hr />
        {this.RenderSupervisorType ()}
        <hr />
        {this.RenderSupervisorQualifications ()}
        <hr />
        {this.RenderSupervisorSupport ()}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (Supervisor)
);
