import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';
import {getAllRegions} from '../../../../store/actions/regionActions';

export class ActiveParticipationHuman extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'activeParticipationHuman',
      isValid: false,
      isJuvenile: false,

      answers: {
        human_subjects_participation: 'NO',

        human_subjects_proc_archive: false,
        human_subjects_proc_observation: false,
        human_subjects_proc_survey: false,
        human_subjects_proc_individual_interview: false,
        human_subjects_proc_group_interview: false,
        human_subjects_proc_test: false,
        human_subjects_proc_conception: false,
        human_subjects_proc_other: '',

        human_subjects_tasks: '',
        human_subjects_duration: '',
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (
      answers.human_subjects_participation === 'YES' &&
      (answers.human_subjects_proc_archive === false &&
        answers.human_subjects_proc_observation === false &&
        answers.human_subjects_proc_survey === false &&
        answers.human_subjects_proc_individual_interview === false &&
        answers.human_subjects_proc_group_interview === false &&
        answers.human_subjects_proc_test === false &&
        answers.human_subjects_proc_conception === false &&
        isEmpty (answers.human_subjects_proc_other))
    ) {
      isValid = false;
    }

    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.human_subjects_tasks)
    )
      isValid = false;
    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.human_subjects_duration)
    )
      isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName, isJuvenile} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;

      let elementary_levels = [
        'ELEMENTARY1',
        'ELEMENTARY2',
        'ELEMENTARY3',
        'ELEMENTARY4',
        'ELEMENTARY5',
        'ELEMENTARY6'
      ];
  
      const isJuvenile = elementary_levels.includes(FormAnswers.participantInformations.level);

      if (isJuvenile) {
        const defaultAnswers = {
          human_subjects_participation: 'NO',

          human_subjects_proc_archive: false,
          human_subjects_proc_observation: false,
          human_subjects_proc_survey: false,
          human_subjects_proc_individual_interview: false,
          human_subjects_proc_group_interview: false,
          human_subjects_proc_test: false,
          human_subjects_proc_conception: false,
          human_subjects_proc_other: '',

          human_subjects_tasks: '',
          human_subjects_duration: '',
        };
        this.setState ({isJuvenile, answers: defaultAnswers}, () => {
          this.props.SetAnswers (this.state);
        });
      } else {
        this.setState ({isJuvenile, answers: FormAnswers[sectionName]}, () => {
          this.Validate ();
        });
      }
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers['human_subjects_proc_archive'] = false;
    answers['human_subjects_proc_observation'] = false;
    answers['human_subjects_proc_survey'] = false;
    answers['human_subjects_proc_individual_interview'] = false;
    answers['human_subjects_proc_group_interview'] = false;
    answers['human_subjects_proc_test'] = false;
    answers['human_subjects_proc_conception'] = false;
    answers['human_subjects_proc_other'] = '';
    answers['human_subjects_proc_other'] = '';
    answers['human_subjects_tasks'] = '';
    answers['human_subjects_duration'] = '';

    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }
    return false;
  }


  RenderJuvenileWarning = () => {
    const {t} = this.props;
    const {level} = this.props.FormAnswers.participantInformations;

    let elementary_levels = [
      'ELEMENTARY1',
      'ELEMENTARY2',
      'ELEMENTARY3',
      'ELEMENTARY4',
      'ELEMENTARY5',
      'ELEMENTARY6'
    ];

    if ( ! elementary_levels.includes(level)) return;
    return (
      <div className="row">
        <div className="col-md-12">
          <h3>{t ('activeParticipationHuman.sectionJuvenileWarning')}</h3>
          <p>
            {t ('activeParticipationHuman.sectionJuvenileWarningText')}
          </p>
        </div>
      </div>
    );
  };

  RenderEthicControlQuestion = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('human_subjects_participation');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.human_subjects_participation.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'YES' ? 'checked' : ''
                }
                ref={input => {
                  this.nameInput = input;
                }}
              />
              <label className="form-check-label" htmlFor="yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="NO"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'NO' ? 'checked' : ''
                }
              />
              <label className="form-check-label" htmlFor="NO">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderExperimentationProceduresOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'human_subjects_proc_archive',
      'human_subjects_proc_observation',
      'human_subjects_proc_survey',
      'human_subjects_proc_individual_interview',
      'human_subjects_proc_group_interview',
      'human_subjects_proc_test',
      'human_subjects_proc_conception',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderExperimentationProcedures = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_procedures', 
      'human_subjects_proc_other',
      'human_subjects_proc_archive',
      'human_subjects_proc_observation',
      'human_subjects_proc_survey',
      'human_subjects_proc_individual_interview',
      'human_subjects_proc_group_interview',
      'human_subjects_proc_test',
      'human_subjects_proc_conception'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.human_subjects_procedures.title`)}</p>
          </div>

          {this.RenderExperimentationProceduresOptions ()}

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="human_subjects_proc_other">
                {t (`${sectionName}.human_subjects_proc_other.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="human_subjects_proc_other"
                id="human_subjects_proc_other"
                value={answers.human_subjects_proc_other}
                onChange={this.OnHandleText}
                maxLength="100"
              />
            </div>
          </div>
        </div>
      );

    }
  };

  RenderProceduresTasks = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_procedures', 
      'human_subjects_tasks'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="human_subjects_tasks">
                {t (`${sectionName}.human_subjects_tasks.title`)}
              </label>
              <p className="important">
                {t (`${sectionName}.human_subjects_tasks.text`)}
              </p>
              <textarea
                type="text"
                className="form-control"
                name="human_subjects_tasks"
                id="human_subjects_tasks"
                value={answers.human_subjects_tasks}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderProceduresDuration = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_procedures', 
      'human_subjects_duration'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="human_subjects_duration">
                {t (`${sectionName}.human_subjects_duration.title`)}
              </label>
              <p className="important">
                {t (`${sectionName}.human_subjects_duration.text`)}
              </p>
              <textarea
                type="text"
                className="form-control"
                name="human_subjects_duration"
                id="human_subjects_duration"
                value={answers.human_subjects_duration}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderEthicControlQuestion ()}
        {answers.human_subjects_participation === 'YES' &&
          <Fragment>
            <hr />
            {this.RenderExperimentationProcedures ()}
            <hr />
            {this.RenderProceduresTasks ()}
            <hr />
            {this.RenderProceduresDuration ()}
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName, isJuvenile} = this.state;

    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {isJuvenile && this.RenderJuvenileWarning ()}
        {!isJuvenile && this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {getAllRegions};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (ActiveParticipationHuman)
);
