import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { adminRegisterUser } from '../../store/actions/authActions';
import { getAllRegions } from "../../store/actions/regionActions";

import { withTranslation } from 'react-i18next';
class AddUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      role: 'COORDINATOR',
      committee: 'NONE',
      password: '',
      regions: []
    };
  }

  componentDidMount() {
    this.props.getAllRegions();
  }

  //**************** */
  OnHandleRegionField = e => {
      const {regions} = this.state;
      const {checked,name} = e.currentTarget;
      if(checked && !regions.includes(name)){
          regions.push(name)
      }else{
        regions.splice(regions.indexOf(name), 1)
      }
      this.setState({regions})
  }
  OnHandleText = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value });
  };

  ActionFct = () => {
    this.props.adminRegisterUser(this.state);
    this.props.ClearModal();
  };

  RenderRegionCheckBoxes = () => {
    const { regionsList } = this.props.region
    return regionsList.map(
      (region) => {
        const { _id, name } = region;
        return <div className="col-md-12" key={_id}>
          <input type="checkbox" name={_id} id={_id} onChange={this.OnHandleRegionField} />
          <label htmlFor={_id}>
            {name}
          </label>
        </div>
      }
    )

  }

  //******** VIEW SECTIONS
  ModalHeader = () => {
    const { t } = this.props;
    return (
      <div className="modal-header">
        <h3 className="modal-title text-center" id="modalEntryLabel">
          {t('users_list.add_user')}
        </h3>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeModalBtn"
          onClick={this.props.ClearModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  Body = () => {
    const { t } = this.props;

    const { firstName, lastName, email, role, committee, password, region } = this.state;


    return (
      <div className="row">
        <div className="form-group col-md-6">
          <label htmlFor="firstName">{t('users_list.firstname')}</label>
          <input
            type="text"
            className="form-control"
            name="firstName"
            id="firstName"
            onChange={this.OnHandleText}
            value={firstName}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="lastName">{t('users_list.lastname')}</label>
          <input
            type="text"
            className="form-control"
            name="lastName"
            id="lastName"
            onChange={this.OnHandleText}
            value={lastName}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="email">{t('users_list.email')}</label>
          <input
            type="text"
            className="form-control"
            name="email"
            id="email"
            onChange={this.OnHandleText}
            value={email}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="email">{t('users_list.temp_pwd')}</label>
          <input
            type="password"
            className="form-control"
            name="password"
            id="password"
            onChange={this.OnHandleText}
            value={password}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="role">{t('users_list.role')}</label>
          <select
            className="custom-select"
            name="role"
            id="role"
            onChange={this.OnHandleText}
            value={role}
          >
            <option value="ADMIN">{t('users_list.admin')}</option>
            <option value="COORDINATOR">
              {t('users_list.committee_member')}
            </option>
            <option value="READONLY">{t('users_list.guest')}</option>
          </select>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="committee">{t('users_list.committees')}</label>
          <select
            className="custom-select"
            name="committee"
            id="committee"
            onChange={this.OnHandleText}
            value={committee}
          >
            <option value="ETHICS">{t('users_list.ethic_committee')}</option>
            <option value="RULES">{t('users_list.rules_committee')}</option>
            <option value="BOTH">{t('users_list.both_committees')}</option>
            <option value="NONE">{t('users_list.none_committee')}</option>
          </select>
        </div>
        <div className="form-group col-md-12">
          {this.RenderRegionCheckBoxes()}
        </div>
      </div>
    );
  };
  ModalBody = () => {
    return (
      <div className="modal-body" id="modalEntry-body">
        <div className="row">
          <div className="col-md-12">
            {this.Body()}
          </div>
        </div>
      </div>
    );
  };

  ModalFooter = () => {
    const { t } = this.props;
    return (
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.ActionFct}
        >
          {t('users_list.update')}
        </button>

        <button
          type="button"
          className="btn btn-invalid"
          data-dismiss="modal"
          onClick={this.props.ClearModal}
        >
          {t('users_list.cancel')}
        </button>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalNewEntry"
          id="modalEntry-btn"
        />
        <div
          className="modal fade"
          id="modalNewEntry"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalEntryLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content ">
              {this.ModalHeader()}
              <hr />
              {this.ModalBody()}
              {this.ModalFooter()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  programs: state.programs,
  entry: state.entry,
  employees: state.employees,
  region: state.region
});

export default withTranslation(['pages'])(
  connect(mapStateToProps, { adminRegisterUser, getAllRegions })(AddUserModal)
);
