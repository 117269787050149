import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class HumanBioMaterial extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'humanBioMaterial',
      isValid: false,

      answers: {
        human_bio_material: 'NO',
        human_bio_material_description: '',

        human_bio_material_laboratory: false,
        human_bio_material_biobank: false,
        human_bio_material_sample_science_fairs: false,
        human_bio_material_sample_other: '',

        project_hypothesis: '',
        project_methodology: '',
        project_radioactive: 'NO',
        project_material_at_risk: 'NO',
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (
      answers.human_bio_material === 'YES' &&
      isEmpty (answers.human_bio_material_description)
    )
      isValid = false;
    if (
      answers.human_bio_material === 'YES' &&
      isEmpty (answers.project_radioactive)
    )
      isValid = false;
    if (
      answers.human_bio_material === 'YES' &&
      isEmpty (answers.project_material_at_risk)
    )
      isValid = false;

    if (
      answers.human_bio_material === 'YES' &&
      (answers.human_bio_material_laboratory === false &&
        answers.human_bio_material_biobank === false &&
        answers.human_bio_material_sample_science_fairs === false &&
        isEmpty (answers.human_bio_material_sample_other))
    )
      isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleControlQuestion = e => {
    const {answers} = this.state;
    answers['human_bio_material_description'] = '';

    answers['human_bio_material_laboratory'] = false;
    answers['human_bio_material_biobank'] = false;
    answers['human_bio_material_sample_science_fairs'] = false;
    answers['human_bio_material_sample_other'] = '';

    answers['project_hypothesis'] = '';
    answers['project_methodology'] = '';
    answers['project_radioactive'] = 'NO';
    answers['project_material_at_risk'] = 'NO';

    answers[e.currentTarget.name] = e.currentTarget.value;

    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }
    return false;
  }

  RenderBioMaterialControlQuestion = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('human_bio_material');

    if (mustRenderQuestion)
    {
        return (
          <div className="row my-3">
            <div className="col-md-12">
              <p>{t (`${sectionName}.human_bio_material.title`)}</p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="human_bio_material"
                  id="yes"
                  value="YES"
                  onChange={this.OnHandleControlQuestion}
                  checked={answers.human_bio_material === 'YES' ? 'checked' : ''}
                  ref={input => {
                    this.nameInput = input;
                  }}
                />
                <label className="form-check-label" htmlFor="yes">
                  {t (`YES`)}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="human_bio_material"
                  id="NO"
                  value="NO"
                  onChange={this.OnHandleControlQuestion}
                  checked={answers.human_bio_material === 'NO' ? 'checked' : ''}
                />
                <label className="form-check-label" htmlFor="NO">
                  {t (`NO`)}
                </label>
              </div>
            </div>
          </div>
        );
      }
    };

    RenderMaterialDescription = () => {
      const {t} = this.props;
      const {sectionName, answers} = this.state;

      let mustRenderQuestion =  this.MustRenderQuestion(['human_bio_material', 'human_bio_material_description']);

      if (mustRenderQuestion)
      {
        return (
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="human_bio_material_description">
                  {t (`${sectionName}.human_bio_material_description.title`)}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  name="human_bio_material_description"
                  id="human_bio_material_description"
                  value={answers.human_bio_material_description}
                  onChange={this.OnHandleText}
                />
              </div>
            </div>
          </div>
        );
      }
  };

  RenderMaterialHypothesis = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    return "";//disable this question
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="project_hypothesis">
              {t (`${sectionName}.project_hypothesis.title`)}
            </label>
            <textarea
              type="text"
              className="form-control"
              name="project_hypothesis"
              id="project_hypothesis"
              value={answers.project_hypothesis}
              onChange={this.OnHandleText}
            />
          </div>
        </div>
      </div>
    );
  };

  RenderMaterialMethodology = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    return "";//disable this question
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="project_methodology">
              {t (`${sectionName}.project_methodology.title`)}
            </label>
            <textarea
              type="text"
              className="form-control"
              name="project_methodology"
              id="project_methodology"
              value={answers.project_methodology}
              onChange={this.OnHandleText}
            />
          </div>
        </div>
      </div>
    );
  };

  RenderMaterialProvenanceOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'human_bio_material_laboratory',
      'human_bio_material_biobank',
      'human_bio_material_sample_science_fairs',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderMaterialProvenanceProcedures = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_bio_material', 
      'human_bio_material_provenance',
      'human_bio_material_sample_other',
      'human_bio_material_laboratory',
      'human_bio_material_biobank',
      'human_bio_material_sample_science_fairs',
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.human_bio_material_provenance.title`)}</p>
          </div>

          {this.RenderMaterialProvenanceOptions ()}

          <div className="col-md-12 pl-5">
            <div className="form-group">
              <label htmlFor="human_bio_material_sample_other">
                {t (`${sectionName}.human_bio_material_sample_other.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="human_bio_material_sample_other"
                id="human_bio_material_sample_other"
                value={answers.human_bio_material_sample_other}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );

    }
  };

  RenderMaterialRadioactive = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_bio_material', 
      'project_radioactive'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.project_radioactive.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_radioactive"
                id="project_radioactive_yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={answers.project_radioactive === 'YES' ? 'checked' : ''}
              />
              <label
                className="form-check-label"
                htmlFor="project_radioactive_yes"
              >
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_radioactive"
                id="project_radioactive_no"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={answers.project_radioactive === 'NO' ? 'checked' : ''}
              />
              <label
                className="form-check-label"
                htmlFor="project_radioactive_no"
              >
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialAtRisk = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_bio_material', 
      'project_material_at_risk'
    ]);

    if (mustRenderQuestion)
    {

      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.project_material_at_risk.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_material_at_risk"
                id="project_material_at_risk_yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={
                  answers.project_material_at_risk === 'YES' ? 'checked' : ''
                }
              />
              <label
                className="form-check-label"
                htmlFor="project_material_at_risk_yes"
              >
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_material_at_risk"
                id="project_material_at_risk_no"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={
                  answers.project_material_at_risk === 'NO' ? 'checked' : ''
                }
              />
              <label
                className="form-check-label"
                htmlFor="project_material_at_risk_no"
              >
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderBioMaterialControlQuestion ()}
        {answers.human_bio_material === 'YES' &&
          <Fragment>
            {this.RenderMaterialDescription ()}
            <hr />
            {this.RenderMaterialProvenanceProcedures ()}
            <hr />
            {this.RenderMaterialRadioactive ()}
            <hr />
            {this.RenderMaterialAtRisk ()}
            <hr />
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (HumanBioMaterial)
);
