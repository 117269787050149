import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class ChemicalMaterial extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'chemicalMaterial',
      isValid: false,

      answers: {
        chemical_material: 'NO',

        chemical_material_type: '',
        project_hypothesis: '',
        project_methodology: '',
        chemical_provenance: '',
        chemical_provenance_other: ''
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (
      answers.chemical_material === 'YES' &&
      isEmpty (answers.chemical_material_type)
    )
      isValid = false;

    if (
      answers.chemical_material === 'YES' &&
      isEmpty (answers.chemical_provenance)
    )
      isValid = false;

    if (
      answers.chemical_material === 'YES' &&
      isEmpty (answers.chemical_provenance_other)
    )
      isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleControlQuestion = e => {
    const {answers} = this.state;

    answers['chemical_material_type'] = '';
    answers['project_hypothesis'] = '';
    answers['project_methodology'] = '';
    answers['chemical_provenance'] = '';
    answers['chemical_provenance_other'] = '';

    answers[e.currentTarget.name] = e.currentTarget.value;

    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }
    return false;
  }

  RenderChemicalMaterialControlQuestion = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('chemical_material');

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.chemical_material.title`)}</p>
            <p className="important">
              {t (`${sectionName}.chemical_material.text`)}
            </p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="chemical_material"
                id="chemical_material_yes"
                value="YES"
                onChange={this.OnHandleControlQuestion}
                checked={answers.chemical_material === 'YES' ? 'checked' : ''}
                ref={input => {
                  this.nameInput = input;
                }}
              />
              <label className="form-check-label" htmlFor="chemical_material_yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="chemical_material"
                id="chemical_material_NO"
                value="NO"
                onChange={this.OnHandleControlQuestion}
                checked={answers.chemical_material === 'NO' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="chemical_material_NO">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialType = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['chemical_material', 'chemical_material_type']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="chemical_material_type">
                {t (`${sectionName}.chemical_material_type.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="chemical_material_type"
                id="chemical_material_type"
                value={answers.chemical_material_type}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialHypothesis = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['chemical_material', 'project_hypothesis']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_hypothesis">
                {t (`${sectionName}.project_hypothesis.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="project_hypothesis"
                id="project_hypothesis"
                value={answers.project_hypothesis}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialMethodology = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="project_methodology">
              {t (`${sectionName}.project_methodology.title`)}
            </label>
            <textarea
              type="text"
              className="form-control"
              name="project_methodology"
              id="project_methodology"
              value={answers.project_methodology}
              onChange={this.OnHandleText}
            />
          </div>
        </div>
      </div>
    );
  };

  RenderChemicalProvenance = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['chemical_material', 'chemical_provenance', 'chemical_provenance_other']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p>{t (`${sectionName}.chemical_provenance.first_part`)}<strong>{t (`${sectionName}.chemical_provenance.important_part`)}</strong></p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="chemical_provenance"
                  id="chemical_provenance_yes"
                  value="YES"
                  onChange={this.OnHandleRadio}
                  checked={answers.chemical_provenance === 'YES' ? 'checked' : ''}
                />
                <label className="form-check-label" htmlFor="chemical_provenance_yes">
                  {t (`YES`)}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="chemical_provenance"
                  id="chemical_provenance_NO"
                  value="NO"
                  onChange={this.OnHandleRadio}
                  checked={answers.chemical_provenance === 'NO' ? 'checked' : ''}
                />
                <label className="form-check-label" htmlFor="chemical_provenance_NO">
                  {t (`NO`)}
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="chemical_provenance_other">
                {t (`${sectionName}.chemical_provenance_other.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="chemical_provenance_other"
                id="chemical_provenance_other"
                value={answers.chemical_provenance_other}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderChemicalMaterialControlQuestion ()}
        {answers.chemical_material === 'YES' &&
          <Fragment>
            {this.RenderMaterialType ()}
            <hr />
            {this.RenderChemicalProvenance ()}
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (ChemicalMaterial)
);
