import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getAllRegions } from '../../../../store/actions/regionActions';

import isEmpty from '../../../../validation/isEmpty';
import {
  ValidateCharAmount,
  ValidateWordAmount,
} from '../../../../validation/fieldValidation';
const WAIT_INTERVAL = 240;
export class ProjectInformations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionName: 'projectInformations',
      isValid: false,

      answers: {
        project_title: '',
        project_description: '',
      },
    };
  }

  Validate = () => {
    const { answers } = this.state;
    let isValid = true;

    if (isEmpty(answers.project_title)) isValid = false;
    if (isEmpty(answers.project_description)) isValid = false;
    if (!ValidateCharAmount(answers.project_title, 1, 30)) isValid = false;
    if (!ValidateWordAmount(answers.project_description, 75, 100)) {
      isValid = false;
    }
    this.props.CheckValid(isValid);
  };

  componentDidMount() {
    const { FormAnswers } = this.props;
    const { sectionName } = this.state;
    this.timer = null;
    if (!isEmpty(FormAnswers[sectionName])) {
      this.setState({ answers: FormAnswers[sectionName] }, () => {
        this.Validate();
      });
    } else {
      this.props.SetAnswers(this.state);
    }
    //this.nameInput.focus ();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const { FormAnswers } = this.props;
      const { sectionName } = this.state;
      this.setState({ answers: FormAnswers[sectionName] }, () => {
        //this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const { answers } = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.timer = null;
      this.triggerChange();
    }, WAIT_INTERVAL);

    this.setState({ answers }, () => {
      this.props.SetAnswers(this.state);
      //this.Validate ();
    });
  };

  triggerChange() {
    this.Validate();
  }

  OnHandleRadio = e => {
    const { answers } = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ answers }, () => {
      this.props.SetAnswers(this.state);
      this.Validate();
    });
  };

  MustRenderQuestion = (question) => {
    const { mode, questions } = this.props;

    if (mode != 'correction')
      return true;

    return questions.includes(question);
  }

  RenderProjectInfos = () => {
    const { t } = this.props;
    const { sectionName, answers } = this.state;

    let mustRenderQuestion_projectName = this.MustRenderQuestion('project_title');
    let mustRenderQuestion_description = this.MustRenderQuestion('project_description');

    return (
      <div className="row">

        {mustRenderQuestion_projectName &&
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_title">
                {t(`${sectionName}.project_title.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="project_title"
                id="project_title"
                value={answers.project_title}
                onChange={this.OnHandleText}
                maxLength="30"
                ref={input => {
                  this.nameInput = input;
                }}
              />
            </div>
          </div>
        }

        {mustRenderQuestion_description &&

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_description">
                {t(`${sectionName}.project_description.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="project_description"
                id="project_description"
                value={answers.project_description}
                onChange={this.OnHandleText}
                autoComplete="off"
                spellCheck="false"
                autoCorrect="off"
                autoCapitalize="off"
                minLength="590"
                maxLength="785"
              />
            </div>
            <div>
              {
                answers
                  .project_description
                  .trim()
                  .split(" ")
                  .filter((word) => { if (word !== '') return word })
                  .length
              } {t(`word`)}
            </div>
          </div>
        }

      </div>
    );
  };

  RenderQuestions = () => {
    return (
      <Fragment>
        {this.RenderProjectInfos()}
      </Fragment>
    );
  };

  render() {
    const { t } = this.props;
    const { sectionName } = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t(sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions()}
      </div>
    );
  }
}

const mapStateToProps = state => ({ region: state.region });

const mapDispatchToProps = { getAllRegions };

export default withTranslation('form')(
  connect(mapStateToProps, mapDispatchToProps)(ProjectInformations)
);
