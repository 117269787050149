import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class AnimalBioMaterial extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'animalBioMaterial',
      isValid: false,

      answers: {
        animal_bio_material: 'NO',

        animal_bio_material_type: '',
        project_hypothesis: '',
        project_methodology: '',
        animal_sacrificed: 'NO',
        animal_provenance: 'YES',
        animal_provenance_other: '',
        project_radioactive: 'NO',
        project_material_at_risk: 'NO',
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (
      answers.animal_bio_material === 'YES' &&
      isEmpty (answers.animal_bio_material_type)
    )
      isValid = false;
    if (
      answers.animal_bio_material === 'YES' &&
      isEmpty (answers.animal_sacrificed)
    )
      isValid = false;
    if (
      answers.animal_bio_material === 'YES' &&
      isEmpty (answers.animal_provenance)
    )
      isValid = false;

    if (
      answers.animal_bio_material === 'YES' &&
      isEmpty (answers.animal_provenance_other)
    )
      isValid = false;

    if (
      answers.animal_bio_material === 'YES' &&
      isEmpty (answers.project_radioactive)
    )
      isValid = false;

    if (
      answers.animal_bio_material === 'YES' &&
      isEmpty (answers.project_material_at_risk)
    )
      isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleControlQuestion = e => {
    const {answers} = this.state;
    answers['animal_bio_material'] = '';

    answers['animal_bio_material_type'] = '';
    answers['project_hypothesis'] = '';
    answers['project_methodology'] = '';
    answers['animal_sacrificed'] = 'NO';
    answers['animal_provenance'] = 'YES';
    answers['animal_provenance_other'] = '';
    answers['project_radioactive'] = 'NO';
    answers['project_material_at_risk'] = 'NO';

    answers[e.currentTarget.name] = e.currentTarget.value;

    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }
    return false;
  }

  RenderBioMaterialControlQuestion = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('animal_bio_material');

    if (mustRenderQuestion)
    {

      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.animal_bio_material.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="animal_bio_material"
                id="animal_bio_material_yes"
                value="YES"
                onChange={this.OnHandleControlQuestion}
                checked={answers.animal_bio_material === 'YES' ? 'checked' : ''}
                ref={input => {
                  this.nameInput = input;
                }}
              />
              <label className="form-check-label" htmlFor="animal_bio_material_yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="animal_bio_material"
                id="animal_bio_material_NO"
                value="NO"
                onChange={this.OnHandleControlQuestion}
                checked={answers.animal_bio_material === 'NO' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="animal_bio_material_NO">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialType = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['animal_bio_material', 'animal_bio_material_type']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="animal_bio_material_type">
                {t (`${sectionName}.animal_bio_material_type.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="animal_bio_material_type"
                id="animal_bio_material_type"
                value={answers.animal_bio_material_type}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialHypothesis = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    return "";//disable this question
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="project_hypothesis">
              {t (`${sectionName}.project_hypothesis.title`)}
            </label>
            <textarea
              type="text"
              className="form-control"
              name="project_hypothesis"
              id="project_hypothesis"
              value={answers.project_hypothesis}
              onChange={this.OnHandleText}
            />
          </div>
        </div>
      </div>
    );
  };

  RenderMaterialMethodology = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    return "";//disable this question
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="project_methodology">
              {t (`${sectionName}.project_methodology.title`)}
            </label>
            <textarea
              type="text"
              className="form-control"
              name="project_methodology"
              id="project_methodology"
              value={answers.project_methodology}
              onChange={this.OnHandleText}
            />
          </div>
        </div>
      </div>
    );
  };

  RenderAnimalSacrificied = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['animal_bio_material', 'animal_sacrificed']);

    if (mustRenderQuestion)
    {

      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.animal_sacrificed.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="animal_sacrificed"
                id="animal_sacrificed_yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={answers.animal_sacrificed === 'YES' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="animal_sacrificed_yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="animal_sacrificed"
                id="animal_sacrificed_no"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={answers.animal_sacrificed === 'NO' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="animal_sacrificed_no">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
     );
    }
  };

  RenderAnimalProvenance = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;


    let mustRenderQuestion =  this.MustRenderQuestion(['animal_bio_material', 'animal_provenance']);

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
                <p>{t (`${sectionName}.animal_provenance.first_part`)}<strong>{t (`${sectionName}.animal_provenance.important_part`)}</strong></p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="animal_provenance"
                    id="animal_provenance_yes"
                    value="YES"
                    onChange={this.OnHandleRadio}
                    checked={answers.animal_provenance === 'YES' ? 'checked' : ''}
                  />
                  <label className="form-check-label" htmlFor="aanimal_provenance_yes">
                    {t (`YES`)}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="animal_provenance"
                    id="animal_provenance_no"
                    value="NO"
                    onChange={this.OnHandleRadio}
                    checked={answers.animal_provenance === 'NO' ? 'checked' : ''}
                  />
                  <label className="form-check-label" htmlFor="animal_provenance_no">
                    {t (`NO`)}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="animal_provenance">
                  {t (`${sectionName}.animal_provenance.precision`)}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  name="animal_provenance_other"
                  id="animal_provenance_other"
                  value={answers.animal_provenance_other}
                  onChange={this.OnHandleText}
                />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialRadioactive = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['animal_bio_material', 'project_radioactive']);

    if (mustRenderQuestion)
    {

      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.project_radioactive.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_radioactive"
                id="project_radioactive_yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={answers.project_radioactive === 'YES' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="project_radioactive_yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_radioactive"
                id="project_radioactive_no"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={answers.project_radioactive === 'NO' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="project_radioactive_no">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderMaterialAtRisk = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['animal_bio_material', 'project_material_at_risk']);

    if (mustRenderQuestion)
    {

      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.project_material_at_risk.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_material_at_risk"
                id="project_material_at_risk_yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={
                  answers.project_material_at_risk === 'YES' ? 'checked' : ''
                }
              />
              <label
                className="form-check-label"
                htmlFor="project_material_at_risk_yes"
              >
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="project_material_at_risk"
                id="project_material_at_risk_no"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={
                  answers.project_material_at_risk === 'NO' ? 'checked' : ''
                }
              />
              <label
                className="form-check-label"
                htmlFor="project_material_at_risk_no"
              >
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderBioMaterialControlQuestion ()}
        {answers.animal_bio_material === 'YES' &&
          <Fragment>
            {this.RenderMaterialType ()}
            {this.RenderAnimalSacrificied ()}
            {this.RenderAnimalProvenance ()}
            {this.RenderMaterialRadioactive ()}
            {this.RenderMaterialAtRisk ()}
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (AnimalBioMaterial)
);
