import axios from 'axios';
import setAuthToken from '../../utils/setAuthHeader';
import jwt_decode from 'jwt-decode';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_SPINNER,
  SET_MESSAGE,
  SET_LANG,
  CLEAR_ALL,
} from './types';
import {getCommitteeMembers} from './userActions';

//Register new user
//Redirects to login
export const registerUser = (userData, history) => dispatch => {
  axios
    .post ('/api/user/register', userData)
    .then (result => {
      dispatch ({
        type: SET_MESSAGE,
        payload: {type: 'NEW_USER', msg: 'Utilisateur créé'},
      });
    })
    .then (result => {
      dispatch (loginUser (userData));
      history.push ('/connexion');
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Logs user
//Saves token into localstorage
//Sets current user into store
export const loginUser = userData => dispatch => {
  axios
    .post ('/api/user/login', userData)
    .then (result => {
      const {token} = result.data;
      localStorage.setItem ('ethiqueTechnoscience', token);
      setAuthToken (token);
      const decoded = jwt_decode (token);
      dispatch (setCurrentUser (decoded));
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const adminRegisterUser = userData => dispatch => {
  axios
    .post ('/api/user/admin-register', userData)
    .then (result => {
      dispatch (getCommitteeMembers ());
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const adminUpdateUser = userData => dispatch => {
  axios
    .post ('/api/user/admin-update', userData)
    .then (result => {
      dispatch (getCommitteeMembers ());
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const sendResetPwdCode = userEmail => dispatch => {
  axios
    .post ('/api/user/send-reset-code', {email: userEmail})
    .then (result => {
      dispatch ({
        type: SET_MESSAGE,
        payload: {
          type: 'CLIENT_PWD_RESET',
          msg: 'Un courriel vous a été envoyé pour réinitialiser votre mot de passe.',
        },
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const resetUserPassword = (
  resetToken,
  password,
  password2,
  history
) => dispatch => {
  axios
    .post ('/api/user/reset-password', {resetToken, password, password2})
    .then (result => {
      dispatch ({
        type: SET_MESSAGE,
        payload: {
          type: 'USER_PWD_RESET',
          msg: 'Le mot de passe a été réinitialisé',
        },
      });
      history.push ('/connexion');
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const logoutUser = () => dispatch => {
  //removes from localstorage
  localStorage.removeItem ('ethiqueTechnoscience');
  //resets requests authorization header
  setAuthToken (false);
  //Set current user to {}
  //set isAuthenticated to false
  dispatch (ClearAllInfos ());
  //Redirects to login
  window.location.href = '/connexion';
};

export const ClearAllInfos = () => dispatch => {
  dispatch ({type: CLEAR_ALL});
};

export const SetSpinner = val => dispatch => {
  dispatch ({type: SET_SPINNER, payload: val});
};
export const SetLanguage = lang => dispatch => {
  console.log (lang);

  dispatch ({type: SET_LANG, payload: lang});
};
