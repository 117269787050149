import axios from 'axios';
import {SetSpinner} from './authActions';

import {
  SET_SELECTED_FORM,
  GET_REQUEST,
  SET_ANSWERS,
  SAVE_FORM,
  GET_ERRORS,
  GET_ALL_FORMS,
  GET_FORM_ATTACHMENTS,
  DELETE_FORM,
} from './types';

const logger = require ('tracer').colorConsole ();

const ETHIC_EMAIL = 'ethique@technoscience.ca';
const RULES_EMAIL = 'reglements@technoscience.ca';

export const GetFormById = _id => dispatch => {
  dispatch (SetSpinner (true));
  axios
    .get ('/api/form/id', {params: {_id}})
    .then (foundRequest => {
      dispatch ({
        type: GET_REQUEST,
        payload: foundRequest.data,
      });
    })
    .then (res => {
      dispatch (SetSpinner (false));
    })
    .catch (err => {
      console.log (err);

      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const CheckIncomplete = participant => dispatch => {
  dispatch (SetSpinner (true));
  axios
    .get ('/api/form/incomplete', {params: {participant}})
    .then (foundRequest => {
      dispatch ({
        type: SET_SELECTED_FORM,
        payload: foundRequest.data,
      });
    })
    .then (res => {
      dispatch (SetSpinner (false));
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const LoadForm = _id => dispatch => {
  dispatch (SetSpinner (true));
  axios
    .get ('/api/form/id', {params: {_id}})
    .then (foundRequest => {
      dispatch ({
        type: SET_SELECTED_FORM,
        payload: foundRequest.data,
      });
    })
    .then (res => {
      dispatch (SetSpinner (false));
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const GetAllFormsByParticipant = _id => dispatch => {
  axios
    .get ('/api/form/user/id', {params: {_id}})
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const GetAllPendingFormsByParticipant = _id => dispatch => {
  axios
    .get ('/api/form/user/id/pending', {params: {_id}})
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const GetAllApprovedFormsByParticipant = _id => dispatch => {
  axios
    .get ('/api/form/user/id/approved', {params: {_id}})
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const GetAllDeniedFormsByParticipant = _id => dispatch => {
  axios
    .get ('/api/form/user/id/denied', {params: {_id}})
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const GetAllArchivedFormsByParticipant = _id => dispatch => {
  axios
    .get ('/api/form/user/id/archived', {params: {_id}})
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const GetAllActiveForms = () => dispatch => {
  axios
    .get ('/api/form/all/active')
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const GetAllPendingForms = () => dispatch => {
  axios
    .get ('/api/form/all/pending')
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const GetAllApprovedForms = () => dispatch => {
  axios
    .get ('/api/form/all/approved')
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const GetAllDeniedForms = () => dispatch => {
  axios
    .get ('/api/form/all/denied')
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const GetAllArchivedForms = () => dispatch => {
  axios
    .get ('/api/form/all/archived')
    .then (foundRequestList => {
      dispatch ({
        type: GET_ALL_FORMS,
        payload: foundRequestList.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const SetForm = form => dispatch => {
  dispatch ({type: SET_SELECTED_FORM, payload: form});
};

export const SetAnswers = answers => dispatch => {
  dispatch ({type: SET_ANSWERS, payload: answers});
};

export const Archive = (_id, isArchived) => dispatch => {
  axios
    .put ('/api/form/archive/id', {_id, isArchived})
    .then (updatedForm => {
      dispatch ({
        type: GET_REQUEST,
        payload: updatedForm.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const Delete = (_id, history) => dispatch => {
  axios
    .get ('/api/attachment/list/id', {params: {_id}})
    .then (attachmentList => {
      if (attachmentList.data.length > 0) {
        const responses = attachmentList.data.map (a => {
          return new Promise ((resolve, reject) => {
            axios
              .delete ('/api/attachment/delete', {
                params: {
                  name: a.name,
                  form: _id,
                },
              })
              .then (response => {
                resolve (response.data);
              })
              .catch (err => {
                reject (err.response.data);
              });
          });
        });
        return Promise.all (responses);
      } else {
        return axios.delete ('/api/form/id', {params: {_id}});
      }
    })
    .then (updatedForm => {
      history.push ('/demandes-archivees');
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const SaveForm = (formInfos, history) => dispatch => {
  let email = formInfos.type === 'AE' ? ETHIC_EMAIL : RULES_EMAIL;

  axios
    .put('/api/form/save', {
      formInfos
    })
    .then(savedForm => {
      dispatch({
        type: SAVE_FORM,
        payload: savedForm.data,
      });
    })
    .then(() => {
      //Send email to main coordinator
      return axios.post('/api/form/admin/notify-new-form', {
        email,
        formInfos,
      });
    })
    .then(() => {
      //Get list of regional coordinators from participant region
      let regionsList = formInfos.participant.regions;
      let role = "READONLY";

      return axios.post('/api/user/regions/role', {
        regionsList,
        role,
      });
    })
    .then(coordinatorsList => {
      //Notify all coordinators
      let emails = coordinatorsList.data.map(coordo => coordo.email);

      if (emails.length === 0) {
        return true
      }

      return axios.post('/api/form/admin/notify-new-form', {
        email,
        formInfos,
        emails
      });

    })
    .then(res => {
      history.push ('/mes-demandes');
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const NewForm = (userId, history) => dispatch => {
  axios
    .post ('/api/form/new', {userId})
    .then (savedForm => {
      dispatch ({
        type: SET_SELECTED_FORM,
        payload: savedForm.data,
      });
      return savedForm._id;
    })
    .catch (err => {
      history.push ('/mes-demandes');
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const SaveSection = formInfos => dispatch => {
  axios
    .put ('/api/form/id', {formInfos})
    .then (savedForm => {
      dispatch ({
        type: SET_SELECTED_FORM,
        payload: savedForm.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const SaveCorrectedAnswers = (formInfos, history, is_admin) => dispatch => {
  const { fullFormInfo } = formInfos;

  axios
    .put ('/api/form/save-corrected-answers', {formInfos})
    .then (savedForm => {
      dispatch ({
        type: SET_SELECTED_FORM,
        payload: savedForm.data,
      });
    })
    .then (() => {
      if ( ! is_admin)
      {
        //let email = formInfos.type === 'AE' ? ETHIC_EMAIL : RULES_EMAIL;
        const {committeeNotifications} = fullFormInfo;
        
        const responses = committeeNotifications.map (userId => {
          return new Promise ((resolve, reject) => {
            return axios
              .post ('/api/form/admin/notify-new-modification', {
                userId,
                fullFormInfo
              })
              .then (response => {
                resolve (response);
              })
              .catch (err => {
                reject (err);
              });
          });
        });
        return Promise.all (responses).then (responses => {
          console.log ('RESPONSES', responses);
        });
      }
    })
    .then (() => {
      history.push (`/discussion/${formInfos._id}`);
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

  export const AddDiscussionMessage = (messageInfos, formInfos) => dispatch => {
    axios
      .post ('/api/message/add-discussion-message', {messageInfos})
      .then (savedMessage => {
        return axios.post ('/api/form/add-discussion-message', {
          messageInfos: savedMessage.data,
        });
      })
      .then (updatedForm => {
        dispatch ({
          type: GET_REQUEST,
          payload: updatedForm.data,
        });
      })
      .then (() => {
        let email = formInfos.type === 'AE' ? ETHIC_EMAIL : RULES_EMAIL;
        const {committeeNotifications} = formInfos;

        const responses = committeeNotifications.map (userId => {
          return new Promise ((resolve, reject) => {
            return axios
              .post ('/api/form/notify-new-message', {
                userId,
                formInfos,
                messageInfos,
              })
              .then (response => {
                resolve (response);
              })
              .catch (err => {
                reject (err);
              });
          });
        });

        return Promise.all (responses).then (responses => {
          console.log ('RESPONSES', responses);
        });
      })
      .catch (err => {
        console.log (err);

        dispatch ({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

  export const ToggleNewDiscussionMessage = messageInfos => dispatch => {
    axios
    .put('/api/message/toggle-new-discussion-message', { messageInfos })
    .then(updatedMessage => {
      return axios.get('/api/form/id', { params: { _id: messageInfos.form } });
    })
    .then(foundForm => {
      dispatch({
        type: GET_REQUEST,
        payload: foundForm.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};


export const DeleteDiscussionMessage = messageInfos => dispatch => {
  axios
    .put('/api/message/delete-message', { messageInfos })
    .then(updatedMessage => {
      return axios.get('/api/form/id', { params: { _id: messageInfos.form } });
      })
    .then(foundForm => {
      dispatch({
          type: GET_REQUEST,
          payload: foundForm.data,
        });
      })
    .catch(err => {
      dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const GetAttachmentsList = _id => dispatch => {
  axios
    .get ('/api/attachment/list/id', {params: {_id}})
    .then (addedAttachment => {
      dispatch ({
        type: GET_FORM_ATTACHMENTS,
        payload: addedAttachment.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
  });
};

export const AddAttachment = (file, id, author, formInfos) => dispatch => {
  let email = formInfos.type === 'AE' ? ETHIC_EMAIL : RULES_EMAIL;

  const formData = new FormData ();
  formData.append ('file', file);
  formData.append ('name', file.name);
  let attachment = null;
  return axios
    .get ('/api/attachment/file-exist', {
      params: {
        name: file.name,
        form: id,
      },
    })
    .then (fileExist => {
      if (fileExist.data) {
        throw {
          response: {
            data: {
              foundFile: {
                type: 'FOUND_FILE',
                msg: 'Le fichier est déjà téléchargé',
              },
            },
          },
        };
      }

      return axios.post ('/api/attachment/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          id: id,
          author: author,
        },
      });
    })
    .then (addedAttachment => {
      attachment = addedAttachment.data;
      return axios.post ('/api/form/add-attachment', {
        attachmentInfos: addedAttachment.data,
      });
    })
    .then (() => {
      let email = formInfos.type === 'AE' ? ETHIC_EMAIL : RULES_EMAIL;
      const {committeeNotifications} = formInfos;

      const responses = committeeNotifications.map (userId => {
        return new Promise ((resolve, reject) => {
          return axios
            .post ('/api/form/notify-new-document', {
              userId,
              attachmentInfos: attachment,
              formInfos,
            })
            .then (response => {
              resolve (response);
            })
            .catch (err => {
              console.log (err);

              reject (err);
            });
        });
      });

      return Promise.all (responses).then (responses => {
        //console.log ('RESPONSES', responses);
      });
    })
    .then (addedAttachment => {
      dispatch (GetAttachmentsList (id));
    })
    .catch (err => {
      console.log (err);

      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const DeleteAttachment = (name, form, _id) => dispatch => {
  return axios
    .delete ('/api/attachment/delete', {
      params: {
        name,
        form,
      },
    })
    .then (addedAttachment => {
      return axios.put ('/api/form/remove-attachment', {
        form,
        attachmentId: _id,
      });
    })
    .then (deletedAttachment => {
      dispatch (GetAttachmentsList (form));
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const DownloadAttachment = (name, form, format) => dispatch => {
  axios
    .get ('/api/attachment/download', {
      params: {
        name,
        form,
      },
      responseType: 'blob',
    })
    .then (file => {
      const downloadUrl = window.URL.createObjectURL (new Blob ([file.data]));
      const link = document.createElement ('a');
      link.href = downloadUrl;
      link.setAttribute ('download', name); //any other extension
      document.body.appendChild (link);
      link.click ();
      link.remove ();
    })
    .catch (err => {
      var reader = new FileReader ();
      reader.addEventListener ('loadend', function () {
        // reader.result contient le contenu du
        // blob sous la forme d'un tableau typé
        dispatch ({
          type: GET_ERRORS,
          payload: JSON.parse (reader.result),
        });
      });
      reader.readAsText (err.response.data);
    });
};

export const ToggleNewAttachment = attachmentInfos => dispatch => {
  axios
    .put ('/api/attachment/toggle-new-attachment', {
      attachmentInfos,
    })
    .then (updatedMessage => {
      return axios.get ('/api/form/id', {params: {_id: attachmentInfos.form}});
    })
    .then (foundForm => {
      dispatch ({
        type: GET_REQUEST,
        payload: foundForm.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const SwitchFormStatus = (_id, status, approbationDate, formInfos) => dispatch => {
  axios
    .put ('/api/form/switch-form-status', {_id, status, approbationDate})
    .then (updatedForm => {
      dispatch ({
        type: GET_REQUEST,
        payload: updatedForm.data,
      });
    })
    .then (() => {
      return axios.post ('/api/form/notify-status-switch', {
        formInfos
      });
    })
   .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const ChangeConditions = (_id, conditions) => dispatch => {
  axios
    .put ('/api/form/change-conditions', {_id, conditions})
    .then (updatedForm => {
      dispatch ({
        type: GET_REQUEST,
        payload: updatedForm.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const ChangeCorrections = (_id, section, key) => dispatch => {
  axios
    .put ('/api/form/update-corrections', {_id, section, key})
    .then (updatedForm => {
      dispatch ({
        type: GET_REQUEST,
        payload: updatedForm.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const UpdateNotifications = (_id, usersList) => dispatch => {
  axios
    .put ('/api/form/update-notifications', {_id, usersList})
    .then (updatedForm => {
      dispatch ({
        type: GET_REQUEST,
        payload: updatedForm.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const DeleteForm = (_id, history) => dispatch => {
  axios
    .delete ('/api/form/id', {params: {_id}})
    .then (deletedForm => {
      dispatch ({
        type: DELETE_FORM,
        payload: deletedForm.data,
      });
    })
    .then (res => {
      history.push ('/mes-demandes');
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
