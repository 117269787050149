import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import classnames from 'classnames';
import {
  SetForm,
  NewForm,
  LoadForm,
  SaveSection,
  SaveForm,
  DeleteForm,
} from '../../store/actions/formActions';
import moment from 'moment';

import DiscussionNav from '../discussion/DiscussionNav';
import DeleteWarningModal from './DeleteWarningModal';
//GENERAL SECTIONS
import ParticipantInformations
  from './Sections/General/ParticipantInformations';
import ProjectMembers from './Sections/General/ProjectMembers';
import ProjectInformation from './Sections/General/ProjectInformation';
import SecondYear from './Sections/General/SecondYear';
import Institution from './Sections/General/Institution';
import Supervisor from './Sections/General/Supervisor';

//ETHICS SECTIONS
import ActiveParticipationHuman
  from './Sections/Ethics/ActiveParticipationHuman';
import RecrutmentMethods from './Sections/Ethics/RecrutmentMethods';
import RisksAndLimitations from './Sections/Ethics/RisksAndLimitations';
import EvaluationByEthicsCommittee
  from './Sections/Ethics/EvaluationByEthicsCommittee';

//RULES SECTIONS
import AnimalNonHumanBioMaterial from './Sections/Rules/AnimalBioMaterial';
import HumanBioMaterial from './Sections/Rules/HumanBioMaterial';
import ChemicalMaterial from './Sections/Rules/ChemicalMaterial';

//END SECTIONS
import Protocol from './Sections/General/Protocol';
import InstitutionsEngagements
  from './Sections/General/InstitutionsEngagements';

export class Form2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: '',
      form: {},
      answers: {},
      selectedSection: 1,
      isValid: false,
      sections: [
        <ParticipantInformations
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <ProjectMembers
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <ProjectInformation
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <SecondYear
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <Institution
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <Supervisor
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <ActiveParticipationHuman
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <RecrutmentMethods
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          SwitchSection={this.SwitchSection}
          GetAnswers={this.GetAnswers}
        />,
        <RisksAndLimitations
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          SwitchSection={this.SwitchSection}
          GetAnswers={this.GetAnswers}
        />,
        <EvaluationByEthicsCommittee
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          SwitchSection={this.SwitchSection}
          GetAnswers={this.GetAnswers}
        />,
        <HumanBioMaterial
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <AnimalNonHumanBioMaterial
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <ChemicalMaterial
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <Protocol
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
        <InstitutionsEngagements
          SetAnswers={this.SetAnswers}
          CheckValid={this.CheckValid}
          GetAnswers={this.GetAnswers}
        />,
      ],
    };
  }
  GetAnswers = () => {
    return this.state.answers;
  };

  componentDidMount() {
    const { user } = this.props.auth;

    if (this.props.match.params.formId === 'new') {
      this.props.NewForm(user._id, this.props.history);
    } else {
      this.props.LoadForm(this.props.match.params.formId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.formId === 'new' &&
      prevProps.form.selectedForm !== this.props.form.selectedForm
    ) {
      this.props.history.push(`/form/${this.props.form.selectedForm._id}`);
    }

    if (
      this.props.match.params.formId !== 'new' &&
      prevProps.form.selectedForm.answers !==
      this.props.form.selectedForm.answers
    ) {
      const { answers } = this.props.form.selectedForm;
      this.setState({ answers });
    }
  }

  CheckValid = isValid => {
    this.setState({ isValid });
  };

  SaveSection = () => {
    const { selectedForm } = this.props.form;
    const { answers } = this.state;
    selectedForm.answers = answers;

    this.props.SaveSection(selectedForm);
  };
  SaveForm = () => {
    const { selectedForm } = this.props.form;
    const { answers } = this.state;
    selectedForm.answers = answers;
    selectedForm.lang = i18next.language;
    selectedForm.isComplete = true;
    selectedForm.date = moment().format();
    this.props.SaveForm(selectedForm, this.props.history);
  };
  DeleteForm = () => {
    this.ShowModal();
  };
  SetAnswers = section => {
    const { answers } = this.state;
    answers[section.sectionName] = section.answers;
    this.setState({ answers });
  };

  RenderSection = () => {
    const { selectedSection, sections, answers } = this.state;
    const section = sections[selectedSection - 1];

    return React.cloneElement(section, { FormAnswers: answers, mode: 'fill' });
  };

  SwitchSection = id => {
    this.SaveSection();
    this.setState({ selectedSection: id });
  };

  RenderNavButtons = () => {
    const { t } = this.props;
    const { selectedSection, sections } = this.state;
    const previous = Math.max(selectedSection - 1, 0);
    const next = Math.min(selectedSection + 1, sections.length);

    return (
      <div className="pt-2 pb-5 row">
        {selectedSection > 1 &&
          <button
            className="btn btn-main mr-2"
            onClick={() => {
              this.SwitchSection(previous);
            }}
          >
            {t('buttons.previous')}
          </button>}
        {selectedSection < sections.length &&
          <button
            className={classnames('btn btn-main', {
              'is-invalid': !this.state.isValid,
            })}
            onClick={() => {
              this.SwitchSection(next);
              this.SaveSection();
            }}
          >
            {t('buttons.next')}
          </button>}
        {selectedSection === sections.length &&
          <button
            className={classnames('btn btn-accent', {
              'is-invalid': !this.state.isValid,
            })}
            onClick={this.ShowSavePopup}
          >
            <h3>{t('buttons.send')}</h3>
          </button>}

      </div>
    );
  };
  RenderMenuSideBar = () => {
    return <DiscussionNav />;
  };

  RenderMain = () => {
    const { t } = this.props;
    const { selectedForm } = this.props.form;
    if (selectedForm === null || selectedForm === undefined) return;
    return (
      <main className="discussion-main">
        <div className="popup-container confirmation-popup invisible">
          <div className="content">
            <h2>{t("save_form_popup")}</h2>
            <p>{t("save_form_popup_message")}</p>
            <div className="button-group">
              <button className="btn btn-accent" onClick={this.HideSavePopup}><h4>{t("NO")}</h4></button>
              <button className="btn btn-main" onClick={this.SaveForm}><h4>{t("save_form_popup_yes")}</h4></button>
            </div>
          </div>
          <div className="bg"></div>
        </div>
        <h3 className="py-3">{t(selectedForm.name)}</h3>
        {this.RenderSection()}
        {this.RenderNavButtons()}
        <div className="row">
          <button
            className={classnames('btn btn-alert')}
            onClick={this.DeleteForm}
          >
            {t('buttons.delete')}
          </button>
        </div>
      </main>
    );
  };

  RenderAside = () => { };

  ShowSavePopup = () => {
    const popup = document.querySelector(".popup-container")
    popup.classList.remove("invisible");
  }

  HideSavePopup = (event) => {
    const popup = event.currentTarget.closest(".popup-container")
    popup.classList.add("invisible");
  }


  ShowModal = () => {
    const { selectedForm } = this.props.form;
    const modal = (
      <DeleteWarningModal
        ClearModal={this.ClearModal}
        _id={selectedForm._id}
        history={this.props.history}
      />
    );

    this.setState({ modal }, () => {
      document.getElementById('modalEntry-btn').click();
    });
  };

  ClearModal = () => {
    document.getElementById('closeModalBtn').click();
    this.setState({ modal: '' });
  };

  render() {
    return (
      <div className="discussion-container">

        {this.state.modal}
        {this.RenderMenuSideBar()}
        {this.RenderMain()}
        {this.RenderAside()}
      </div>
    );
  }
}

const mapStateToProps = state => ({ form: state.form, auth: state.auth });

const mapDispatchToProps = {
  SetForm,
  NewForm,
  LoadForm,
  SaveSection,
  SaveForm,
  DeleteForm,
};

export default withTranslation('form')(
  connect(mapStateToProps, mapDispatchToProps)(Form2)
);
