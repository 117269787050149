import {combineReducers} from 'redux';

import authReducer from './authReducer';
import userReducer from './userReducer';
import regionReducer from './regionReducer';
import formReducer from './formReducer';
import messageReducer from './messageReducer';
import siteStyleReducer from './siteStyleReducer';
import translationReducer from './translationReducer';
import errorReducer from './errorReducer';

export default combineReducers ({
  auth: authReducer,
  user: userReducer,
  siteStyle:siteStyleReducer,
  region: regionReducer,
  form: formReducer,
  translations:translationReducer,
  message: messageReducer,
  error: errorReducer
});
