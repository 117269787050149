import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class InstitutionsEngagements extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'institutionsEngagements',
      isValid: false,

      answers: {
        certify_all_info: false,
        certify_protocol: false,
        certify_tools: false,

        certify_rules: false,
        certify_filled: false,
        certify_informed: false,
        certify_ethics_security: false,
        certify_public_authorization: false,
        certify_photo_authorization: false,
        certify_content: false,
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (answers.certify_all_info === false) isValid = false;
    if (answers.certify_protocol === false) isValid = false;
    if (answers.certify_tools === false) isValid = false;

    if (answers.certify_rules === false) isValid = false;
    if (answers.certify_filled === false) isValid = false;
    if (answers.certify_informed === false) isValid = false;
    if (answers.certify_ethics_security === false) isValid = false;
    if (answers.certify_public_authorization === false) isValid = false;
    if (answers.certify_photo_authorization === false) isValid = false;
    if (answers.certify_content === false) isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
   // this.nameInput.focus ();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }

    return false;
  }

  RenderDocumentOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = ['certify_all_info', 'certify_protocol', 'certify_tools'];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
              ref={input => {
                if (type === 'certify_all_info') this.nameInput = input;
              }}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderDocumentCertification = () => {
    const {t} = this.props;
    const {sectionName} = this.state;

    const types = ['certify_all_info', 'certify_protocol', 'certify_tools'];
    let mustRenderQuestion =  this.MustRenderQuestion(types);

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.document_certification.title`)}</p>
          </div>
  
          {this.RenderDocumentOptions ()}
        </div>
      );
    }
  };
  RenderInformationOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'certify_rules',
      'certify_filled',
      'certify_informed',
      'certify_ethics_security',
      'certify_public_authorization',
      'certify_photo_authorization',
      'certify_content',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-12 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };
  RenderInformedCertification = () => {
    const {t} = this.props;
    const {sectionName} = this.state;

    const types = [
      'certify_rules',
      'certify_filled',
      'certify_informed',
      'certify_ethics_security',
      'certify_public_authorization',
      'certify_photo_authorization',
      'certify_content',
    ];
    let mustRenderQuestion =  this.MustRenderQuestion(types);

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <hr />
            <p>{t (`${sectionName}.information_certification.title`)}</p>
          </div>

          {this.RenderInformationOptions ()}
        </div>
      );
    }
  };

  RenderQuestions = () => {
    return (
      <Fragment>

        {this.RenderDocumentCertification ()}
        {this.RenderInformedCertification ()}

      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        <h6 className="section-text important">
          {t (sectionName + '.sectionText')}
        </h6>
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (InstitutionsEngagements)
);
