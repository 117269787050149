import axios from 'axios';

import {
  GET_TRANSLATIONS,
  EDIT_TRANSLATIONS,
  SET_MESSAGE,
  GET_ERRORS
} from './types';

const logger = require('tracer').colorConsole();

export const GetTranslations = () => dispatch => {
  axios
    .get('api/translations')
    .then(foundRequest => {
      dispatch({
        type: GET_TRANSLATIONS,
        payload: foundRequest.data,
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const UpdateTranslations = (translations) => dispatch => {
  axios
    .put('api/translations/edit', translations)
    .then(foundRequest => {
      dispatch({
        type: EDIT_TRANSLATIONS,
        payload: foundRequest.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "Translations edit",
          msg: "Changements enregistrés"
        },
      });
    })
    .catch(err => {
      console.log(err);
    });
};