//IMPORT ACTIONS
import {
  GET_ALL_FORMS,
  SET_SELECTED_FORM,
  SET_ANSWERS,
  CLEAR_SELECTED_FORM,
  GET_REQUEST,
  CLEAR_ALL,
  GET_FORM_ATTACHMENTS,
} from '../actions/types';

const initialState = {
  formsList: [],
  selectedForm: {},
  pendingRequest: {},
  answers: {},
  attachments: [],
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FORMS:
      return {
        ...state,
        formsList: action.payload,
      };
    case SET_SELECTED_FORM:
      return {
        ...state,
        selectedForm: action.payload,
      };
    case GET_REQUEST:
      return {
        ...state,
        pendingRequest: action.payload,
      };
    case CLEAR_SELECTED_FORM:
      return {
        ...state,
        selectedForm: {},
      };
    case SET_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };
    case GET_FORM_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
};

export default formReducer;
