import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class RisksAndLimitations extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'risksAndLimitations',
      isValid: false,

      answers: {
        human_subjects_participation: 'NO',

        risks_hurt: false,
        risks_stress: false,
        risks_not_feeling_well: false,
        risks_judged: false,
        risks_privacy: false,
        risks_uncomfortable: false,
        risks_other: '',

        risks_limitations: '',
      },
    };
  }
  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (
      answers.human_subjects_participation === 'YES' &&
      (answers.risks_hurt === false &&
        answers.risks_stress === false &&
        answers.risks_not_feeling_well === false &&
        answers.risks_judged === false &&
        answers.risks_privacy === false &&
        answers.risks_uncomfortable === false &&
        isEmpty (answers.risks_other))
    )
      isValid = false;

    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.risks_limitations)
    )
      isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      if (
        isEmpty (FormAnswers.activeParticipationHuman) &&
        isEmpty (
          FormAnswers.activeParticipationHuman.human_subjects_participation
        )
      ) {
        this.props.SwitchSection (7);
      }

      // PRECEDENT SECTION == NO
      if (
        FormAnswers.activeParticipationHuman.human_subjects_participation ===
        'NO'
      ) {
        const answers = {
          human_subjects_participation: 'NO',

          risks_hurt: false,
          risks_stress: false,
          risks_not_feeling_well: false,
          risks_judged: false,
          risks_privacy: false,
          risks_uncomfortable: false,
          risks_other: '',

          risks_limitations: '',
        };

        this.setState ({answers}, () => {
          this.props.SetAnswers (this.state);
          this.Validate ();
        });
      } else {
        FormAnswers[sectionName].human_subjects_participation =
          FormAnswers.activeParticipationHuman.human_subjects_participation;
        this.setState ({answers: FormAnswers[sectionName]}, () => {
          this.Validate ();
        });
      }
    } else {
      this.props.SetAnswers (this.state);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      FormAnswers[sectionName].human_subjects_participation =
        FormAnswers.activeParticipationHuman.human_subjects_participation;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }

    return false;
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;

    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  RenderEthicControlQuestion = () => {
    const {t} = this.props;
    const {answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('human_subjects_participation');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>
              {t (`activeParticipationHuman.human_subjects_participation.title`)}
            </p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'YES' ? 'checked' : ''
                }
                disabled="disabled"
              />
              <label className="form-check-label" htmlFor="yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="NO"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'NO' ? 'checked' : ''
                }
                disabled="disabled"
              />
              <label className="form-check-label" htmlFor="NO">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
      }
  };

  RenderRisksOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'risks_hurt',
      'risks_stress',
      'risks_not_feeling_well',
      'risks_judged',
      'risks_privacy',
      'risks_uncomfortable',
    ];
    const options = [];
    let index = 0;

    for (let type of types) {
      options.push (
        <div className="col-md-6 pl-5" key={index}>
          <div className="form-group">
            <input
              className="form-check-input"
              type="checkbox"
              id={type}
              name={type}
              onChange={this.OnHandleCheckbox}
              checked={answers[type] ? 'checked' : ''}
              ref={input => {
                if (type === 'risks_hurt') this.nameInput = input;
              }}
            />
            <label className="form-check-label" htmlFor={type}>
              {t (`${sectionName}.${type}.title`)}
            </label>
          </div>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderRisks = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'risks_hurt',
      'risks_stress',
      'risks_not_feeling_well',
      'risks_judged',
      'risks_privacy',
      'risks_uncomfortable',
    ];

    let mustRenderQuestion =  this.MustRenderQuestion([
      'human_subjects_participation', 
      'risks_hurt',
      'risks_stress',
      'risks_not_feeling_well',
      'risks_judged',
      'risks_privacy',
      'risks_uncomfortable',
      'risks', 
      'risks_other']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.risks.title`)}</p>
          </div>

          {this.RenderRisksOptions ()}

          <div className="col-md-12 pl-4">
            <div className="form-group">
              <label htmlFor="risks_other">
                {t (`${sectionName}.risks_other.title`)}
              </label>
              <textarea
                type="text"
                className="form-control"
                name="risks_other"
                id="risks_other"
                value={answers.risks_other}
                onChange={this.OnHandleText}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderLimitations = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['human_subjects_participation', 'risks_limitations']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="risks_limitations">
                {t (`${sectionName}.risks_limitations.title`)}
              </label>
              <p className="important">
                {' '}{t (`${sectionName}.risks_limitations.text`)}
              </p>
              <textarea
                type="text"
                className="form-control"
                name="risks_limitations"
                id="risks_limitations"
                value={answers.risks_limitations}
                onChange={this.OnHandleText}
                rows="5"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderEthicControlQuestion ()}
        {answers.human_subjects_participation === 'YES' &&
          <Fragment>
            {this.RenderRisks ()}
            <hr />
            {this.RenderLimitations ()}
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {answers.human_subjects_participation === 'YES' &&
          <h6 className="section-text mb-3 important">
            {t (sectionName + '.sectionText')}
          </h6>}
        {answers.human_subjects_participation === 'NO' &&
          <div>
            <h4>
              {t ('activeParticipationHuman.noParticipation')}
            </h4>
          </div>}
        {answers.human_subjects_participation === 'YES' &&
          this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (RisksAndLimitations)
);
