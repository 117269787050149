import React, {Component} from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  Link,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import * as S from './StyledCertificateComp';
import isEmpty from '../../../validation/isEmpty';
import {Trans} from 'react-i18next';

class CertificateB1 extends Component {
  constructor (props) {
    super (props);

    this.state = {
      datetime: moment ().format ('YYYY-MM-DD HH:mm:ss'),
    };
  }

  componentDidMount () {}

  RenderHeader = () => {
    const {lang} = this.props.auth;
    const xsc_logo_url = lang == 'en'
      ? '/images/certificat/Logo_Xsc_en.png'
      : '/images/certificat/Logo_Xsc.png';
    const rt_logo_url = lang == 'en'
      ? '/images/certificat/Logo_TechSc_en.png'
      : '/images/certificat/Logo_TechSc.png';

    return (
      <S.LogoGroup>
        <S.Logo src={xsc_logo_url} />
        <S.Logo src={rt_logo_url} />
      </S.LogoGroup>
    );
  };

  RenderPreambule = () => {
    if (isEmpty (this.props.form)) return;
    const {t} = this.props;
    const {answers, type, lang, conditions, approbationDate} = this.props.form;
    const {participantInformations, projectMembers} = answers;
    const {year, final} = participantInformations;
    const {member1_lastName, member2_lastName} = projectMembers;

    const prefix = 'ethic';
    const formTitle = t (prefix + ':certificate.title');
    const formSubtitle = t (prefix + ':certificate.subtitle');
    const formPreamble = `
    ${t (prefix + ':certificate.preamble1')} ${year}\n
    ${t (prefix + ':certificate.preamble2')}\n 
    ${t (prefix + ':certificate.preamble3')} ${year} ${t (prefix + ':certificate.preamble4')}
    `;
    const formApprobationText = t (prefix + ':certificate.approbation_text');

    const participant1 = member1_lastName.substr (0, 5).toUpperCase ();
    const participant2 = member2_lastName.substr (0, 5).toUpperCase ();

    const cond = isEmpty (conditions) ? 'N/A' : conditions;

    return (
      <View>
        <S.Header1>
          {formTitle}
        </S.Header1>
        <S.Header2>
          {formSubtitle}
        </S.Header2>
        <S.Hr />
        <S.Para>
          {formPreamble}
        </S.Para>
        <S.Hr />
        <S.Lead>
          {formApprobationText}
        </S.Lead>
        <S.Hr />
        <S.ImportantInfo>
          <S.Strong>{t ('certificate:certificate_date')}</S.Strong>
          {' '}
          {moment (approbationDate).format ('YYYY-MM-DD HH:mm')}
        </S.ImportantInfo>
        <S.ImportantInfo>
          <S.Strong>{t ('certificate:number')}</S.Strong>
          {' '}
          {`${year}_${type.toUpperCase ()}_${lang.toUpperCase ()}_${participant1}${participant2}_${final}`}
        </S.ImportantInfo>
        <S.ImportantInfo>
          <S.Conditions>{t ('certificate:conditions')}</S.Conditions>{'\n'}
          <S.Conditions>{cond}</S.Conditions>
        </S.ImportantInfo>
      </View>
    );
  };

  RenderProjectInformation = () => {
    if (isEmpty (this.props.form)) return;
    const {t} = this.props;
    const {answers} = this.props.form;
    const {
      participantInformations,
      projectMembers,
      projectInformations,
    } = answers;
    const {year, final} = participantInformations;
    const {project_title} = projectInformations;
    const {
      member1_firstName,
      member1_lastName,
      member1_email,
      member2_firstName,
      member2_lastName,
      member2_email,
    } = projectMembers;
    const finalLongName = `${t ('certificate:B1.region.' + final)}`;

    return (
      <S.InfosBlockContainer>
        <S.InfosBlock>
          <S.Header6>{t ('ethic:certificate.project')}</S.Header6>
        </S.InfosBlock>
        <S.InfosBlock />
        <S.InfosBlock>
          <S.Para>{t ('ethic:certificate.year')} {year}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>
            {t ('ethic:certificate.final')} {finalLongName}
          </S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>
            {t ('ethic:certificate.project_title')} {project_title}
          </S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para />
        </S.InfosBlock>

        <S.InfosBlock>
          <S.Header6>{t ('ethic:certificate.exhibitors')} </S.Header6>
        </S.InfosBlock>
        <S.InfosBlock />

        <S.InfosBlock>
          <S.Para>{member1_firstName + ' ' + member1_lastName}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{member1_email}</S.Para>
        </S.InfosBlock>
        {!isEmpty (member2_lastName) &&
          <S.InfosBlock>
            <S.Para>{member2_firstName + ' ' + member2_lastName}</S.Para>
          </S.InfosBlock>}
        {!isEmpty (member2_email) &&
          <S.InfosBlock>
            <S.Para>{member2_email}</S.Para>
          </S.InfosBlock>}
      </S.InfosBlockContainer>
    );
  };

  RenderSupervisorInformation = () => {
    if (isEmpty (this.props.form)) return;
    const {t} = this.props;
    const {answers} = this.props.form;
    const {supervisor, institution} = answers;

    const {institution_name} = institution;
    const {
      supervisor_firstName,
      supervisor_lastName,
      supervisor_email,
      supervisor_type,
      supervisor_type_other,
    } = supervisor;

    const supervisorTitle = supervisor_type === 'OTHER'
      ? supervisor_type_other
      : t ('certificate:B1.supervisor.' + supervisor_type);

    return (
      <S.InfosBlockContainer>
        <S.Header6>
          {t ('ethic:certificate.supervisor')}
        </S.Header6>
        <S.InfosBlock>
          <S.Para>{supervisor_firstName + ' ' + supervisor_lastName}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{supervisorTitle}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{institution_name}</S.Para>
        </S.InfosBlock>
        <S.InfosBlock>
          <S.Para>{supervisor_email}</S.Para>
        </S.InfosBlock>
      </S.InfosBlockContainer>
    );
  };

  RenderConsentSection = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Header4>
          {t ('ethic:certificate.consent_title')}
        </S.Header4>
        <S.Hr />
        <S.Para>
          {t ('ethic:certificate.consent_preamble')}
        </S.Para>
        <S.Para>
          {t ('ethic:certificate.exhibitor_must')}
          <S.Strong>{t ('ethic:certificate.before')}</S.Strong>
          {t ('ethic:certificate.exhibitor_must2')}
        </S.Para>
        <S.Para>
          {t ('ethic:certificate.participant_must1')}
          <S.Strong>{t ('ethic:certificate.participant_must2')}</S.Strong>
          {t ('ethic:certificate.participant_must3')}
        </S.Para>
        <S.Para>
          {t ('ethic:certificate.consult_rules')}
          {' '}
          <Link src="https://technoscience.ca/programmes/expo-sciences/outils-et-reglements/reglements/">
            http://technoscience.ca
          </Link>
        </S.Para>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.obligation1')}</S.Strong>
        </S.Header6>
        <S.Para>
          {t ('ethic:certificate.obligation2')}
          {'\n'}
          {t ('ethic:certificate.obligation3')}
          {' '}
          <S.Strong>{t ('ethic:certificate.obligation4')}</S.Strong>
          {' '}
          {t ('ethic:certificate.obligation5')}
          {'\n'}
          {t ('ethic:certificate.obligation6')}{'\n'}
          {t ('ethic:certificate.obligation7')}
          {'\n'}
        </S.Para>
      </View>
    );
  };

  RenderDescription = () => {
    const {t} = this.props;

    const {answers} = this.props.form;
    const {projectInformations} = answers;
    const {project_description} = projectInformations;
    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.project_description')}</S.Strong>
        </S.Header6>
        <S.Para>{project_description}</S.Para>
      </View>
    );
  };
  RenderTasks = () => {
    const {t} = this.props;

    const {answers} = this.props.form;
    const {activeParticipationHuman} = answers;
    const {human_subjects_tasks} = activeParticipationHuman;

    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.project_task')}</S.Strong>
        </S.Header6>
        <S.Para>{human_subjects_tasks}</S.Para>
      </View>
    );
  };
  RenderRisks = () => {
    const {t} = this.props;
    const {answers} = this.props.form;
    const {risksAndLimitations} = answers;
    const {
      risks_hurt,
      risks_stress,
      risks_not_feeling_well,
      risks_judged,
      risks_privacy,
      risks_uncomfortable,
      risks_other,
    } = risksAndLimitations;

    let risks = '';
    risks += risks_hurt ? `${t ('certificate:B1.risks.RISKS_HURT')}; ` : '';
    risks += risks_stress ? `${t ('certificate:B1.risks.RISKS_STRESS')}; ` : '';
    risks += risks_not_feeling_well
      ? `${t ('certificate:B1.risks.RISKS_NOT_FEELING_WELL')}; `
      : '';
    risks += risks_judged ? `${t ('certificate:B1.risks.RISKS_JUDGED')}; ` : '';
    risks += risks_privacy
      ? `${t ('certificate:B1.risks.RISKS_PRIVACY')}; `
      : '';
    risks += risks_uncomfortable
      ? `${t ('certificate:B1.risks.RISKS_UNCOMFORTABLE')}; `
      : '';
    risks += !isEmpty (risks_other) ? `${risks_other}; ` : '';

    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.project_risk')}</S.Strong>
        </S.Header6>
        <S.Para>{risks}</S.Para>
      </View>
    );
  };

  RenderRisksLimits = () => {
    const {t} = this.props;
    const {answers} = this.props.form;
    const {risksAndLimitations} = answers;
    const {risks_limitations} = risksAndLimitations;
    return (
      <View>
        <S.Header6>
          <S.Strong>
            {t ('ethic:certificate.project_risk_limitations')}
          </S.Strong>
        </S.Header6>
        <S.Para>{risks_limitations}</S.Para>
      </View>
    );
  };

  RenderExpTime = () => {
    const {t} = this.props;
    const {answers} = this.props.form;
    const {activeParticipationHuman} = answers;
    const {human_subjects_duration} = activeParticipationHuman;
    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.project_duration')}</S.Strong>
        </S.Header6>
        <S.Para>{human_subjects_duration}</S.Para>
      </View>
    );
  };

  RenderExpConditions = () => {
    const {t} = this.props;
    const {conditions} = this.props.form;
    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.project_conditions')}</S.Strong>
        </S.Header6>
        <S.Para>{isEmpty (conditions) ? 'N/A' : conditions}</S.Para>
      </View>
    );
  };

  RenderConfidentialityBlock = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.confidentiality_header')}</S.Strong>
        </S.Header6>
        <S.Para>{t ('ethic:certificate.confidentiality_text')}</S.Para>
      </View>
    );
  };

  RenderRemovalBlock = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.retractation_header')}</S.Strong>
        </S.Header6>
        <S.Para>{t ('ethic:certificate.retractation_text')}</S.Para>
      </View>
    );
  };
  RenderMoreInfosBlock = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.more_infos_header')}</S.Strong>
        </S.Header6>
        <S.Para>{t ('ethic:certificate.more_infos_text')}</S.Para>
      </View>
    );
  };

  RenderUploadWarningBlock = () => {
    const {t} = this.props;
    return (
      <View>
        <S.ImportantInfo>
          {t ('ethic:certificate.upload_warning1')}
        </S.ImportantInfo>
        <S.ImportantInfo>
          {t ('ethic:certificate.upload_warning2')}
        </S.ImportantInfo>
      </View>
    );
  };
  RenderSubjectConsent = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Header6>
          <S.Strong>{t ('ethic:certificate.subject_consent')}</S.Strong>
        </S.Header6>
        <S.Para>{t ('ethic:certificate.subject_consent_text')}</S.Para>
      </View>
    );
  };

  RenderAdultSignature = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Hr />

        <S.Header6>{t ('ethic:certificate.signature_adult_header')}</S.Header6>

        <S.Para>{t ('ethic:certificate.signature_adult_text')}</S.Para>

        <S.Space />
        <S.Para>{t ('ethic:certificate.signature_adult_name')}</S.Para>
        <S.Para>{t ('ethic:certificate.signature_adult_block')}</S.Para>
        <S.InfosBlockContainer>
          <S.InfosBlock>
            <S.Para>{t ('ethic:certificate.signature_adult_contact')}</S.Para>
          </S.InfosBlock>
          <S.InfosBlock>
            <S.Para>{t ('ethic:certificate.signature_adult_date')}</S.Para>
          </S.InfosBlock>
        </S.InfosBlockContainer>
      </View>
    );
  };
  RenderMinorSignature = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Hr />

        <S.Header6>{t ('ethic:certificate.signature_minor_header')}</S.Header6>

        <S.Para>{t ('ethic:certificate.signature_minor_text')}</S.Para>

        <S.Space />
        <S.Para>{t ('ethic:certificate.signature_minor_name')}</S.Para>
        <S.Para>{t ('ethic:certificate.signature_minor_block')}</S.Para>
        <S.InfosBlockContainer>
          <S.InfosBlock>
            <S.Para>{t ('ethic:certificate.signature_minor_contact')}</S.Para>
          </S.InfosBlock>
          <S.InfosBlock>
            <S.Para>{t ('ethic:certificate.signature_minor_date')}</S.Para>
          </S.InfosBlock>
        </S.InfosBlockContainer>
      </View>
    );
  };

  RenderParticipantSignature = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Hr />

        <S.Header6>
          {t ('ethic:certificate.signature_exhibitor_header')}
        </S.Header6>

        <S.Para>{t ('ethic:certificate.signature_exhibitor_text')}</S.Para>

        <S.Space />
        <S.Para>{t ('ethic:certificate.signature_exhibitor_name1')}</S.Para>
        <S.InfosBlockContainer>
          <S.InfosBlock>
            <S.Para>
              {t ('ethic:certificate.signature_exhibitor_block1')}
            </S.Para>
          </S.InfosBlock>
          <S.InfosBlock>
            <S.Para>{t ('ethic:certificate.signature_exhibitor_date1')}</S.Para>
          </S.InfosBlock>
        </S.InfosBlockContainer>
        <S.Para>{t ('ethic:certificate.signature_exhibitor_name2')}</S.Para>
        <S.InfosBlockContainer>
          <S.InfosBlock>
            <S.Para>
              {t ('ethic:certificate.signature_exhibitor_block2')}
            </S.Para>
          </S.InfosBlock>
          <S.InfosBlock>
            <S.Para>{t ('ethic:certificate.signature_exhibitor_date2')}</S.Para>
          </S.InfosBlock>
        </S.InfosBlockContainer>
      </View>
    );
  };

  RenderSupervisorSignature = () => {
    const {t} = this.props;
    return (
      <View>
        <S.Hr />

        <S.Header6>
          {t ('ethic:certificate.signature_supervisor_header')}
        </S.Header6>

        <S.Para>{t ('ethic:certificate.signature_supervisor_text')}</S.Para>

        <S.Space />
        <S.Para>{t ('ethic:certificate.signature_supervisor_name')}</S.Para>
        <S.InfosBlockContainer>
          <S.InfosBlock>
            <S.Para>
              {t ('ethic:certificate.signature_supervisor_block')}
            </S.Para>
          </S.InfosBlock>
          <S.InfosBlock>
            <S.Para>{t ('ethic:certificate.signature_supervisor_date')}</S.Para>
          </S.InfosBlock>
        </S.InfosBlockContainer>
      </View>
    );
  };

  RenderFooter = () => {
    const {t} = this.props;
    return (
      <View>
        <S.InfosBlock>
          <S.ImportantInfo>
            <S.Header3>
              {' '}
              <S.Strong>{t ('ethic:certificate.footer_note')}</S.Strong>
            </S.Header3>
          </S.ImportantInfo>
          <S.ImportantInfo>
            <S.Header3>
              <S.Strong>{t ('ethic:certificate.footer_note2')}</S.Strong>
            </S.Header3>
          </S.ImportantInfo>
        </S.InfosBlock>
      </View>
    );
  };

  RenderFooterNotes = () => {
    const {t} = this.props;
    return (
      <S.Footer>
        <S.Small>{t ('ethic:certificate.footer_date')}</S.Small>
        <S.Small>{t ('ethic:certificate.footer_duo')}</S.Small>
        <S.Small>
          Page{' '}
          <Text
            render={({pageNumber}) =>
              `${pageNumber} ${t ('ethic:certificate.of')} 4`}
            fixed
          />
        </S.Small>
      </S.Footer>
    );
  };

  render () {
    const {t} = this.props;
    const styles = StyleSheet.create ({
      page: {padding: '30px'},
    });

    return (
      <Document
        title="Certificat d'approbation"
        author="Comité éthique Expo-sciences"
      >
        <Page size="LETTER" style={styles.page}>
          <S.PageContainer>
            {this.RenderHeader ()}
            {this.RenderPreambule ()}
            <S.Space />
            <S.FineHr />
            {this.RenderFooter ()}
            {this.RenderFooterNotes ()}
          </S.PageContainer>
        </Page>
        <Page size="LETTER" style={styles.page}>
          <S.PageContainer>
            <S.Header4>{t ('ethic:certificate.project_information')}</S.Header4>
            <S.Hr />
            {this.RenderProjectInformation ()}
            {this.RenderSupervisorInformation ()}
            <S.HrBlue />
            {this.RenderDescription ()}
            {this.RenderTasks ()}
            {this.RenderRisks ()}
            {this.RenderRisksLimits ()}
            {this.RenderExpTime ()}
            {this.RenderExpConditions ()}
            {this.RenderFooterNotes ()}
          </S.PageContainer>
        </Page>
        <Page size="LETTER" style={styles.page}>
          <S.PageContainer>
            {this.RenderConsentSection ()}
            <S.HrBlue />
            {this.RenderConfidentialityBlock ()}
            {this.RenderRemovalBlock ()}
            {this.RenderMoreInfosBlock ()}
            <S.HrBlue />
            {this.RenderSubjectConsent ()}
            {this.RenderFooterNotes ()}
          </S.PageContainer>
        </Page>
        <Page size="LETTER" style={styles.page}>
          <S.PageContainer>
            {this.RenderAdultSignature ()}
            {this.RenderMinorSignature ()}
            {this.RenderParticipantSignature ()}
            {this.RenderSupervisorSignature ()}
            {this.RenderFooterNotes ()}
          </S.PageContainer>
        </Page>
      </Document>
    );
  }
}

export default CertificateB1;
