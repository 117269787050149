//IMPORT ACTIONS
import {
    GET_TRANSLATIONS,
    EDIT_TRANSLATIONS,
    CLEAR_ALL
   } from '../actions/types';
 
   const initialState = {
    allTranslations:[]
   };
 
   const translationReducer = (state = initialState, action) => {
     switch (action.type) {
       case GET_TRANSLATIONS:
         return {
          allTranslations:action.payload
         };
       case EDIT_TRANSLATIONS:
         return {
          allTranslations:action.payload,
         };
       case CLEAR_ALL:
         return initialState;
       default:
         return state;
     }
   };
   
   export default translationReducer;