import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {loginUser} from '../../store/actions/authActions';
import { GetSiteStyle } from "../../store/actions/siteStyleActions";
import {withTranslation} from 'react-i18next';
import isEmpty from "../../validation/isEmpty";
class Login extends Component {
  constructor () {
    super ();
    this.state = {
      email: '',
      password: '',
    };
  }

  //If is authenticated, redirects to dashboard
  //Prevents from login again
  componentDidMount () {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push ('/mes-demandes');
    }
    this.props.GetSiteStyle();
  }

  //When is logged, redirects to dashboard
  //if errors, sets error states
  componentDidUpdate (prevProps) {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push ('/mes-demandes');
    }
    if (this.props.errors !== prevProps.errors) {
      this.setState ({errors: this.props.errors});
    }
    if (prevProps.siteStyle !== this.props.siteStyle) {
      this.SetSiteStyle();
    }
  }
  SetSiteStyle() {
    if(!isEmpty(this.props.siteStyle)){
      document.documentElement.style.setProperty('--data-color-main',this.props.siteStyle.mainColor);
      document.documentElement.style.setProperty('--data-color-secondary',this.props.siteStyle.secondaryColor);
      document.documentElement.style.setProperty('--data-color-accent',this.props.siteStyle.accentColor);
      document.documentElement.style.setProperty('--data-color-dark',this.props.siteStyle.darkColor);
      document.documentElement.style.setProperty('--data-color-light',this.props.siteStyle.lightColor);
    } 
  }

  onChange = e => {
    this.setState ({[e.target.name]: e.target.value});
  };

  onSubmit = e => {
    e.preventDefault ();

    const user = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser (user, this.props.history);
  };

  render () {
    const {t} = this.props;

    return (
      <div className="register">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">
                {t ('login.login')}
              </h1>
              <h5>
                Pour faire une nouvelle demande d'approbation ou pour consulter les demandes en cours, tu dois te connecter à la plateforme.
              </h5>
              <h5 className="mb-3">
                Tu n'as pas de compte? Appuie sur "Je n'ai pas de compte" au bas de l'écran
              </h5>
              <form noValidate onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="email">{t ('login.user')}</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder={t ('login.email')}
                      onChange={this.onChange}
                      value={this.state.email}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="password">{t ('login.password')}</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder={t ('login.password')}
                      onChange={this.onChange}
                      value={this.state.password}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-reseau btn-block mt-4"
                    value={t ('login.login')}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-center">
                      <Link to="/nouveau-compte">
                        {t ('login.no_account')}
                      </Link>
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h4 className="text-center">
                      <Link to="/mot-de-passe">
                        {t ('login.forgotten')}
                      </Link>
                    </h4>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//To access the info
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  siteStyle:state.siteStyle
});
export default withTranslation ('pages') (
  connect (mapStateToProps, {loginUser,GetSiteStyle}) (withRouter (Login))
);
