//IMPORT ACTIONS
import {
  GET_ALL_REGIONS,
  SET_SELECTED_REGION,
  CLEAR_SELECTED_REGION,
  CLEAR_ALL,
} from '../actions/types';

const initialState = {
  regionsList: [],
  selectedRegion: {},
};

const regionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REGIONS:
      return {
        ...state,
        regionsList: action.payload,
      };
    case SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case CLEAR_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: {},
      };
    case CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
};

export default regionReducer;
