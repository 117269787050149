//IMPORT ACTIONS
import {SET_MESSAGE, CLEAR_MESSAGE, CLEAR_ALL} from '../actions/types';

const initialState = {};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MESSAGE:
      return initialState;
    case SET_MESSAGE:
      return {...action.payload};
    case CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
};

export default messageReducer;
