import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';
import {getAllRegions} from '../../../../store/actions/regionActions';

export class Institution extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'institution',
      isValid: false,

      answers: {
        institution_name: '',
        institution_type: '',
        institution_type_other: '',
        experimentation_location: '',
        institution_support: '',
        institution_code: false,
        institution_animal_code: false,
        institution_three_counsils: false,
      },
    };
  }

  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (isEmpty (answers.institution_name)) isValid = false;
    if (isEmpty (answers.institution_type)) isValid = false;
    if (
      answers.institution_type === 'OTHER' &&
      isEmpty (answers.institution_type_other)
    )
      isValid = false;
    if (isEmpty (answers.experimentation_location)) isValid = false;
    if (isEmpty (answers.institution_support)) isValid = false;
    if (isEmpty (answers.institution_code)) isValid = false;
    if (isEmpty (answers.institution_animal_code)) isValid = false;
    if (isEmpty (answers.institution_three_counsils)) isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
    //this.nameInput.focus ();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
    });
    this.Validate ();
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    answers['institution_type_other'] = '';
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }

    return false;
  }

  RenderInstitutionName = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    
    let mustRenderQuestion =  this.MustRenderQuestion('institution_name');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="institution_name">
                {t (`${sectionName}.institution_name.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="institution_name"
                id="institution_name"
                value={answers.institution_name}
                onChange={this.OnHandleText}
                ref={input => {
                  this.nameInput = input;
                }}
              />
            </div>
            <hr />
          </div>
        </div>
      );
    }
    
  };

  RenderInstitutionTypeOptions = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    const types = [
      'HIGHSCHOOL',
      'CEGEP',
      'UNIVERSITY',
      'RESEARCH_CENTER',
      'OTHER',
    ];
    const options = [];
    let index = 0;
    for (let type of types) {
      options.push (
        <div className="form-check" key={index}>
          <input
            className="form-check-input"
            type="radio"
            name="institution_type"
            id={type}
            value={type}
            onChange={this.OnHandleRadio}
            checked={answers.institution_type === type ? 'checked' : ''}
          />
          <label className="form-check-label" htmlFor={type}>
            {t (`${sectionName}.institution_type.options.${type}`)}
          </label>
        </div>
      );
      index++;
    }
    return options;
  };

  RenderInstitutionType = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('institution_type');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.institution_type.title`)}</p>
            {this.RenderInstitutionTypeOptions ()}
          </div>
          {answers.institution_type === 'OTHER' &&
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="institution_type_other">
                  {t (`${sectionName}.institution_type_other.title`)}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="institution_type_other"
                  id="institution_type_other"
                  value={answers.institution_type_other}
                  onChange={this.OnHandleText}
                />
              </div>
            </div>}
        </div>
      );
    }
  };

  RenderExperimentationLocation = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('experimentation_location');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <hr />
            <div className="form-group">
              <label htmlFor="experimentation_location">
                {t (`${sectionName}.experimentation_location.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="experimentation_location"
                id="experimentation_location"
                value={answers.experimentation_location}
                onChange={this.OnHandleText}
              />
            </div>
            <hr />
          </div>
        </div>
      );
    }
  };

  RenderInstitutionSupport = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('institution_support');

    if (mustRenderQuestion)
    {

      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="institution_support">
                {t (`${sectionName}.institution_support.title`)}
              </label>
              <input
                type="text"
                className="form-control"
                name="institution_support"
                id="institution_support"
                value={answers.institution_support}
                onChange={this.OnHandleText}
              />
            </div>
            <hr />
          </div>
        </div>
      );

    }
  };

  RenderInstitutionCode = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['institution_code', 'institution_animal_code', 'institution_three_counsils']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{t (`${sectionName}.code.title`)}</p>
          </div>
          <div className="col-md-12 pl-5">
            <div className="form-group">
              <input
                className="form-check-input"
                type="checkbox"
                id="institution_code"
                name="institution_code"
                onChange={this.OnHandleCheckbox}
                checked={answers.institution_code ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="institution_code">
                {t (`${sectionName}.institution_code.title`)}
              </label>
            </div>
          </div>
          <div className="col-md-12  pl-5">
            <div className="form-group">
              <input
                className="form-check-input"
                type="checkbox"
                id="institution_animal_code"
                name="institution_animal_code"
                onChange={this.OnHandleCheckbox}
                checked={answers.institution_animal_code ? 'checked' : ''}
              />
              <label
                className="form-check-label"
                htmlFor="institution_animal_code"
              >
                {t (`${sectionName}.institution_animal_code.title`)}
              </label>
            </div>
          </div>
          <div className="col-md-12  pl-5">
            <div className="form-group">
              <input
                className="form-check-input"
                type="checkbox"
                id="institution_three_counsils"
                name="institution_three_counsils"
                onChange={this.OnHandleCheckbox}
                checked={answers.institution_three_counsils ? 'checked' : ''}
              />
              <label
                className="form-check-label"
                htmlFor="institution_three_counsils"
              >
                {t (`${sectionName}.institution_three_counsils.title`)}
              </label>
            </div>
            <hr />
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    return (
      <Fragment>
        {this.RenderInstitutionName ()}
        {this.RenderInstitutionType ()}
        {this.RenderExperimentationLocation ()}
        {this.RenderInstitutionSupport ()}
        {this.RenderInstitutionCode ()}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {getAllRegions};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (Institution)
);
