import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

import { ToggleNewDiscussionMessage, DeleteDiscussionMessage } from '../../store/actions/formActions';
export class DiscussionMessage extends Component {
  // == Toggle if message is new or not
  ToggleNewMessage = (event) => {
    const { message } = this.props;
    if (this.props.auth.user.role !== 'ADMIN') return;

    this.props.ToggleNewDiscussionMessage(message);
  };

  // == Toggle if message is new or not
  DeleteMessage = (event) => {
    const { message } = this.props;
    if (this.props.auth.user.role !== 'ADMIN' && this.props.auth.user.role !== 'COORDINATOR') return;

    this.props.DeleteDiscussionMessage(message);
  };

  // == FORMAT MESSAGE AUTHOR NAME IF USER IS PARTICIPANT
  FormatParticipant = () => {
    const { message, type, t } = this.props;
    if (message.author === undefined) {
      return t('message.author_non_defined');
    }

    const author = message.author.role === 'PARTICIPANT'
      ? `${message.author.firstName} ${message.author.lastName}`
      : type === 'AE'
        ? t('message.ethic_committee')
        : t('message.rules_committee');

    return author;
  };

  // == FORMAT MESSAGE AUTHOR NAME IF USER IS ADMIN OR COMMITTEE MEMBER
  FormatAdmin = () => {
    const { message, t } = this.props;
    if (message.author === undefined) {
      return t('message.author_non_defined');
    }
    const author = `${message.author.firstName} ${message.author.lastName}`;
    return author;
  };

  // == RENDERS THE NAME, DATE TIME OF MESSAGE ABOVE THE BOX
  RenderDateTime = () => {
    const { message, auth, t } = this.props;
    const { user } = auth;
    const author = user.role === 'PARTICIPANT'
      ? this.FormatParticipant()
      : this.FormatAdmin();

    const datetime = moment(message.datetime).format('YYYY-MM-DD, HH:mm');
    return (
      <p>
        {message.adminOnly && <span>{t('message.private')}<br /></span>}
        {!message.adminOnly &&
          user.role !== 'PARTICIPANT' &&
          <span>{t('message.public')}<br /></span>}
        {author}
        {' '}
        {t('message.has_written')}
        {' '}
        {datetime}

      </p>
    );
  };

  // == RENDERS THE MESSAGE ITSELF
  RenderMessage = () => {
    const { message } = this.props;
    return (
      <p>
        <i
          className={classnames('fas', { 'fa-lock pr-3': message.adminOnly })}
        />
        {message.message}
      </p>
    );
  };

  RenderDeletedMessage =()=>{
    const { t } = this.props;
    return(
      <span>{t("discussion_view.deletedMessage")}</span>
    )
  }

  render() {
    const { message, user } = this.props;
    const { pendingRequest } = this.props.form;
    //HIDE ADMIN MESSAGE TO PARTICIPANT AND ALL MESSAGES TO READONLY MEMBERS
    if (
      message.adminOnly &&
      (user.role !== 'ADMIN' && user.role !== 'COORDINATOR')
    ) {
      return null;
    }

    if (
      message.isDeleted &&
      (user.role !== 'ADMIN' && user.role !== 'COORDINATOR')
    ) {
      return null;
    }

    let deleteButton;
    if ((user.role === 'ADMIN' || user.role === 'COORDINATOR') && !message.isDeleted) {
      deleteButton = <i className="fas fa-trash delete-message-button" onClick={this.DeleteMessage}></i>
    }
    let isDeleted = message.isDeleted;

    return (
      <div
        className={classnames(
          'message',
          {
            participant: message.author.role === 'PARTICIPANT',
            admin: message.author.role !== 'PARTICIPANT',
          },
          {
            'admin-only': message.adminOnly,
          },
          { new: message.new },
          { "deleted": isDeleted }
        )}
      >
        <div className="message-date">
          {this.RenderDateTime()}
        </div>
        <div className={classnames(["message-text", { "deleted": isDeleted }])} onClick={this.ToggleNewMessage}>
          {isDeleted?this.RenderDeletedMessage():this.RenderMessage()}
        </div>
        {deleteButton}
      </div>
    );
  }
}

const mapStateToProps = state => ({ auth: state.auth, form: state.form });

const mapDispatchToProps = { ToggleNewDiscussionMessage, DeleteDiscussionMessage };

export default withTranslation(['pages'])(
  connect(mapStateToProps, mapDispatchToProps)(DiscussionMessage)
);
