import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';

export class EvaluationByEthicsCommittee extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'evaluationByEthicsCommittee',
      isValid: false,

      answers: {
        human_subjects_participation: 'NO',
        evaluation: 'NO',
      },
    };
  }
  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (
      answers.human_subjects_participation === 'YES' &&
      isEmpty (answers.evaluation)
    )
      isValid = false;

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;

    if (!isEmpty (FormAnswers[sectionName])) {
      if (
        isEmpty (FormAnswers.activeParticipationHuman) &&
        isEmpty (
          FormAnswers.activeParticipationHuman.human_subjects_participation
        )
      ) {
        this.props.SwitchSection (7);
      }

      // PRECEDENT SECTION == NO
      if (
        FormAnswers.activeParticipationHuman.human_subjects_participation ===
        'NO'
      ) {
        const answers = {
          human_subjects_participation: 'NO',
          evaluation: 'NO',
        };

        this.setState ({answers}, () => {
          this.props.SetAnswers (this.state);
          this.Validate ();
        });
      } else {
        FormAnswers[sectionName].human_subjects_participation =
          FormAnswers.activeParticipationHuman.human_subjects_participation;
        this.setState ({answers: FormAnswers[sectionName]}, () => {
          this.Validate ();
        });
      }
    } else {
      this.props.SetAnswers (this.state);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      FormAnswers[sectionName].human_subjects_participation =
        FormAnswers.activeParticipationHuman.human_subjects_participation;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        if (this.nameInput !== undefined) {
          this.nameInput.focus ();
        }
        this.Validate ();
      });
    }
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;

    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  OnHandleCheckbox = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.checked;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      this.Validate ();
    });
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }
    return false;
  }

  RenderEthicControlQuestion = () => {
    const {t} = this.props;
    const {answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('human_subjects_participation');

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>
              {t (`activeParticipationHuman.human_subjects_participation.title`)}
            </p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'YES' ? 'checked' : ''
                }
                disabled="disabled"
              />
              <label className="form-check-label" htmlFor="yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="human_subjects_participation"
                id="NO"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={
                  answers.human_subjects_participation === 'NO' ? 'checked' : ''
                }
                disabled="disabled"
              />
              <label className="form-check-label" htmlFor="NO">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
     );
    }
  };

  RenderEvaluation = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['human_subjects_participation', 'evaluation']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.evaluation.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="evaluation"
                id="evaluation_yes"
                value="YES"
                onChange={this.OnHandleRadio}
                checked={answers.evaluation === 'YES' ? 'checked' : ''}
                ref={input => {
                  this.nameInput = input;
                }}
              />
              <label className="form-check-label" htmlFor="evaluation_yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="evaluation"
                id="evaluation_no"
                value="NO"
                onChange={this.OnHandleRadio}
                checked={answers.evaluation === 'NO' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="evaluation_no">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;
    return (
      <Fragment>
        {this.RenderEthicControlQuestion ()}
        {answers.human_subjects_participation === 'YES' &&
          <Fragment>
            {this.RenderEvaluation ()}
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName, answers} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/*  <h6 className="section-text mb-3 important">
          {t (sectionName + '.sectionText')}
        </h6> */}
        {answers.human_subjects_participation === 'NO' &&
          <div>
            <h4>
              {t ('activeParticipationHuman.noParticipation')}
            </h4>
          </div>}
        {answers.human_subjects_participation === 'YES' &&
          this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (EvaluationByEthicsCommittee)
);
