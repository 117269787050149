import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';
//TODO: add fallback transaltions?
const fetchTranslations = async (resolve, reject) => {
  try {

    const result = await axios.get('api/translations/');
    const translations = result.data.reduce((accumulator, currentValue, currentIndex, array) => {
      accumulator[currentValue.name] = currentValue.translations
      return accumulator;
    }, {})

    if (translations.hasOwnProperty("pages_fr") && translations.hasOwnProperty("pages_en")) {
      i18next.addResourceBundle("fr", "pages", translations.pages_fr, true, true, {silent: false});
      i18next.addResourceBundle("en", "pages", translations.pages_en, true, true);
    }

    if (translations.hasOwnProperty("ae_fr") && translations.hasOwnProperty("ae_en")) {
      i18next.addResourceBundle("fr", "ethic", translations.ae_fr, true, true);
      i18next.addResourceBundle("en", "ethic", translations.ae_en, true, true);
    }

    if (translations.hasOwnProperty("ar_fr") && translations.hasOwnProperty("ar_en")) {
      i18next.addResourceBundle("fr", "rules", translations.ar_fr, true, true);
      i18next.addResourceBundle("en", "rules", translations.ar_en, true, true);
    }

    if (translations.hasOwnProperty("pre_fr") && translations.hasOwnProperty("pre_en")) {
      i18next.addResourceBundle("fr", "prequestionnaire", translations.pre_fr, true, true);
      i18next.addResourceBundle("en", "prequestionnaire", translations.pre_en, true, true);
    }

    if (translations.hasOwnProperty("form_fr") && translations.hasOwnProperty("form_en")) {
      i18next.addResourceBundle("fr", "form", translations.form_fr, true, true);
      i18next.addResourceBundle("en", "form", translations.form_en, true, true);
    }

    if (translations.hasOwnProperty("certificate_fr") && translations.hasOwnProperty("certificate_en")) {
      i18next.addResourceBundle("fr", "certificate", translations.certificate_fr, true, true);
      i18next.addResourceBundle("en", "certificate", translations.certificate_en, true, true);
    }
    resolve(true)
    
  } catch (error) {
    reject(false)
  }
}

i18next.use(LanguageDetector).init({
  interpolation: {
    escapeValue: false
  },
  react: {
    bindI18n: "'languageChanged''added'",
    bindI18nStore:'added',
    useSuspense: false,
  },
  resources: {},
  fallbackLng: 'fr',
  debug: false,
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
})

new Promise(async (resolve, reject)=>{
  await fetchTranslations(resolve, reject);
}).then(result =>{
  i18next.reloadResources();
  console.log("translations loaded")
}).catch(err=>{
  console.log("translation errors", err)
})

export default i18next;