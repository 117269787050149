import React, {Component} from 'react';
import {connect} from 'react-redux';

import {sendResetPwdCode} from '../../store/actions/authActions';
class AuthForgottenPwd extends Component {
  constructor (props) {
    super (props);
    this.state = {
      email: '',
    };
  }

  OnSubmit = e => {
    this.props.sendResetPwdCode (this.state.email);
    window.setTimeout (e => {
      this.props.history.push ('/connexion');
    }, 2000);
  };

  OnChange = e => {
    this.setState ({[e.target.name]: e.target.value});
  };
  RenderHeader = () => {
    return (
      <div className="row">
        <div className="col-md-8 m-auto text-center">
          <h1 className="display-4">
            Réinitialisation du mot de passe
          </h1>
          <h5>
            Pour réinitialiser votre mot de passe, veuillez entrer votre courriel utilisé pour vous connecter à l'application. Vous recevrez un courriel avec un lien pour réinitialiser ce dernier.
          </h5>
        </div>
      </div>
    );
  };
  RenderForm = () => {
    return (
      <div className="row py-5 text-center">
        <div className="form-group col-md-12 text-center">
          <label htmlFor="email">Veuillez entrer votre courriel</label>
          <input
            type="email"
            className="form-control form-control-lg"
            name="email"
            value={this.state.email}
            onChange={this.OnChange}
          />
        </div>
        <div className="col-md-12">
          <button className="btn btn-main p-3" onClick={this.OnSubmit}>
            <h4>Envoyer un courriel de réinitialisation</h4>
          </button>
        </div>

      </div>
    );
  };
  render () {
    return (
      <div className="container">
        {this.RenderHeader ()}
        {this.RenderForm ()}

      </div>
    );
  }
}

export default connect (null, {sendResetPwdCode}) (AuthForgottenPwd);
