import styled from '@react-pdf/styled-components';
import {Font} from '@react-pdf/renderer';
import fontreg from './fonts/rakesly_rg.ttf';
import fontbold from './fonts/rakesly_bd.ttf';

Font.register ({
  family: 'Rakesly',
  fonts: [
    {
      src: fontreg,
    },
    {
      src: fontbold,
      fontWeight: 'bold',
    },
  ],
});

export const PageContainer = styled.View`
  padding:30px;
  border:1px solid orange;
  height:100%;
`;
export const TextBlock = styled.Text`
  white-space: pre-line;
  color:blue;
`;

export const Header1 = styled.Text`
  text-transform:uppercase;
  color:black;
  font-size:16px;
  margin-bottom:10px;
  white-space:nowrap;
`;
export const Header2 = styled.Text`
  color:black;
  font-size:14px;
  margin-bottom:5px;
`;
export const Header3 = styled.Text`
  font-size:12px;
  color:grey;
  margin-bottom:5px;

`;
export const Header4 = styled.Text`
  font-size:13px;
  letter-spacing:1px;
  margin-bottom:5px;
  font-weight:bold;

`;
export const Header5 = styled.Text`
  text-align:center;
  font-size:12px;
  text-transform:uppercase;
  color:orange;
  margin:5px 0px;
`;
export const Header6 = styled.Text`
  font-size:12px;
  text-transform:uppercase;
  color:orange;
  margin:10px 0px 5px 0px;
`;
export const Header7 = styled.Text`
  font-size:12px;
  text-transform:uppercase;
  color:orange;
  margin:5px 0px 2px 0px;
`;
export const Para = styled.Text`
  font-size:9px;
  text-align:justify;
  margin-bottom:5px;
`;

export const Lead = styled.Text`
  font-family: 'Rakesly';
  font-size:16px;
  letter-spacing:1px;
  text-decoration:underline;
  text-align:center;
  margin:5px 0;
  color:orange;
  text-transform:uppercase;
  font-weight:bold;

`;

export const Small = styled.Text`
  font-size:7px;
  text-align:justify;
`;
export const Strong = styled.Text`
  font-family: 'Rakesly';
  font-weight:bold;
`;

export const Conditions = styled.Text`
  font-family: 'Rakesly';
  font-weight:bold;
  color:red;
`;

export const ImportantInfo = styled.Text`
  font-size:11px;
  line-height:1.1;
  font-weight:800;
  text-align:left;
  padding:5px 0; 
`;

export const ImportantFooter = styled.Text`
  font-size:12px;
  line-height:1.2;
  font-weight:800;
  text-align:center;
  background-color:orange;
  padding:5px 0; 
  border-radius:10px;
`;
export const Footer = styled.View`
  width:100%;
  padding:5em;
  min-height:20px;
  position:absolute;
  bottom: 0;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  margin-left:25px;
`;

export const InfosBlockContainer = styled.View`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  padding:10px 0;
`;
export const InfosBlockContainer2 = styled.View`
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  padding:5px 0;
`;
export const InfosBlock = styled.View`
  flex-shrink:0;
  flex-grow:0;
  flex-basis:50%;
`;

export const Hr = styled.View`
  background-color:orange;
  height:3px;
  width:100%;
  margin: 5px 0;
`;

export const FineHr = styled.View`
  background-color:orange;
  height:1px;
  width:100%;
  margin: 2px 0;
`;
export const Space = styled.View`
  height:10px;
  margin:5px 0;
`;

export const HrBlue = styled.View`
  background-color:#64dbff;
  height:2px;
  width:100%;
  margin: 5px 0;
`;

export const Logo = styled.Image`
 width:150px;
 max-height:100px;
 object-fit:contain;
`;
export const LogoGroup = styled.View`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  padding-right:25px;
  margin-bottom:30px;
`;
