import axios from 'axios';
import {
  GET_ALL_USERS,
  GET_ERRORS,
  CLEAR_SELECTED_USER,
  SET_SELECTED_USER,
} from './types';

export const getAllUsers = () => dispatch => {
  axios
    .get ('/api/user/')
    .then (foundUsers => {
      dispatch ({
        type: GET_ALL_USERS,
        payload: foundUsers.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getCommitteeMembers = () => dispatch => {
  axios
    .get ('/api/user/committee')
    .then (foundUsers => {
      dispatch ({
        type: GET_ALL_USERS,
        payload: foundUsers.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getUsersFromRegions = regionsList => dispatch => {
  axios
    .post ('/api/user/regions', regionsList)
    .then (foundUsers => {
      dispatch ({
        type: GET_ALL_USERS,
        payload: foundUsers.data,
      });
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteUser = id => dispatch => {
  axios
    .delete ('/api/user/id', {
      params: {
        id: id,
      },
    })
    .then (deletedUser => {
      dispatch (getCommitteeMembers ());
    })
    .catch (err => {
      dispatch ({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setSelectedUser = selectedUser => dispatch => {
  if (selectedUser === undefined) {
    return dispatch ({
      type: CLEAR_SELECTED_USER,
    });
  }
  dispatch ({
    type: SET_SELECTED_USER,
    payload: selectedUser,
  });
};
