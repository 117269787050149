import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PDFViewer} from '@react-pdf/renderer';
import CertificateB1 from './CertificateB1';
import {GetFormById} from '../../../store/actions/formActions';
import {withTranslation} from 'react-i18next';

export class CertificateTestB1 extends Component {
  componentDidMount () {
    this.props.GetFormById ('5dd7fe75c5029b0fc54751e0');
  }

  render () {
    const {t} = this.props;
    const {pendingRequest} = this.props.form;
    return (
      <PDFViewer width="500px" height="800px">
        <CertificateB1 form={pendingRequest} t={t} />
      </PDFViewer>
    );
  }
}

const mapStateToProps = state => ({form: state.form});

const mapDispatchToProps = {GetFormById};

export default withTranslation (['ethic', 'rules']) (
  connect (mapStateToProps, mapDispatchToProps) (CertificateTestB1)
);
