import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {ClearMessage} from '../../store/actions/messageActions';
import isEmpty from '../../validation/isEmpty';

export class MessageBox extends Component {
  constructor (props) {
    super (props);
    this.state = {
      cleared: false,
    };
  }
  componentDidUpdate (prevProps, prevState) {
    if (this.props.message !== prevProps.message) {
      if (!isEmpty (this.props.message) && !this.state.cleared) {
        this.setState ({cleared: true});

        window.setTimeout (() => {
          this.setState ({cleared: false});
          this.props.ClearMessage ();
        }, 2500);
      }
    }
  }
  render () {
    const isMessage = !isEmpty (this.props.message);
    return (
      <Fragment>
        {isMessage &&
          <div className="messageBox" onClick={this.props.ClearMessage}>
            <div className="alert alert-success fade-in-out box" role="alert">
              <p>
                {this.props.message.msg}
              </p>
            </div>
          </div>}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  message: state.message,
});

const mapDispatchToProps = {ClearMessage};

export default connect (mapStateToProps, mapDispatchToProps) (MessageBox);
