const filterQuestions = [
  {
    id: 'q1',
    trigger: 'formAE',
  },
  {
    id: 'q2',
    trigger: 'formAR',
  },
  {
    id: 'q3',
    trigger: 'formAR',
  },
  {
    id: 'q4',
    trigger: 'formAR',
  },
  {
    id: 'q5',
    trigger: 'formAR',
  },
  {
    id: 'q6',
    trigger: 'formAR',
  },
  {
    id: 'q7',
    trigger: 'formAR',
  },
  {
    id: 'q8',
    trigger: 'formAR',
  },
];

export default filterQuestions;
