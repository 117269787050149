import React, {Component, Fragment} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {logoutUser, SetLanguage} from '../../store/actions/authActions';

import {connect} from 'react-redux';
import i18next from 'i18next';
import {withTranslation} from 'react-i18next';
class Nav extends Component {
  componentDidMount () {
    const {lang} = this.props.auth;
    i18next.changeLanguage (lang);
    this.forceUpdate ();
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.auth !== this.props.auth) {
      i18next.changeLanguage (this.props.auth.lang);
      this.forceUpdate ();
    }
  }
  RenderClientNav = () => {
    const {lang} = this.props.auth;
    const {isAuthenticated} = this.props.auth;
    const {t} = this.props;

    return (
      <Fragment>
        <NavLink to="/" className="navbar-brand">
          <img
            className="logo"
            src={`/images/Logo_XSC_noir_${i18next.language}.svg`}
            width="250"
            alt="logo"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="selected"
                to="/pre-questionnaire"
              >
                {t ('nav_prequiz')}
              </NavLink>
            </li>
            {isAuthenticated &&
              <Fragment>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="selected"
                    to="/mes-demandes"
                  >
                    <span>{t ('nav_myforms')}</span>
                  </NavLink>
                </li>
              </Fragment>}
            {isAuthenticated
              ? <li className="nav-item">
                  <NavLink
                    activeClassName="selected"
                    className="nav-link"
                    to="#"
                    onClick={this.props.logoutUser}
                  >
                    <span>{t ('nav_logout')}</span>
                  </NavLink>
                </li>
              : <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="selected"
                    to="/connexion"
                  >
                    <span>{t ('nav_login')}</span>
                  </NavLink>
                </li>}
          </ul>
          <div className="pl-4 lang-nav">
            {lang === 'fr' &&
              <div
                className="nav-item"
                onClick={() => {
                  this.props.SetLanguage ('en');
                }}
              >
                <span>English</span>
              </div>}

            {lang === 'en' &&
              <div
                className="nav-item"
                onClick={() => {
                  this.props.SetLanguage ('fr');
                }}
              >
                <span>Français</span>
              </div>}
          </div>
        </div>
      </Fragment>
    );
  };

  render () {
    return (
      <nav className="navbar navbar-expand-lg mb-4">
        {this.RenderClientNav ()}
      </nav>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});

export default withTranslation ('pages') (
  connect (mapStateToProps, {logoutUser, SetLanguage}) (Nav)
);
