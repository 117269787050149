import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../../../validation/isEmpty';
import {getAllRegions} from '../../../../store/actions/regionActions';
import {ValidateCharAmount,ValidateWordAmount} from '../../../../validation/fieldValidation';
const WAIT_INTERVAL = 250;

export class ProjectInformations extends Component {
  constructor (props) {
    super (props);

    this.state = {
      sectionName: 'secondYear',
      isValid: false,

      answers: {
        secondYear: 'NO',
        secondYear_modifications: '',
      },
    };
  }
  Validate = () => {
    const {answers} = this.state;
    let isValid = true;

    if (isEmpty (answers.secondYear)) isValid = false;
    if (
      answers.secondYear === 'YES' &&
      isEmpty (answers.secondYear_modifications)
    ) {
      isValid = false;
    }
    
    if (
      answers.secondYear === 'YES' &&
      !ValidateWordAmount (answers.secondYear_modifications, 50)
    ) {
      isValid = false;
    }

    this.props.CheckValid (isValid);
  };

  componentDidMount () {
    const {FormAnswers} = this.props;
    const {sectionName} = this.state;
    this.timer = null;

    if (!isEmpty (FormAnswers[sectionName])) {
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    } else {
      this.props.SetAnswers (this.state);
    }
    //this.nameInput.focus ();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.FormAnswers !== this.props.FormAnswers) {
      const {FormAnswers} = this.props;
      const {sectionName} = this.state;
      this.setState ({answers: FormAnswers[sectionName]}, () => {
        this.Validate ();
      });
    }
  }
  triggerChange () {
    this.Validate ();
  }

  OnHandleText = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;

    clearTimeout (this.timer);

    this.timer = setTimeout (() => {
      this.triggerChange ();
    }, WAIT_INTERVAL);

    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
      //this.Validate ();
    });
  };

  OnHandleRadio = e => {
    const {answers} = this.state;
    answers[e.currentTarget.name] = e.currentTarget.value;
    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
    });
    this.Validate ();
  };

  OnHandleControlQuestion = e => {
    const {answers} = this.state;

    answers['secondYear_modifications'] = '';
    answers[e.currentTarget.name] = e.currentTarget.value;

    this.setState ({answers}, () => {
      this.props.SetAnswers (this.state);
    });
    this.Validate ();
  };

  MustRenderQuestion = (question) => {
    const {mode, questions} = this.props;

    if (mode != 'correction')
      return true;

    if ( ! Array.isArray(question))
      question = [question];

    for(let i = 0; i < question.length; i++)
    {
        if (questions.includes(question[i]))
          return true;
    }

    return false;
  }

  RenderSecondYearControlQuestion = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion('secondYear');

    if (mustRenderQuestion)
    {
      return (
        <div className="row my-3">
          <div className="col-md-12">
            <p>{t (`${sectionName}.secondYear.title`)}</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="secondYear"
                id="secondYear_yes"
                value="YES"
                onChange={this.OnHandleControlQuestion}
                checked={answers.secondYear === 'YES' ? 'checked' : ''}
                ref={input => {
                  this.nameInput = input;
                }}
              />
              <label className="form-check-label" htmlFor="secondYear_yes">
                {t (`YES`)}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="secondYear"
                id="secondYear_NO"
                value="NO"
                onChange={this.OnHandleControlQuestion}
                checked={answers.secondYear === 'NO' ? 'checked' : ''}
              />
              <label className="form-check-label" htmlFor="secondYear_NO">
                {t (`NO`)}
              </label>
            </div>
          </div>
        </div>
      );
    }
  };

  RenderProjectInfos = () => {
    const {t} = this.props;
    const {sectionName, answers} = this.state;

    let mustRenderQuestion =  this.MustRenderQuestion(['secondYear', 'secondYear_modifications']);

    if (mustRenderQuestion)
    {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="secondYear_modifications">
                {t (`${sectionName}.secondYear_modifications.title`)}
              </label>
              <p className="py-1 important">
                {t (`${sectionName}.secondYear_modifications.text`)}
              </p>
              <textarea
                type="text"
                className="form-control"
                name="secondYear_modifications"
                id="secondYear_modifications"
                value={answers.secondYear_modifications}
                onChange={this.OnHandleText}
                autoComplete="off"
                spellCheck="false"
                autoCorrect="off"
                autoCapitalize="off"
                minLength="395"
              />
            </div>
            <div>
              {
                answers
                  .secondYear_modifications
                  .trim()
                  .split(" ")
                  .filter((word) => { if (word !== '') return word })
                  .length
            }  {t (`word`)}
            </div>
          </div>
  
        </div>
      );
    }
  };

  RenderQuestions = () => {
    const {answers} = this.state;

    return (
      <Fragment>
        {this.RenderSecondYearControlQuestion ()}
        {answers.secondYear === 'YES' &&
          <Fragment>
            {this.RenderProjectInfos ()}
          </Fragment>}
      </Fragment>
    );
  };

  render () {
    const {t} = this.props;
    const {sectionName} = this.state;
    return (
      <div className="section">
        <h1 className="section-text">{t (sectionName + '.sectionTitle')}</h1>
        {/* <h6 className="section-text">{t (sectionName + '.sectionText')}</h6> */}
        {this.RenderQuestions ()}
      </div>
    );
  }
}

const mapStateToProps = state => ({region: state.region});

const mapDispatchToProps = {getAllRegions};

export default withTranslation ('form') (
  connect (mapStateToProps, mapDispatchToProps) (ProjectInformations)
);
