import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import {withTranslation} from 'react-i18next';
import {ChangeConditions} from '../../store/actions/formActions';

class ConditionModal extends Component {
  constructor (props) {
    super (props);
    this.state = {
      conditions: '',
    };
  }

  componentDidMount () {
    this.setState ({conditions: this.props.conditions});
  }

  componentDidUpdate (prevProps, prevState) {}

  //**************** */

  OnHandleText = e => {
    this.setState ({[e.currentTarget.name]: e.currentTarget.value});
  };

  ChangeConditions = () => {
    this.props.ChangeConditions (this.props._id, this.state.conditions);
    this.props.ClearModal ();
  };

  //******** VIEW SECTIONS
  ModalHeader = () => {
    const {t} = this.props;
    return (
      <div className="modal-header">
        <h3 className="modal-title text-center" id="modalEntryLabel">
          {t ('conditions.change_conditions')}
        </h3>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeModalBtn"
          onClick={this.props.ClearModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };
  ModalBody = () => {
    const {t} = this.props;
    return (
      <div className="modal-body" id="modalEntry-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="conditions">
                {t ('conditions.project_conditions')}
              </label>
              <textarea
                className="form-control"
                name="conditions"
                id="conditions"
                rows="5"
                onChange={this.OnHandleText}
                value={this.state.conditions}
              />
            </div>
          </div>

        </div>
      </div>
    );
  };

  ModalFooter = () => {
    const {t} = this.props;
    const isValid = true;

    return (
      <div className="modal-footer">
        <button
          type="button"
          className={classnames ('btn btn-primary', {
            'btn-disabled': !isValid,
          })}
          onClick={this.ChangeConditions}
        >
          {t ('conditions.update')}
        </button>

        <button
          type="button"
          className="btn btn-invalid"
          data-dismiss="modal"
          onClick={this.props.ClearModal}
        >
          {t ('conditions.cancel')}
        </button>
      </div>
    );
  };

  render () {
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalNewEntry"
          id="modalEntry-btn"
        />
        <div
          className="modal fade"
          id="modalNewEntry"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalEntryLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content ">
              {this.ModalHeader ()}
              <hr />
              {this.ModalBody ()}
              {this.ModalFooter ()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  programs: state.programs,
  entry: state.entry,
  employees: state.employees,
});

export default withTranslation (['pages']) (
  connect (mapStateToProps, {
    ChangeConditions,
  }) (ConditionModal)
);
