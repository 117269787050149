export const GET_ALL_REGIONS = 'GET_ALL_REGIONS';
export const SET_SELECTED_REGION = 'SET_SELECTED_REGION';
export const CLEAR_SELECTED_REGION = 'CLEAR_SELECTED_REGION';

export const GET_REGION_ID = 'GET_REGION_ID';
export const CREATE_REGION = 'CREATE_REGION';
export const UPDATE_REGION_ID = 'UPDATE_REGION_ID';
export const DELETE_REGION_ID = 'DELETE_REGION_ID';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_ALL_FORMS = 'GET_ALL_FORMS';
export const SAVE_FORM = 'SAVE_FORM';
export const DELETE_FORM = 'DELETE_FORM';
export const SET_SELECTED_FORM = 'SET_SELECTED_FORM';
export const SET_ANSWERS = 'SET_ANSWERS';
export const CLEAR_SELECTED_FORM = 'CLEAR_SELECTED_FORM';
export const CHANGE_CONDITIONS = 'CHANGE_CONDITIONS';

export const GET_FORM_ATTACHMENTS = 'GET_FORM_ATTACHMENTS';

export const GET_REQUEST = 'GET_REQUEST';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';

export const EXPORTATION_DONE = 'EXPORTATION_DONE';
export const CLEAR_EXPORTATION_DATA = 'CLEAR_EXPORTATION_DATA';
export const SET_EXPORTATION_DATA = 'SET_EXPORTATION_DATA';
export const SET_EXPORTATION_FORMAT = 'SET_EXPORTATION_FORMAT';

export const GET_SITE_STYLE = 'GET_SITE_STYLE';
export const EDIT_SITE_STYLE = 'EDIT_SITE_STYLE';
export const CREATE_SITE_STYLE = 'CREATE_SITE_STYLE';

export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const EDIT_TRANSLATIONS = 'EDIT_TRANSLATIONS';

export const SET_SPINNER = 'SET_SPINNER';
export const GET_MESSAGE = 'GET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_LANG = 'SET_LANG';

export const CLEAR_ALL = 'CLEAR_ALL';
