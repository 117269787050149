import React, {Component} from 'react';
import {connect} from 'react-redux';

class Spinner extends Component {
  constructor (props) {
    super (props);
    this.state = {
      spinning: false,
    };
  }
  componentDidMount = () => {
    const {spinning} = this.props.auth;
    this.setState ({spinning});
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.auth !== this.props.auth) {
      const {spinning} = this.props.auth;
      this.setState ({spinning});
    }
  };

  render () {
    const showSpinner = this.state.spinning;
    return (
      <div>
        {showSpinner &&
          <div className="spinner-container fa-5x d-flex justify-content-center align-items-center">
            <i className=" fas fa-circle-notch fa-spin text-white" />
          </div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect (mapStateToProps) (Spinner);
